import { FetchFromTable } from "./apis";

export default async function fetchRecords(
  data = [],
  table = "",
  where = "",
  limit = 2000,
  opts
) {
  const result = await FetchFromTable(data, table, where, limit, opts);
  return result;
}
