/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { GenerateReport, httpRequest } from "../../utils/apis";
import swal from "sweetalert";
import {
  base64ToArrayBuffer,
  saveByteArray,
  showToast,
  urls,
} from "../../utils/utils";
import { Button, Checkbox, Grid } from "@material-ui/core";
// import SelectDBAuto from "../../components/selectDbAuto";
import { Stack } from "@mui/material";
import SelectDB from "../../components/selectDb";
import SelectDBAuto from "../../components/selectDbAuto";
import { TypeheadSingle } from "../../components/Typehead/typehead";
//import { toast } from "react-toastify";

function PostpaidReportForm() {
  const [reportTypes, setReportTypes] = useState([
    {
      label: "Customer History",
      value: "cus_history",
    },
    {
      label: "Customer History(PDF)",
      value: "cus_history2",
    },
    {
      label: "Detailed Bill Dump",
      value: "bill_dump",
    },
    {
      label: "Minimum Bill Dump",
      value: "min_bill_dump",
    },
    {
      label: "Bill Summary",
      value: "bill_summary",
    },
    {
      label: "Payments",
      value: "payment",
    },
    {
      label: "Payment Reversal",
      value: "reverse",
    },
    {
      label: "Account without payments",
      value: "account",
    },
    {
      label: "Payments by tariff",
      value: "tariff",
    },
    {
      label: "Monthly Sales by Tariff",
      value: "month_tariff",
    },
    {
      label: "Marketer Performance",
      value: "marketer_report",
    },
    {
      label: "Viable Feeder Report",
      value: "feeder_report",
    },
    {
      label: "New Application",
      value: "new_customer",
    },
    {
      label: "New Connection",
      value: "new_connect",
    },
    {
      label: "Arrears",
      value: "arrears",
    },
    {
      label: "Customers On Credit",
      value: "credit",
    },
    {
      label: "Customers Dump",
      value: "customers",
    },
    {
      label: "Feeder Wise-Billing Report",
      value: "feeder_bill",
    },
    {
      label: "Adjustment Report",
      value: "adj_dump",
    },
    {
      label: "Amount Dump",
      value: "amount_dump",
    },
  ]);

  const [account, setAccount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [serviceCenterId, setServiceCenterId] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [reportType, setReportType] = useState(reportTypes[0].value);
  const [useServiceCenter, setServiceCenter] = useState(false);
  const [fileName, setFileName] = useState("");
  const [maxAmount, setMaxAmount] = useState(undefined);
  const [minAmount, setMinAmount] = useState(undefined);

  let checkboxRef = useRef();

  const onChangeuseServiceCenter = (e) => {
    e.preventDefault();
    console.log("e", e.target.value, checkboxRef.current);
    // checkboxRef.current.setAttribute("checked", !checkboxRef.current.checked);
    // e.currentTarget.setAttribute("checked", !e.currentTarget.checked);
    if (!useServiceCenter) {
      document.getElementById("use-district").setAttribute("checked", "");
    } else {
      document.getElementById("use-district").removeAttribute("checked");
    }

    setServiceCenter(!useServiceCenter);
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      startDate,
      endDate,
      reportType,
      account,
      serviceCenterId,
      month,
      year,
      useServiceCenter,
      minAmount,
      maxAmount
    };
    // toast.success('Generating report...')
    showToast({
      message: "Generating report...",
      type: "success",
    });
    // live
    await GenerateReport("report", payload, { method: "post" }).then(
      (response) => {
        if (response && response.status) {
          setFileName(response.data.fileName);
          saveByteArray(
            `${reportType}_${response.data.fileName}`,
            base64ToArrayBuffer(response.data.base64),
            response.data.extension
          );
          // setMinAmount()

          swal(response?.message, {
            icon: "success",
          });
        } else {
          swal(response?.message || "An error occurred, please try again.", {
            icon: "warning",
          });
        }
      }
    );
  };

  const getReportTypes = () => {
    httpRequest({
      url: urls.postpaidReportTypes, method: 'get'
    }).then(response => {
      if (response.status) {
        setReportTypes(response.data);
      }
      else {
        showToast({
          message: response.message || 'Failed to fetch report types.',
          type: 'error'
        })
      }
    })
  }

  const onAccountChange = (e) => {
    e.preventDefault();
    setAccount(e.target.textContent);
  };

  const years = function (startYear) {
    var currentYear = new Date().getFullYear(),
      years = [];
    startYear = startYear || 2017;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  useEffect(() => {
    getReportTypes();
  }, [useServiceCenter]);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">PostPaid Report</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field ml-5">
                      <label>SmartPower Account</label>
                      {/* <input type="text" placeholder="SmartPower Account" onChange={(e) => setAccount(e.target.value)} /> */}
                      <TypeheadSingle
                        form={{
                          name: "acc_no",
                          type: "select_db_auto",
                          sort: "acc_no",
                          fullWidth: false,
                          table: "res_partner",
                          value: "acc_no",
                          field: "acc_no",
                          label: "Select Account",
                          picks: [],
                          lg: 3,
                        }}
                        idField={"acc_no"}
                        onChange={(e) => {
                          console.log("e", e);
                          onAccountChange(e);
                        }}
                      />
                    </div>

                    <div class="input-field">
                      <label>Start Date</label>
                      <input
                        type={"date"}
                        placeholder="Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div class="input-field">
                      <label>End Date</label>
                      <input
                        type={"date"}
                        placeholder="End Date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>

                    {/*  */}

                    <div class="input-field">
                      <label>Month</label>
                      {/* <input
                        type="text"
                        placeholder="Month"
                        onChange={(e) => setMonth(e.target.value)}
                      /> */}
                      <select onChange={(e) => setMonth(e.target.value)}>
                        {[...Array(12).keys()].map((key) => {
                          return <option value={key + 1}>{key + 1}</option>;
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Year</label>
                      <select onChange={(e) => setYear(e.target.value)}>
                        {[...years()].map((key) => {
                          return <option value={key + 1}>{key + 1}</option>;
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Report Type</label>
                      <select onChange={(e) => setReportType(e.target.value)}>
                        {reportTypes.map((item, index) => {
                          return (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Service Center</label>
                      <SelectDB
                        col={{
                          name: "id",
                          type: "select_db",
                          fullWidth: false,
                          table: "service_center",
                          field: "name",
                          label: "",
                          picks: [],
                        }}
                        handleChange={(e) => {
                          console.log(e.target);
                          setServiceCenterId(e.target.value);
                        }}
                        indx={0}
                      />
                    </div>

                    <div class="input-field">
                      <label>Use Service Center?</label>
                      <Checkbox
                        checked={useServiceCenter}
                        onClick={(evt) => {
                          console.log("evt", evt);
                          setServiceCenter(!useServiceCenter);
                          // handleChange && handleChange(evt, evt.target.value);
                        }}
                      />
                    </div>

                    <div class="input-field">
                      <label>Min Amount</label>
                      <input
                        type={"number"}
                        placeholder="Min Amount"
                        onChange={(e) => setMinAmount(e.target.value)}
                        value={minAmount}
                      />
                    </div>

                    <div class="input-field">
                      <label>Max Amount</label>
                      <input
                        type={"number"}
                        placeholder="Max Amount"
                        onChange={(e) => setMaxAmount(e.target.value)}
                        value={maxAmount}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Generate Report
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}

export default PostpaidReportForm;
