/* eslint-disable unused-imports/no-unused-imports */
import { TableChart } from "@material-ui/icons";
import {
  dailyStartAndEnd,
  managementDashboardUrls,
  monthStartAndEnd,
  weekStartAndEnd,
  yearStartAndEnd,
} from "../../utils/utils";

// Customer Class
export const WeeklySumArrearsByCustomerClassModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerClass({
        startDate: weekStartAndEnd().start,
        endDate: weekStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Class for the Week",
  }
);

export const MonthlySumArrearsCustomerClassModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerClass({
        startDate: monthStartAndEnd().start,
        endDate: monthStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 650,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Class for the Month",
  }
);

export const DailySumArrearsByCustomerClassModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerClass({
        startDate: dailyStartAndEnd().start,
        endDate: dailyStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Class for the Day",
  }
);

export const YearlySumArrearsCustomerClassModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerClass({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 650,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Class for the Year",
  }
);


export const TotalPrepaidArrearsByServiceCentersModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalPrepaidArrearsByServiceCenters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 1200,
      height: 350,
    },
    fields: {
      argumentField: "sum",
      valueField: "name",
      // countField: "sum",
    },
    title: "Prepaid Arrears By Service Center",
  }
);

export const TotalActivePostpaidArrearsByServiceCentersModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalActivePostpaidArrearsByServiceCenters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 1200,
      height: 350,
    },
    fields: {
      argumentField: "sum",
      valueField: "name",
      // countField: "sum",
    },
    title: "Active Postpaid Arrears By Service Center",
  }
);

export const TotalSuspendedPostpaidArrearsByServiceCentersModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalSuspendedPostpaidArrearsByServiceCenters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 1200,
      height: 350,
    },
    fields: {
      argumentField: "sum",
      valueField: "name",
      // countField: "sum",
    },
    title: "Active Postpaid Arrears By Service Center",
  }
);

export const TotalPrepaidArrearsByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalPrepaidArrearsByCustomerCategory,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 350,
    },
    fields: {
      argumentField: "customer_category",
      valueField: "sum",
      // countField: "sum",
    },
    title: "Prepaid Arrears By Customer Category",
  }
);

export const TotalActivePostpaidArrearsByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalActivePostpaidArrearsByCustomerCategory,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 350,
    },
    fields: {
      argumentField: "customer_category",
      valueField: "sum",
      // countField: "sum",
    },
    title: "Active Postpaid Arrears By Customer Category",
  }
);

export const WeeklySumArrearsByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerCategory({
        startDate: weekStartAndEnd().start,
        endDate: weekStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Customer Category Arrears for Week",
  }
);

export const MonthlySumArrearsCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerCategory({
        startDate: monthStartAndEnd().start,
        endDate: monthStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Category for the Month",
  }
);

export const YearlySumArrearsCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumArrearsByCustomerCategory({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Arrears By Customer Category for the Year",
  }
);

export const WeeklySumPrepaidArrearsModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    title: "Sum of Arrears for the Week",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumArrearsTransaction({
        startDate: weekStartAndEnd().start,
        endDate: weekStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    footerText: "Arrears",
  }
);

export const MonthlySumPrepaidArrearsModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    title: "Sum of Arrears for the Month",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumArrearsTransaction({
        startDate: monthStartAndEnd().start,
        endDate: monthStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    footerText: "Arrears",
  }
);

export const YearlySumPrepaidArrearsModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    title: "Sum of Arrears for the Year",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumArrearsTransaction({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    footerText: "Arrears",
  }
);
