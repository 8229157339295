/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { GenerateReport, httpRequest } from "../../utils/apis";
import swal from "sweetalert";
import {
  base64ToArrayBuffer,
  saveByteArray,
  showToast,
  smartCashAnalyticsDashboardUrls,
  urls,
} from "../../utils/utils";
import { Button, Checkbox, Grid } from "@material-ui/core";
// import SelectDBAuto from "../../components/selectDbAuto";
import { Stack } from "@mui/material";
import SelectDB from "../selectDb";
import SelectDBAuto from "../selectDbAuto";
import { TypeheadSingle } from "../Typehead/typehead";
//import { toast } from "react-toastify";

function VendorTransactionReportForm() {

  const [accounts, setAccounts] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [purposes, setPurposes] = useState([]);
  const [billTypes, setBillTypes] = useState([]);
  const [channels, setChannels] = useState([]);
  const [vendors, setVendors] = useState([]);
  

  const [paymentMode, setPaymentMode] = useState(undefined);
  const [purpose, setPurpose] = useState(undefined);
  const [billType, setBillType] = useState(undefined);
  const [channel, setChannel] = useState(undefined);

  const [account, setAccount] = useState("");
  const [vendor, setVendor] = useState("");
  const [startDate, setStartDate] = useState(undefined);

  const [fileName, setFileName] = useState("");

  let checkboxRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();

    showToast({
      message: "Generating report...",
      type: "success",
    });

    await httpRequest({
      url: urls.generateVendorTransactionReport,
      method: "post",
      data: {
        purpose,
        channel,
        paymentMode,
        billType,
        startDate,
        vendor,
      },
    }).then((response) => {
      if (response && response.status) {
        setFileName(response.data.fileName);
        saveByteArray(
          `vendor_transaction_report_${response.data.fileName}`,
          base64ToArrayBuffer(response.data.base64),
          response.data.extension
        );

        swal("Report Generated.", {
          icon: "success",
        });
      } else {
        swal(response?.message || "An error occurred, please try again.", {
          icon: "warning",
        });
      }
    });
  };

  const fetchFormFields = () => {
    httpRequest({
      url: urls.vendorTransactionReportFormFields,
      method: "get",
    })
      .then((result) => {
        if (result.status) {
          setAccounts(result.data.accountNumber);
          setPaymentModes(result.data.payment_mode);
          setChannels(result.data.channel);
          setBillTypes(result.data.billTye);
          setPurposes(result.data.purpose);
          setVendors(result.data.vendor);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const onAccountChange = (e) => {
    e.preventDefault();
    setAccount(e.target.textContent);
  };

  const onVendorChange = (e) => {
    e.preventDefault();
    setVendor(e.target.value);
  };

  useEffect(() => {
    fetchFormFields();
  }, []);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Vendor Transaction Report</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field ml-5">
                      <label>Account Number</label>
                      {/* <input type="text" placeholder="SmartPower Account" onChange={(e) => setAccount(e.target.value)} /> */}
                      <TypeheadSingle
                        form={{
                          name: "account_number",
                          type: "select_db_auto",
                          fullWidth: false,
                          field: "account_number",
                          label: "Select Account",
                          picks: accounts,
                          lg: 3,
                        }}
                        idField={"account_number"}
                        onChange={(e) => {
                          console.log("e", e);
                          onAccountChange(e);
                        }}
                      />
                    </div>

                    <div class="input-field ml-5">
                      <label>Vendor</label>
                      {/* <input type="text" placeholder="SmartPower Account" onChange={(e) => setAccount(e.target.value)} /> */}
                      <select onChange={(e) => onVendorChange(e)}>
                        <option value={""}>Select Vendor</option>
                        {vendors && vendors.map((key) => {
                          if (!key["vendor"]) return;
                          return (
                            <option value={key["vendor"]}>
                              {key["vendor"]}
                            </option>
                          );
                        })}
                      </select>
                      {/* <TypeheadSingle
                        form={{
                          name: "vendor",
                          type: "select_db",
                          sort: "vendor",
                          fullWidth: false,
                          value: "vendor",
                          field: "vendor",
                          label: "Select Vendor",
                          picks: vendors.map(item => item.vendor),
                          lg: 3,
                        }}
                        idField={"acc_no"}
                        onChange={(e) => {
                          console.log("e", e);
                          onVendorChange(e);
                        }}
                      /> */}
                    </div>

                    <div class="input-field">
                      <label>Select Date</label>
                      <input
                        type={"date"}
                        placeholder="Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div class="input-field">
                      <label>Payment Mode</label>
                      {/* <input
                        type="text"
                        placeholder="Month"
                        onChange={(e) => setMonth(e.target.value)}
                      /> */}
                      <select onChange={(e) => setPaymentMode(e.target.value)}>
                        <option value={""}>Select Payment Mode</option>
                        {paymentModes && paymentModes.map((key) => {
                          if (!key["payment_mode"]) return;
                          return (
                            <option value={key["payment_mode"]}>
                              {key["payment_mode"]}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Channel</label>
                      <select onChange={(e) => setChannel(e.target.value)}>
                        <option value={""}>Select Channel</option>
                        {channels && channels.map((key) => {
                          if (!key["channel"]) return;
                          return (
                            <option value={key["channel"]}>
                              {key["channel"]}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Bill Type</label>
                      <select onChange={(e) => setBillType(e.target.value)}>
                        <option value={""}>Select Bill Type</option>
                        {billTypes && billTypes.map((item, index) => {
                          if (!item["bill_type"]) return;
                          return (
                            <option key={index} value={item["bill_type"]}>
                              {item["bill_type"]}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="input-field">
                      <label>Purpose</label>
                      <select onChange={(e) => setPurpose(e.target.value)}>
                        <option value={""}>Select Purpose</option>
                        {purposes && purposes.map((item, index) => {
                          if (!item["purpose"]) return;
                          return (
                            <option key={index} value={item["purpose"]}>
                              {item["purpose"]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Generate Report
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}

export default VendorTransactionReportForm;
