/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import "assets/css/bulk.css";
import AutoBulkUpload from "./AutoBulkUpload";
import { BulkKeyChangeModel } from "model/meter/bulk_processes";
import Header from "./../../components/Headers/Header";
import { generateKey } from "utils/utils";
import { BulkModel } from "../../model/meter/bulk_processes";

function BulkChange() {
  return (
    <>
      <Header />
      <AutoBulkUpload
        key={generateKey()}
        //form={BulkKeyChangeModel}
        form={BulkModel({ uploadType: "" })}
      />
    </>
  );
}
export default BulkChange;
