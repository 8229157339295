import BasicMenu from "./../components/SimpleMenu";
import { UniqueCode } from "utils/events";
import { useState } from "react";
import SwapMeterModel from "./swap_meter_model";
import { triggerMenuActionController } from "controllers/trinity-item.controller";
import AutoForms from "components/AutoForm";
import { generateKey } from "utils/utils";

const uniqId = `L${UniqueCode(1000000)}`;
const LocationFormModel = {
  edit: false,
  table: "prepaid_location",
  uniqId: uniqId,
  fields: [
    // {name:"status",label:"Activate?.",type:"switch"},
    {
      fullWidth: true,
      validate: { required: true },
      name: "address_1",
      type: "text",
      label: "Address",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "phone",
      type: "phone",
      label: "Phone number",
    },
    { name: "locid", type: "hidden", value: uniqId, label: "Loc ID" },
    {
      name: "city",
      type: "select",
      fullWidth: false,
      label: "City",
      picks: [
        { id: "aba", text: "Aba" },
        { id: "ariaria", text: "Ariaria" },
      ],
    },
    {
      name: "state",
      type: "select",
      fullWidth: false,
      label: "State(s)",
      picks: [{ id: "aba", text: "Aba" }],
    },
    {
      name: "feeder",
      type: "select_db",
      sort: "name",
      fullWidth: true,
      table: "feeder_feeder",
      field: "name",
      label: "Feeder",
      picks: [],
    },
    {
      name: "locdistrict",
      type: "select_db",
      fullWidth: false,
      table: "res_district",
      field: "name",
      label: "Loc District",
      picks: [],
    },
    {
      fullWidth: false,
      validate: { required: false },
      name: "zip_code",
      type: "text",
      label: "ZipCode",
    },
    {
      fullWidth: false,
      validate: { required: false },
      name: "longitude",
      type: "text",
      label: "Longitude",
    },
    {
      fullWidth: false,
      validate: { required: false },
      name: "latitude",
      type: "text",
      label: "Latitude",
    },
  ],
  desc: "Location Create/Edit",
  title: "Location Setup",
};

export const LocationReadModel = {
  action: {
    component: [
      ({ data }) => {
        const [showMenu, setShowMenu] = useState(false);
        const [formModel, setFormModel] = useState(SwapMeterModel);
        return (
          <>
            <BasicMenu
              options={[
                {
                  name: "Swap Meter",
                  action: () => {
                    triggerMenuActionController(
                      "swap-meter",
                      setShowMenu,
                      setFormModel,
                      data["locid"],
                      "locid"
                    );
                  },
                },
                {
                  name: "Install New Meter",
                  action: () => {
                    triggerMenuActionController(
                      "assign-meter",
                      setShowMenu,
                      setFormModel,
                      data["locid"],
                      "locid"
                    );
                  },
                },
                {
                  name: "Update Seal",
                  action: () => {
                    triggerMenuActionController(
                      "update-seal",
                      setShowMenu,
                      setFormModel,
                      data["locid"],
                      "locid"
                    );
                  },
                },
              ]}
              data-testid={`locid-menu`}
              title={"Actions"}
            />
            <AutoForms
              key={generateKey()}
              keyId={156001}
              form={formModel}
              cancel={setShowMenu}
              open={showMenu}
              submit={() => {}}
            />
          </>
        );
      },
    ],
  },
  fields: [
    {
      name: "locid",
      type: "selflink",
      link: "/admin/trinity/locid/",
      label: "LOCID.",
    },
    { name: "meter_no", type: "text", label: "Serial Number." },
    { name: "acc_no", type: "text", label: "Account" },
    { name: "street", type: "text", label: "Address." },
    { name: "phone", type: "text", label: "Phone." },
    { name: "city", type: "text", label: "City." },
  ],
  opts: {
    // sort: "desc",
    // order: "id",
    sort: "desc",
    order: "(case when locid is null then 0 when locid = 'NULL' then 1 else 2 end)",
  },
  table: "res_partner",
  // where: "locid is not null and locid != 'NULL'"
};

export const ReportReadModel = [
  {
    name: "rocid",
    type: "selflink",
    link: "/admin/trinity/rocid/",
    label: "ROCID.",
  },
  { name: "start_date", type: "text", label: "Start Date" },
  { name: "smartPower_account", type: "text", label: "SmartPower Account" },
  { name: "end_date", type: "text", label: "End Date." },
  { name: "district", type: "text", label: "District." },
  { name: "report_type", type: "text", label: "Report Type." },
  { name: "use_district?", type: "checkbox", label: "Use District?" },
  { name: "created", type: "text", label: "Created On" },
];

// const
export default LocationFormModel;
