import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const ChangeTariffIndexModel = {
  edit: true,
  editPram: { col: "locid" },
  respText: "Update was successful!",
  table: "res_partner",
  fields: [
    {
      type: "hidden",
      name: "locid",
      value: "",
    },
    {
      name: "tariff_code",
      // new_tariff
      type: "select_db",
      fullWidth: false,
      table: "customer_class",
      // tariff_extension
      field: "name",
      value: "name",
      label: "Change Tariff Index",
      picks: [],
      validate: { required: true },
    },
    {
      name: "lifenotes",
      type: "text",
      validate: { required: true },
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Change Tariff Index Create/Edit",
  title: "Change Tariff Index Setup",
};

export const ChangeTariffIndexReadModel = [];

// const
export default ChangeTariffIndexModel;
