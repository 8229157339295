import React, { useState } from "react";
import "assets/css/bulk.css";
import { FileUpload } from "../../utils/apis";
import swal from "sweetalert";
import BulkOutputPopup from "../../components/BulkOutputPopup";
import { showToast } from "../../utils/utils";

function AutoBulkUpload({ form }) {
  const [file, setFile] = useState(undefined);
  const [output, setOuput] = useState(undefined);
  const [showOutput, setShowOuput] = useState(false);
  const [outputExcelBase64, setOutputExcelBase64] = useState(undefined);
  const [uploadType, setUploadType] = useState(form.uploadType);

  const uploadTypes = [
    {
      name: "Select upload type",
      value: "",
    },
    {
      name: "Bulk Key Change",
      value: "bulk_keychange",
    },
    {
      name: "Bulk Free Issue",
      value: "bulk_freeissue",
    },
    {
      name: "Bulk Meter",
      value: "bulk_meter",
    },
    {
      name: "Bulk Clear Token",
      value: "bulk_cleartoken",
    },
    {
      name: "Bulk Tamper Token",
      value: "bulk_tampertoken",
    },
    {
      name: "Bulk Max Power",
      value: "bulk_maxpower",
    },
  ];

  // $(document).ready(function(){
  //   $('form input').change(function () {
  //     $('form p').text(this.files.length + " file(s) selected");
  //   });
  // });

  const onChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFile(file);
    console.log("file", file, form);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!file) {
      swal("Please select a file", {
        icon: "warning",
      });
      return;
    }
    if (!uploadType) {
      swal("Please select upload type", {
        icon: "warning",
      });
      return;
    }

    showToast({
      message: "File upload started",
      type: "success",
    });

    FileUpload({
      file: file,
      uploadType: uploadType,
    })
      .then((response) => {
        console.log("response", response);

        if (response && response.status) {
          swal(response.message || "File uploaded.", {
            icon: "success",
            timer: 1.5,
          });
          if (response.data) {
            setOuput(response.data);
            setShowOuput(true);
            setOutputExcelBase64(response.base64);
          }
        } else {
          swal(response ? response.message : "An error occurred.", {
            icon: "warning",
          });
        }
      })
      .catch((err) => console.log("err", err));
  };

  const onUploadTypeChange = (e) => {
    e.preventDefault();
    setUploadType(e.target.value);
  };

  return (
    <>
      <br />
      <form method="POST" encType={"multipart/form-data"} className="forms">
        <div>
          <input
            type="file"
            accept={form.accept || ".csv,.xlsx"}
            onChange={onChange}
          />
          <p>
            {form.descritpionText || "Click in this area to upload your file"}
          </p>
        </div>
        <select onChange={onUploadTypeChange} value={uploadType}>
          {uploadTypes.map((item) => {
            return <option value={item.value}>{item.name}</option>;
          })}
        </select>
        <button type="submit" onClick={(e) => onSubmit(e)}>
          {form.buttonText || "Upload"}
        </button>
      </form>

      <BulkOutputPopup
        open={showOutput}
        output={output}
        handleClose={() => setShowOuput(!showOutput)}
        title={"Result"}
        base64={outputExcelBase64}
      />
    </>
  );
}

export default AutoBulkUpload;
