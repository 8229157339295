/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { AutoBarVisualization } from "../../../components/AutoVisualization/bar.charts";
import componentStyles from "assets/theme/components/header.js";
import {
  WalletSingleModel,
  ArrearsSingleModel,
  PostpaidTransactionSingleModel,
  LastPaymentModel,
} from "./../../../model/postpaid_cardstat_model";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { AutoformRenderCard } from "./../../../components/AutoFormRenderCard";
import {
  DailySumTransactionByTypeModel,
  DailySumTransactionModel,
  MonthlySumTransactionByTypeModel,
  MonthlySumTransactionModel,
  PostpaidTransactionByBillTypeModel,
  PostpaidTransactionByCustomerCategoryModel,
  SumPrepaidTransactionMonthlyModel,
  SumPrepaidTransactionWeeklyModel,
  WeeklySumTransactionByTypeModel,
  WeeklySumTransactionModel,
  YearlySumTransactionByTypeModel,
  YearlySumTransactionModel,
} from "./../../../model/dashboard/transaction_model";

export default function TransactionDashboard() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  React.useEffect(() => {}, []);
  // const _caller = "acc_no";
  // const requester = "3437469750";

  return (
    <div>
      <div
        className={classes.header}
        style={{ height: 200, paddingTop: 15, marginBottom: 10 }}
      >
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={DailySumTransactionModel}
                  prefix={"NGN"}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={WeeklySumTransactionModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>

              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={MonthlySumTransactionModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={YearlySumTransactionModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      {/* Transactions */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: SumPrepaidTransactionWeeklyModel.style.width,
            height: SumPrepaidTransactionWeeklyModel.style.height,
            marginLeft: '10%',
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization
            form={SumPrepaidTransactionWeeklyModel}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: SumPrepaidTransactionMonthlyModel.style.width,
            height: SumPrepaidTransactionMonthlyModel.style.height,
            marginLeft: '10%',
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization
            form={SumPrepaidTransactionMonthlyModel}
          />
        </div>
      </div>
      {/* Postpaid */}
      
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: PostpaidTransactionByCustomerCategoryModel.style.width,
            height: PostpaidTransactionByCustomerCategoryModel.style.height,
            marginLeft: '10%',
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization
            form={PostpaidTransactionByCustomerCategoryModel}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: PostpaidTransactionByBillTypeModel.style.width,
            height: PostpaidTransactionByBillTypeModel.style.height,
            marginLeft: '10%',
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization form={PostpaidTransactionByBillTypeModel} />
        </div>
      </div>
    </div>
  );
}
