import { UniqueCode } from "utils/events";

let AccountFormModel = {
  edit: false,
  table: "res_partner",
  fields: [
    // {name:"status",label:"Activate?.",type:"switch"},
    {
      fullWidth: true,
      validate: { required: true },
      name: "name",
      type: "text",
      label: "Full name.",
    },
    {
      name: "acc_no",
      type: "hidden",
      value: UniqueCode(1000000000),
      label: "Account Number",
    },
    {
      name: "tariff_code",
      type: "select_db",
      fullWidth: false,
      table: "customer_class",
      field: "name",
      label: "Tariff",
      picks: [],
    },
    {
      name: "district",
      type: "select_db",
      fullWidth: false,
      table: "res_district",
      field: "name",
      value: "id",
      // field: "concat(id,' - ', tariff_code)",

      label: "Disttrict",
      picks: [],
    },
    {
      //name: "billing_type",
      name: "metering_type",
      type: "select",
      fullWidth: false,
      label: "Billing Type",
      picks: [
        { id: "prepaid", text: "Prepaid" },
        { id: "postpaid", text: "Postpaid" },
      ],
    },
    {
      name: "invoice_warn",
      value: "no-messages",
      type: "hidden",
    },
    {
      name: "picking_warn",
      value: "no-messages",
      type: "hidden",
    },
  ],
  desc: "Account Create/Edit",
  title: "Account Setup",
};

export default AccountFormModel;
