export const GeneratedTokenModel = {
  defaultValue: `XXXX XXXX XXXX`,
  type: "render",
  defaultHeader: "Generated Token",
  prefix: "",
  title: "Token",
};

export const PreviousArrearsModel = {
  defaultValue: `XXXX XXXX XXXX`,
  type: "",
  defaultHeader: "Arrears Lookup",
  prefix: "",
  title: "Customer Arrears",
  table: "res_partner",
  columnToRender: "new_arrears",
  name: "arrears",
};
