/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

import componentStyles from "./../../assets/theme/components/header.js";

const useStyles = makeStyles(componentStyles);

const Header = ({style}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <div className={[classes.header]} style={style}>
        {/* <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
          style={{maxHeight: 5}}
        >
          <div>
            <Grid container></Grid>
          </div>
        </Container> */}
      </div>
    </>
  );
};

export default Header;
