import React from "react";
import FetchTable from "./../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
import LocationFormModel, { LocationReadModel } from "./../../model/location_model";
import AutoForms from "components/AutoForm";

function LocationTree() {
  const [datas, setDatas] = React.useState([]);
  const [addloc, setAddLoc] = React.useState(false);

  const sendSubmit = () => {
    window.location.href = `/admin/trinity/locid/${LocationFormModel.uniqId}`;
  };

  React.useEffect(() => {}, [datas]);
  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Location",
          add: setAddLoc,
          table: LocationReadModel.table || "prepaid_location",
          where: LocationReadModel.where,
        }}
        columns={LocationReadModel.fields}
        records={[]}
        form={LocationReadModel}
      />

      <AutoForms
        key={0}
        keyId={1567}
        form={LocationFormModel}
        cancel={setAddLoc}
        open={addloc}
        submit={sendSubmit}
      />
    </>
  );
}

export default LocationTree;
