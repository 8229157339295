import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
// selfSubmit == submit function would be handled outside of Autoform
// getValues == returns values of the field element for this model
const TransactionReversalForm = {
  fields: [
    {
      name: "actionType",
      type: "select",
      fullWidth: false,
      required: true,
      label: "Action type",
      picks: [
        // { id: "reversal", text: "Reverse Transaction" },
        { id: "duplicate", text: "Treat Duplicate" },
        { id: "internal-reversal", text: "Reverse to Quick Cash." },
        
      ],
    },
    {
      name: "reason",
      type: "text",
      value: "",
      label: "Reversal Reason",
    },
    {
      name: "type",
      type: "hidden",
      value: ``,
    }
  ],
  desc: "",
  title: "Reverse Transaction",
  get getValues() {
    return this.fields;
  },
  selfSubmit: true,
};

export default TransactionReversalForm;
