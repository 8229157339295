/* eslint-disable unused-imports/no-unused-imports */
import * as React from "react";
import Paper from "@mui/material/Paper";
import { Chart, PieSeries } from "@devexpress/dx-react-chart-material-ui";
import {
  ArgumentAxis,
  EventTracker,
  ValueAxis,
} from "@devexpress/dx-react-chart";
import { Title, Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { useState } from "react";
import Controller from "utils/controls";
import { useEffect } from "react";
import { prepareValue } from "utils/utils";

const _data = [
  { country: "Russia", area: 12 },
  { country: "Canada", area: 7 },
  { country: "USA", area: 7 },
  { country: "China", area: 7 },
  { country: "Brazil", area: 6 },
  { country: "Australia", area: 5 },
  { country: "India", area: 2 },
  { country: "Others", area: 55 },
];

export const AutoPieVisualizationBinary = ({ form }) => {
  if (!form) form = {};
  const [targetItem, setTargetItem] = useState();
  const [targetItemText, setTargetItemText] = useState("");
  const [data, setData] = useState(undefined);
  const [valueField, setValueField] = useState(undefined);
  const [totalCountField, setTotalCountField] = useState(undefined);

  const changeTargetItem = (targetItem) => {
    setTargetItem(targetItem);
    if (targetItem)
      setTargetItemText(
        data.filter((x) => x.area == targetItem.point)[0].country
      );
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    Controller({
      control: form?.control,
      url: form?.request?.url,
      data: form?.fields,
      method: form?.request?.method,
      baseUrl: form?.request?.baseUrl,
    }).then((response) => {
      if (response && response.status) {
        setData(response.data);
        // sum total and counts
        setValueField(0);
        let valueTotal = 0;
        let countTotal = 0;

        response.data.map((item) => {
          valueTotal += item[form.fields.valueField];
          if (form?.fields?.countField) {
            countTotal += item[form.fields.countField];
          }
        });
        setValueField(valueTotal);
        setTotalCountField(countTotal);
      } else {
        console.log("error ", response.message);
      }
    });
  };

  return (
    <div style={{ background: "#fff" }}>
      <Paper style={{ width: form?.style?.width || 250, padding: 10 }}>
        <div
          style={{
            fontSize: 13,
            marginBottom: 5,
            marginLeft: 5,
            paddingTop: 5,
          }}
        >
          {(form && form.title) || ""}{" "}
        </div>
        <Chart
          data={data || _data}
          height={form?.style?.height || 250}
          width={form?.style?.width || 250}
        >
          <ArgumentAxis />
          <ValueAxis />
          <PieSeries
            valueField={form?.fields?.valueField || "area"}
            argumentField={form?.fields?.argumentField || "country"}
          />
          <EventTracker />
          <Tooltip
            targetItem={targetItem}
            onTargetItemChange={changeTargetItem}
          />
        </Chart>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {valueField && (
            <div style={{ fontSize: 13 }}>
              Total Amount of NGN {prepareValue("currency", valueField)}
            </div>
          )}

          {totalCountField && (
            <div style={{ fontSize: 13 }}>
              Total Count {prepareValue("number", totalCountField)}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};
// }
