import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
import React from "react";
import { useHistory } from "react-router-dom";
import { PendingMeterRequestModel } from "./../../model/meter_request_model";

export default function MeterRequest() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Pending Meter Request",
          table: PendingMeterRequestModel.table,
          control: PendingMeterRequestModel.control,
        }}
        columns={PendingMeterRequestModel.fields}
        records={[]}
        form={PendingMeterRequestModel}
      />
    </>
  );
}
