/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import { makeStyles } from "@material-ui/core/styles";
// import { useTheme } from "@material-ui/core/styles";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid } from "@material-ui/core";
import SelectDB from "./selectDb";
import DateTimeAuto from "./DateTime";
import componentStyles from "assets/theme/views/admin/profile.js";
import { Select } from "@material-ui/core";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Switch,
  FormControlLabel,
  //Checkbox,
} from "@material-ui/core";
import ActLoader from "./Loader/loader";
import Controller from "../utils/controls";
import swal from "sweetalert";
import SelectDBAuto from "./selectDbAuto";
import { AutoformCheckbox } from "./Checkbox/Checkbox";
import { AutoformRadioButton } from "./RadioButton/radioButton";
import { AutoformButton } from "./AutoformButton/button";
import AutoformRenderInput from "./AutoformRenderInput";
import { generateKey } from "./../utils/utils";
import { GeneratedTokenModel } from "../model/autoform_renderinput_model";
// import { Alert } from '@mui/material';
import ReceiptDialog, { PopupReceipt } from "./receipt/dialog";

const useStyles = makeStyles(componentStyles);
function AutoForms({ keyId = 0, form = {}, open, cancel, submit = null }) {
  const [loading, setLoading] = React.useState(false);
  //const [select_vals, setSelVals] = React.useState({});
  const [token, setToken] = React.useState(undefined);
  const [showTokenPopup, setshowTokenPopup] = React.useState(false);

  let select_vals = {};
  const setSelVals = (data) => {
    select_vals = data;
  };

  const classes = useStyles();
  const handleClose = (evt) => {
    console.log("Closing...");
    let action = evt.currentTarget.getAttribute("actionsend");
    if (action === "cancel") {
      cancel(false);
    }
  };

  const handleChange = ($evt, value) => {
    if (typeof value === "object") {
      try {
        let preval = { ...select_vals };
        //let entry = Object.entries(value);
        // value['props'] contains needed value e.g {"value": "prepaid","children": "Prepaid"}
        //{"value": 30,"children": "D1T"}

        //preval[entry[0][0]] = entry[0][1];
        preval[$evt.target.name] = value["props"].value;
        setSelVals(preval);
      } catch (e) {
        let preval = { ...select_vals };
        let entry = Object.entries(value);
        preval[entry[0][0]] = entry[0][1];
        setSelVals(preval);
        console.log("params -#>", preval);
      }
    } else {
      console.log("params -/>", value);
      let preval = { ...select_vals };
      preval[$evt.target.name] = $evt.target.value;
      setSelVals(preval);
      console.log(preval);
      console.log($evt.target.name);
      console.log($evt.target.value);
    }

    // if($evt.target.getAttribute("myval") !== undefined){

    // }
  };

  const onChange = (e) => {
    const value = e.target.value;

    let preval = { ...select_vals };
    preval[e.target.name] = e.target.value;
    setSelVals(preval);
    console.log(value);
  };

  const _ = (id) => {
    let element = window.document.getElementById(id);
    return element;
  };

  const getValue = (field) => {
    let value = undefined;
    console.log("field.type", field.type);
    switch (field.type) {
      case "switch":
        value = _(field.name).checked;
        break;
      case "check":
        value = field.value;
        break;
      case "radio":
        value = select_vals[field.name];
        break;
      case "select":
      case "select_db":
      case "select_db_auto":
        value = select_vals[field.name];
        break;
      default:
        value = _(field.name).value;
        break;
    }
    return value;
  };
  const submitForm = async () => {
    setLoading(true);
    //Get All Data
    let validated = validateForm();
    console.log(validated);
    if (validated === false) {
      setLoading(false);
      return;
    }
    let sendForm = { table: form.table, control: "create" };
    console.log("sendform", sendForm, "form", form);
    if (form.edit === true) {
      sendForm.control = "edit";
      sendForm["editconf"] = form.editPram;
    }

    let toSubmit = form.fields;
    let formData = {};
    toSubmit.forEach((val) => {
      formData[`${val.name}`] = getValue(val);
    });
    sendForm["formData"] = formData;
    if (form.control !== undefined) {
      sendForm.control = form.control;
      if (sendForm.control === "create_update") {
        form.updateForm[form.key] = sendForm.formData[form.key];
        sendForm["updatePayload"] = form.updateForm;
      }
    }
    console.log("sendForm", sendForm);
    if (form.selfSubmit) {
      // if selfSubmit, submit action is handled externally by model
      setLoading(false);
      cancel(false);
      submit(sendForm);
      return;
    }
    await Controller(sendForm)
      .then((res) => {
        if (typeof res === "boolean") {
          if (res === true) {
            swal(
              form.respText !== undefined
                ? form.respText
                : "Creation was Succesful!",
              {
                icon: "success",
              }
            );

            if (submit !== null) {
              submit();
              return true;
            }
            return;
          } else {
            swal("Something Went Wrong!!!", {
              icon: "warning",
            });
            return false;
          }
        } else if (typeof res === "object") {
          if (res.status && res.control === "meter_action") {
            setToken(res.data);
            setshowTokenPopup(true);
            // handleClose();
            return true;
          } else {
            swal(
              res.message !== undefined
                ? res.message
                : "Action was not succesful!",
              {
                icon: "error",
              }
            );
            return false;
          }
        }
        throw new Error('Something went wrong, please try again later.');
      })
      .catch((err) => {
        swal(err.toString(), {
          icon: "error",
        });
        return false;
      });
    setLoading(false);
    cancel(false);
  };

  const validateForm = () => {
    let toSubmit = form.fields;
    let resp = true;
    toSubmit.forEach((val) => {
      if (val.validate !== undefined) {
        //Check For Required Fields

        let rule = val.validate;
        if (rule.required === true) {
          if ((getValue(val) === "") | (getValue(val) === "")) {
            alert(`${val.label} is required!!!`);
            resp = false;
          }
        }
        if (rule.length !== undefined) {
        }
        if (rule.count !== undefined) {
        }
        if (rule.maxvalue !== undefined) {
        }
        if (rule.minvalue !== undefined) {
        }
      }
    });
    return resp;
  };

  useEffect(() => {}, []);

  return (
    <>
      <Dialog key={keyId} open={open} onClose={handleClose}>
        <DialogTitle key={generateKey()}>{form.title}</DialogTitle>
        <DialogContent key={generateKey()}>
          <DialogContentText>{form.desc}</DialogContentText>
          <Grid key={generateKey()} container>
            {form &&
              form.fields &&
              form.fields.map((field, indx) => {
                switch (field.type) {
                  case "switch":
                    return (
                      <Grid key={generateKey()} item xl={3} lg={6} xs={12}>
                        <FormControlLabel
                          label={field.label}
                          control={
                            <Switch
                              id={field.name}
                              key={`${indx}switch`}
                              defaultChecked
                            />
                          }
                        />
                      </Grid>
                    );
                  case "check":
                    return (
                      <AutoformCheckbox
                        field={field}
                        key={generateKey()}
                        handleChange={handleChange}
                      />
                    );
                  case "radio":
                    return (
                      <AutoformRadioButton
                        field={field}
                        key={generateKey()}
                        handleChange={handleChange}
                      />
                    );
                  case "button":
                    return (
                      <AutoformButton
                        field={field}
                        key={generateKey()}
                        handleChange={handleChange}
                      />
                    );
                  case "select_db":
                    return (
                      <SelectDB
                        key={generateKey()}
                        col={field}
                        indx={indx}
                        handleChange={handleChange}
                        form={form}
                      />
                    );
                  case "select_db_auto":
                    return (
                      <SelectDBAuto
                        key={generateKey()}
                        col={field}
                        indx={indx}
                        handleChange={handleChange}
                        form={form}
                      />
                    );
                  case "select":
                    return (
                      <Grid
                        key={generateKey()}
                        item
                        xs={12}
                        lg={
                          field.fullWidth === undefined ||
                          field.fullWidth === false
                            ? 6
                            : 12
                        }
                      >
                        <FormControl focused={true} fullWidth={true}>
                          <InputLabel
                            key={`${indx}label`}
                            id={`select_label_${field.name}`}
                          >
                            {field.label}
                          </InputLabel>
                          <Select
                            labelId={`select_label_${field.name}`}
                            key={`${indx}select`}
                            id={`${field.name}`}
                            label={field.label}
                            defaultValue={null}
                            myval={field.name}
                            data-id={`${field.name}`}
                            name={field.name}
                            onClick={(evt) => {
                              onChange(evt);
                            }}
                          >
                            <MenuItem key="lolaa" disabled={true} value={null}>
                              Select {field.label}
                            </MenuItem>
                            {field.picks !== undefined &&
                              field.picks.map((val, s_indx) => {
                                return (
                                  <MenuItem key={s_indx} value={val.id}>
                                    {val.text}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  case "datetime":
                    return (
                      <DateTimeAuto
                        key={generateKey()}
                        col={field}
                        indx={indx}
                        handleChange={handleChange}
                      />
                    );
                  case "hidden":
                    return (
                      <TextField
                        key={generateKey()}
                        margin="dense"
                        id={field.name}
                        type="hidden"
                        value={field.value}
                        fullWidth={false}
                        placeholder={`${field.label}`}
                      />
                    );

                  default:
                    return (
                      <Grid
                        key={generateKey()}
                        item
                        xs={12}
                        lg={
                          field.fullWidth === undefined ||
                          field.fullWidth === false
                            ? 6
                            : 12
                        }
                      >
                        <TextField
                          margin="dense"
                          id={field.name}
                          label={`${field.label}`}
                          name={field.name}
                          type="text"
                          focused={true}
                          fullWidth={true}
                          placeholder={`${field.label}`}
                          variant="standard"
                        />
                      </Grid>
                    );
                }
              })}
          </Grid>
        </DialogContent>

        <DialogActions>
          {}
          <Button actionsend="cancel" onClick={handleClose}>
            Cancel
          </Button>

          <Button
            classes={{ root: classes.buttonRootDark }}
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <>
        {/* <AutoformRenderInput
              form={GeneratedTokenModel}
              open={token !== undefined}
              handleClose={() => setToken(undefined)}
              renderValue={token}
            /> */}
        {/* <ReceiptDialog  */}

        <PopupReceipt
          open={showTokenPopup}
          onClose={() => setshowTokenPopup(false)}
          handleClose={() => {
            setshowTokenPopup(false);
            // setTimeout(() => {
            //   // document.getElementsByName('body')[0].style.overflow = 'scroll !important';
            //   // alert('');
            // }, 1000);
          }}
          title={"Invoice"}
          // token={token}
          data={token}
        />
      </>
      <ActLoader running={loading} />
    </>
  );
}

export default AutoForms;
