import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TablePagination,
  Fab,
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Container from "@material-ui/core/Container";
import { useTheme } from "@material-ui/core/styles";
import { List } from "@material-ui/icons";
import Search from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import fetchRecords from "../../utils/fetch";

// import Fab from "@mui/material/Fab";
import LoadingOverlay from "react-loading-overlay";
//import AddIcon from "@mui/icons-material/Add";

import Pagination from "@material-ui/lab/Pagination";

import componentStyles from "assets/theme/views/admin/tables.js";
import FetchTableHead from "components/FetchTableHead";
import FetchTableBody from "../../components/FetchTableBody";

import { QueryTableWhere } from "../../utils/apis";
import AutoForms from "components/AutoForm";
import Controller from "../../utils/controls";
import swal from "sweetalert";

const useStyles = makeStyles(componentStyles);

const FetchTable = ({
  settings,
  columns = [],
  records = [],
  autoForm = {},
  styles = {},
  form = undefined,
}) => {
  const [datas, setDatas] = React.useState(records);
  const [searchFor, setSearchFor] = React.useState("Record");
  const [search, setSearch] = React.useState("");
  const [overlay, setOverLay] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [renderedData, setRenderedData] = React.useState([]);

  React.useEffect(() => {
    init();
  }, [search]);

  const handleClick = (() => {})();
  const handleClose = (() => {})();
  const searchHandle = (() => {})();

  async function init() {
    let mounted = true;
    try {
      setOverLay(true);
      console.log("mounted");
      const _recs = await prepareFetchTable();
      if (mounted) {
        setDatas(_recs);
        setOverLay(false);
        updateRenderedData(page, rowsPerPage, _recs);
      }

      return () => (mounted = false);
    } catch (e) {
      console.error("e", e);
      if (mounted) setOverLay(false);
    }
  }

  function updateRenderedData(
    _page = page,
    _rowsPerPage = rowsPerPage,
    _datas = datas
  ) {
    if (_datas && _datas.length > 0) {
      setRenderedData(
        _datas.slice(_page * _rowsPerPage, _page * _rowsPerPage + _rowsPerPage)
      );
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    updateRenderedData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);

    updateRenderedData(0, event.target.value);
  };

  async function prepareFetchTable() {
    let result = [];

    if (settings.control) {
      // if control is supplied,
      // data should be supplied
      //if (!settings.data) return result;

      const payload = {
        control: settings.control,
        caller: settings.data?.caller,
        requester: settings.data?.requester,
        limit: settings.limit,
        search: search,
        data: settings.data,
      };
      if (form && form.request) {
        payload.url = form.request.url;
        payload.baseUrl = form.request.baseUrl;
        payload.method = form.request.method;
      }
      result = await Controller(payload);
      console.log("result", result);
      if (result.status) {
        result = result.data;
      } else {
        swal(result.message || "Data could not be fetched.", { icon: "error" });
      }
    } else {
      //console.log(`form?.opts`, form?.opts);
      if (!settings.where) {
        result = await fetchRecords(
          columns,
          settings.table,
          search,
          settings.limit,
          form?.opts
        );
      } else {
        result = await QueryTableWhere(
          settings.table,
          columns,
          settings.where,
          form?.opts
        );
      }
    }

    return result;
  }

  const classes = useStyles();
  const theme = useTheme();

  return (
    <LoadingOverlay active={overlay} spinner>
      <Container
        maxWidth={false}
        component={Box}
        marginTop={styles.marginTop ? styles.marginTop : "-6rem"}
        classes={{ root: classes.containerRoot }}
      >
        <Card classes={{ root: classes.cardRoot }}>
          <CardHeader
            className={classes.cardHeader}
            title={settings.name}
            titleTypographyProps={{
              component: Box,
              marginBottom: "0!important",
              variant: "h3",
            }}
          ></CardHeader>
          <Container
            display="flex!important"
            justifyContent="space-between"
            alignItems="center"
            marginTop=".75rem"
            marginBottom=".75rem"
            width="100%"
            component={Box}
            maxWidth={false}
            classes={{ root: classes.containerRoot }}
            padding="0!important"
          >
            <Box component="div" marginLeft="15px">
              <FormControl variant="outlined">
                <InputLabel
                  size="small"
                  htmlFor="outlined-adornment-search-responsive"
                >
                  {searchFor}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-search-responsive"
                  type="text"
                  endAdornment={
                    <InputAdornment position="end" cursor="hover">
                      <Box
                        component={Search}
                        width="1.25rem!important"
                        height="1.25rem!important"
                      />
                    </InputAdornment>
                  }
                  labelWidth={70}
                  onKeyDown={searchHandle}
                  onChange={($evt) => {
                    setSearch($evt.target.value);
                    // inputVal.current = $evt.currentTarget.value
                  }}
                />
              </FormControl>

              <Box
                aria-controls="simple-menu-6"
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
                component={Button}
                width="3rem!important"
                height="3rem!important"
                minWidth="2rem!important"
                minHeight="2rem!important"
              >
                <Box
                  component={List}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  position="relative"
                  top="2px"
                  color={theme?.palette?.gray ? theme.palette.gray[500] : ""}
                />
              </Box>
            </Box>

            <Box component="div" marginLeft="15px">
              <Box sx={{ "& > :not(style)": { m: 1 } }}>
                {form?.fabs &&
                  Object.values(form.fabs).map((fab, index) => {
                    return (
                      <Fab
                        color="primary"
                        aria-label="add"
                        size="small"
                        key={index}
                        onClick={fab.onClick}
                      >
                        {fab.icon}
                      </Fab>
                    );
                  })}
                {/* <Fab
                  color="primary"
                  aria-label="add"
                  actionload="create_acc"
                  onClick={() => { }}
                >
                  <AddIcon />
                </Fab>
                <Fab
                  color="primary"
                  aria-label="add"
                  actionload="create_acc"
                  onClick={() => { }}
                >
                  <AddIcon />
                </Fab> */}
              </Box>{" "}
              {/* <Box sx={{ "& > :not(style)": { m: 1 } }}>
                <Fab
                  color="primary"
                  aria-label="add"
                  actionload="create_acc"
                  onClick={() => {}}
                >
                  <AddIcon />
                </Fab>
              </Box>{" "} */}
            </Box>
          </Container>
          <TableContainer>
            <Box
              component={Table}
              alignItems="center"
              marginBottom="0!important"
            >
              <FetchTableHead columns={columns} />

              <FetchTableBody
                cols={columns}
                records={renderedData}
                control={settings.control}
                rowAction={settings.rowAction}
                form={form}
              />
            </Box>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={datas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Box
            classes={{ root: classes.cardActionsRoot }}
            component={CardActions}
            justifyContent="flex-end"
          >
            {/* <DataGridProDemo/> */}

            <Pagination
              count={1}
              onChange={($evt, val) => {
                console.log(val);

                // setOffset(val)
              }}
              color="primary"
              variant="outlined"
            />
          </Box>
        </Card>
      </Container>
      <AutoForms
        key={1001}
        keyId={15601}
        form={autoForm}
        cancel={setShowMenu}
        open={showMenu}
        submit={() => {}}
      />
    </LoadingOverlay>
  );
};

export default FetchTable;
