/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { AutoBarVisualization } from "../../../components/AutoVisualization/bar.charts";
import componentStyles from "assets/theme/components/header.js";
import {
  WalletSingleModel,
  ArrearsSingleModel,
  PostpaidTransactionSingleModel,
  LastPaymentModel,
} from "../../../model/postpaid_cardstat_model";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { AutoformRenderCard } from "../../../components/AutoFormRenderCard";
import {
  DailySumTransactionByTypeModel,
  DailySumTransactionModel,
  MonthlySumTransactionByTypeModel,
  MonthlySumTransactionModel,
  PostpaidTransactionByBillTypeModel,
  PostpaidTransactionByCustomerCategoryModel,
  WeeklySumTransactionByTypeModel,
  WeeklySumTransactionModel,
  YearlySumTransactionByTypeModel,
  YearlySumTransactionModel,
} from "../../../model/dashboard/transaction_model";
import { SumTransactionsModel } from "model/dashboard/vendors_model";
import {
  sumArrearsTransactionsModel,
  sumTransactionsByBillTypeModel,
  sumTransactionsByChannelModel,
  sumTransactionsByPaymentModeModel,
  sumTransactionsByPurposeModel,
} from "./../../../model/dashboard/vendors_model";
import { AutoPieVisualizationBinary } from "./../../../components/AutoVisualization/pie.charts";

export default function VendorsDashboard() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  React.useEffect(() => {}, []);
  // const _caller = "acc_no";
  // const requester = "3437469750";

  return (
    <div>
      <div
        className={classes.header}
        style={{ height: 200, paddingTop: 15, marginBottom: 10 }}
      >
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={6} lg={6} xs={12}>
                <AutoformRenderCard
                  //condition={`${_caller}='${requester}'`}
                  form={SumTransactionsModel}
                  prefix={"NGN"}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={6} lg={6} xs={12}>
                <AutoformRenderCard
                  //condition={`${_caller}='${requester}'`}
                  form={sumArrearsTransactionsModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>

              {/* <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  //condition={`${_caller}='${requester}'`}
                  form={MonthlySumTransactionModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />

              </Grid>
              <Grid item xl={3} lg={6} xs={12}>
                <AutoformRenderCard
                  form={YearlySumTransactionModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                  //condition={`${requester}`}
                />
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </div>
      {/* Transactions */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: sumTransactionsByBillTypeModel.style.width,
            height: sumTransactionsByBillTypeModel.style.height,
            marginLeft: 5,
            //marginBottom: 20
          }}
        >
          <AutoBarVisualization form={sumTransactionsByBillTypeModel} />
        </div>
        <div
          style={{
            width: sumTransactionsByChannelModel.style.width,
            height: sumTransactionsByChannelModel.style.height,
            marginLeft: 5,
            //marginBottom: 20
          }}
        >
          <AutoBarVisualization form={sumTransactionsByChannelModel} />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: sumTransactionsByPaymentModeModel.style.width,
            height: sumTransactionsByPaymentModeModel.style.height,
            marginLeft: 5,
            marginBottom: 20,
          }}
        >
          {/* <AutoPieVisualizationBinary
            form={sumTransactionsByPaymentModeModel}
          /> */}
          <AutoBarVisualization form={sumTransactionsByPaymentModeModel} />
        </div>
        <div
          style={{
            width: sumTransactionsByPurposeModel.style.width,
            height: sumTransactionsByPurposeModel.style.height,
            marginLeft: 5,
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization form={sumTransactionsByPurposeModel} />
        </div>
      </div>
      {/* Postpaid */}
      {/* <div style={{display: "flex", flexDirection: 'row'}}>
        <div
          style={{ 
              width: PostpaidTransactionByCustomerCategoryModel.style.width, 
              height: PostpaidTransactionByCustomerCategoryModel.style.height, 
              marginLeft: 5, marginBottom: 20 }}
          >
          <AutoBarVisualization
            form={PostpaidTransactionByCustomerCategoryModel}
          />
        </div>
        <div
          style={{ 
              width: PostpaidTransactionByBillTypeModel.style.width, 
              height: PostpaidTransactionByBillTypeModel.style.height, 
              marginLeft: 5, marginBottom: 20 }}
          >
          <AutoBarVisualization
            form={PostpaidTransactionByBillTypeModel}
          />
        </div>
      </div> */}
    </div>
  );
}
