// view vendors
// view subvendors
// view cashiers
// view agents
// view vendor's subvendors
// view subvendor's cashiers
// view subvendor's agent

import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { OwnerVendorsModel } from "../../model/vendors/owner_vendors_model";

export default function FetchOwnerVendors() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();
  const { username } = useParams();

  React.useEffect(() => {
    OwnerVendorsModel.fabs.downloadReport.onClick = () => {};
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Owner Vendors",
          table: OwnerVendorsModel.table,
          control: OwnerVendorsModel.control,
          data: {
            owner_user: username,
          },
        }}
        columns={OwnerVendorsModel.fields}
        records={[]}
        form={OwnerVendorsModel}
      />
    </>
  );
}
