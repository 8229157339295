import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Receipt from "./receipt";
import { spaceOutCharacters } from "utils/utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const getLabel = (action) => {
  let label = "";
  switch (action) {
    case "":
      break;
    case "ClearTamperCondition":
    case "tampertoken":
      label = "Tamper Reset";
      break;
    case "keychange":
      label = "key Change";
      break;
    case "freeissue":
      label = "Free Issue";
      break;
    case "setmaxpower":
      label = "Max Power";
      break;
    case "clearCreditToken":
      label = "Clear Credit Token";
      break;
    case "Credit:Vend":
    case "Credit:Electricity":
      label = "Token";
      break;
    default:
      label = null;
      break;
  }

  return label;
};

export default function ReceiptDialog({ open, onClose, title, data }) {
  const [show, setShow] = React.useState(open);

  return (
    <Dialog
      fullScreen
      open={show}
      onClose={() => {
        setShow(!show);
        onClose();
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {title || "Invoice"}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Receipt
          accountName={data.display_name}
          accountNo={data.acc_no}
          address={data.street2}
          amount={data.amount}
          invoiceId={data.invoiceId}
          invoiceToken={data.token}
          invoiceType={data.action}
          invoiceTypeLabel={getLabel(data.action)}
          meterNo={data.meter_no}
          rate={data.tariff_rate}
          subTotal={""}
          tarrif={data.tariff_name}
          transactionType={data.transaction_type}
          units={data.units}
          vat={data.vat}
        />
      </DialogContent>
    </Dialog>
  );
}

export function PopupReceipt({ keyId, open, handleClose, data }) {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  console.log('receipt data', data);

  React.useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <>
      <Dialog fullScreen key={keyId} open={open} onClose={handleClose}>
        <DialogContent>
          <Receipt
            accountName={data?.display_name}
            accountNo={data?.acc_no}
            address={
              data && data.street2
                ? data.street2.toString().substring(0, 25)
                : ""
            }
            amount={data?.amount}
            invoiceId={data?.invoice_id}
            invoiceToken={data ? spaceOutCharacters(data?.token) : ""}
            invoiceType={data?.action}
            invoiceTypeLabel={data?.invoice_type}
            meterNo={data?.meter_no}
            rate={data?.tariff_rate}
            subTotal={undefined}
            tarrif={data?.tariff_name}
            transactionType={data?.transaction_type}
            units={data?.units}
            vat={data?.vat}
            ststi={data?.ststi}
            sgc={data?.sgc}
            phases={data?.phases}
          />
        </DialogContent>
        <DialogActions>
          <Button actionsend="cancel" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
