/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//import Container from "@material-ui/core/Container";
//import FormControl from "@material-ui/core/FormControl";
//import InputAdornment from "@material-ui/core/InputAdornment";
//import InputLabel from "@material-ui/core/InputLabel";
//import OutlinedInput from "@material-ui/core/OutlinedInput";
// @material-ui/icons components
//import Search from "@material-ui/icons/Search";
// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.js";
//import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import NavbarDropdown from "components/Dropdowns/NavbarDropdown.js";
import routes from "routes.js";
import componentStyles from "assets/theme/layouts/admin.js";
import NewHeader from "../components/header.js";
import ResponsiveAppBar from "../components/NavToolTip/index.js";
import ApplicationTopBar from "components/NavToolTip/appbar.js";
//import { Divider } from "@material-ui/core";

const useStyles = makeStyles(componentStyles);

const Admin = () => {
  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout + prop.path) {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        if (routes[i].brandText === false) return "";
        return routes[i].name;
      }
    }
    return "";
  };

  return (
    <>
      <>
        {/* <Sidebar
          routes={routes.filter(route => route.layout === "/admin")}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/brand/argon-react.png").default,
            imgAlt: "...",
            innerWidth: 600,
            innerHeight: 600,
          }}
          dropdown={<NavbarDropdown />}
          input={
            <></>
          } className={classes.mainContent}
        /> */}
        {/* <NewHeader /> */}
        {/* <ResponsiveAppBar routes={routes.filter(route => route.layout === "/admin")} /> */}
        <ApplicationTopBar
          routes={routes.filter((route) => route.layout === "/admin")}
        />
        <Box position="relative">
          <AdminNavbar brandText={getBrandText(location.pathname)} />
          <Switch>
            {getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          {/* <Container
            maxWidth={false}
            component={Box}
            classes={{ root: classes.containerRoot }}
          >
            <AdminFooter />
          </Container> */}
        </Box>
      </>
    </>
  );
};

export default Admin;
