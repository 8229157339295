/* eslint-disable unused-imports/no-unused-imports */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import componentStyles from "assets/theme/components/sidebar.js";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const pages = ["Products", "Pricing", "Blog"];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = ({ routes }) => {
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();
  const history = useHistory();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [routeMenuItems, setRouteMenuItems] = React.useState([]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  console.log(routeMenuItems);

  return (
    <AppBar
      position="static"
      style={{
        background: "linear-gradient(87deg,#000, #11cdef)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters style={{ marginBottom: "-5px" }}>
          <IconButton
            onClick={() => {}}
            sx={{ p: 0 }}
            style={{ marginRight: 8 }}
          >
            <Avatar
              alt="Remy Sharp"
              src={
                require(`./../../assets/img/brand/${
                  process.env.REACT_APP_APP_LOGO
                    ? process.env.REACT_APP_APP_LOGO
                    : "argon-react.png"
                }`).default
              }
            />
          </IconButton>
          <h2 style={{ marginRight: 120 }}>SmartPower</h2>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routes &&
                routes
                  .filter((x) => x.dividerHeader)
                  .map((page) => (
                    <MenuItem key={page} onClick={handleOpenUserMenu}>
                      <Typography textAlign="center">{page.title}</Typography>
                    </MenuItem>
                  ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes &&
              routes
                .filter((x) => {
                  if (x.dividerHeader) {
                    return x;
                  }
                })
                .map((page, index) => (
                  <Button
                    key={index}
                    aria-haspopup="true"
                    variant="contained"
                    disableElevation
                    onClick={(e) => {
                      const routeItems = routes.filter((x) => {
                        if (!x.action && x.parentKey === page.uniqueKey) {
                          return x;
                        }
                      });
                      setRouteMenuItems(routeItems);
                      handleOpenUserMenu(e);
                    }}
                    sx={{ my: 2, color: "white", display: "block" }}
                    style={{
                      marginLeft: 10,
                      shadow: "none",
                      background: "transparent",
                      borderStyle: "solid",
                    }}
                  >
                    {page.title}
                    {<KeyboardArrowDownIcon style={{ marginBottom: "-6px" }} />}
                  </Button>
                ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {routeMenuItems.map((route, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    // handle Link navigation
                    if (route.layout + route.path) {
                      history.push({
                        pathname: route.layout + route.path,
                        state: {},
                      });
                    }
                    handleCloseUserMenu(e);
                  }}
                >
                  <Typography textAlign="center">{route.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
