/* eslint-disable unused-imports/no-unused-imports */
import Header from "./../../components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
import React, { useState } from "react";
import { VendorsTransactionsModel } from "../../model/vendors/vendor_transactions_model"
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { httpRequest } from "../../utils/apis"
import { showToast } from "utils/utils";
import { saveByteArray } from "utils/utils";
import { base64ToArrayBuffer } from "utils/utils";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, makeStyles, useTheme } from "@material-ui/core";
import CustomerTableHead from "../../views/admin/CusTableHead"
import CustomerTableBody from "../Tables/CusTableBody"
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import componentStyles from "assets/theme/components/header";
import { Pagination } from "@mui/material";
import { prepareValue } from "./../../utils/utils";
import UnstyledTable from "components/Tables/UnstyedTable";
import { DownloadRounded } from "@mui/icons-material";

const useStyles = makeStyles(componentStyles);
export default function VendorsTransactions() {
  const { vendor } = useParams();
  // VendorsTransactionsModel.request.url =
  //   VendorsTransactionsModel.request.url;
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [pageNumber, SetPageNumber] = useState(1);
  const [pageSize, SetPageSize] = useState(10);
  const [totalSize, SetTotalSize] = useState(10);
  const [totalPages, SetTotalPages] = useState(1);
  const [purpose, setPurpose] = useState('payment');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  const setPage = (pageNumber) => {
    if (pageNumber > 0) {
      SetPageNumber(pageNumber + 1);
      fetchData({ pageNumber: pageNumber + 1 });
    }
  }

  const buildURLParams = (payload={}) => {
    let urlParams = `?vendor=${vendor}`;
    if (payload.pageNumber || pageNumber) {
      urlParams += `&pageNumber=${(payload.pageNumber ? payload.pageNumber : pageNumber)}`;
    }
    if (payload.pageSize || pageSize) {
      const _pageSize = (payload.pageSize ? payload.pageSize : pageSize);
      if (_pageSize > -1) {
        urlParams += `&pageSize=${_pageSize}`;
      } else {
        urlParams += `&pageSize=${totalPages * totalSize}`;
      }
    }
    if (payload.startDate || startDate) {
      urlParams += `&startDate=${payload.startDate ? payload.startDate : startDate}`;
    }
    if (payload.endDate || endDate) {
      urlParams += `&endDate=${payload.endDate ? payload.endDate : endDate}`;
    }
    if (payload.purpose || purpose) {
      urlParams += `&purpose=${payload.purpose ? payload.purpose : purpose}`;
    };
    return urlParams;
  }
  const fetchData = (payload = {}) => {
    showToast({ message: "Fetching report...", type: "success" });
    httpRequest({
      url: VendorsTransactionsModel.request.url + buildURLParams(payload),
      method: 'get',
      data: null,
    }).then(response => {
      if (response.status) {
        showToast({ message: "Transactions fetched", type: "success" });
        setData(response.data.rows);
        SetTotalSize(parseInt(response.data.count));
        SetTotalPages(parseInt(response.data.totalPages))
      } else {
        showToast({ message: response.message || "Failed to fetch transactions, please try again.", type: "error" });
      }
    });
  }

  const exportReport = ()=> {
    showToast({ message: "Downloading report...", type: "success" });
      httpRequest({ url: VendorsTransactionsModel.request.url + buildURLParams() + `&report=1`, data: undefined, method: 'get' }).then(response => {
        if (response.status) {
          saveByteArray(
            response.data.fileName,
            base64ToArrayBuffer(response.data.base64),
            response.data.extension
          );
        } else {
          showToast({
            message: response.message || "An error occurred",
            type: "error",
          });
        }
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  // return (< UnstyledTable />);
  return (
    <>
      {/* <Header style={{ height: '150px !important', zIndex: 1 }} /> */}
      <div style={{backgroundColor: '#11c7ef', height: '50px', }}></div>
      <div style={{ padding: '5px' }}>
        <div style={{ fontSize: 18, fontWeight: 'bold' }}>Filter Transactions </div>
        <div style={{ display: 'flex', marginLeft: 10 }}>
          <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', flex: 1, }}>
            <div style={{ display: 'flex', marginLeft: 10,  }}>
              <label htmlFor="purpose">Purpose</label>
              <select id="purpose" style={{ marginLeft: 10, height: 35, marginTop: -5 }} onChange={(e) => setPurpose(e.target.value)} value={purpose}>
                {["payment", "transfer", "reversal"].map(x => <option value={x}>{x}</option>)}
              </select>
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <label htmlFor="start-date">Start Date</label>
              <input id='start-date' type="date" style={{ marginLeft: 10, maxHeight: 35 }} onChange={(e) => setStartDate(e.target.value)} />
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <label htmlFor="end-date">End Date</label>
              <input id='end-date' type="date" style={{ marginLeft: 10, maxHeight: 35 }} onChange={(e) => setEndDate(e.target.value)} />
            </div>
            <div style={{ display: 'flex', marginLeft: 10 }}>
              <button style={{ width: 120 }} onClick={() => fetchData({ purpose: purpose, startDate: startDate, endDate: endDate, })}>Submit</button>
            </div>
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row-reverse', padding: '10px', }}>
          <span onClick={() => { exportReport();}}>
            <DownloadRounded />
          </span>
          </div>
        </div>
      </div>

      <TableContainer>
        <Box
          component={Table}
          alignItems="center"
          marginBottom="0!important"
        >
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Account Number
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Trans. Ref
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Amount
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Commision Earned
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Bill Type
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Vendor
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Token
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Purpose
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Arrears paid
              </TableCell>
              <TableCell
                classes={{
                  root:
                    classes.tableCellRoot +
                    " " +
                    classes.tableCellRootHead,
                }}
              >
                Transaction Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data && data.map(row => RenderTableRow({
                accountNumber: row.account_number,
                transactionRef: row.transaction_ref,
                amount: row.amount,
                arrearsPaid: row.arrearspaid,
                billType: row.bill_type,
                commisionEarned: row.revenue_earned,
                token: row.token,
                purpose: row.purpose,
                transactionDate: row.trans_date,
                vendor: row.vendor,
                classes,
              }))
            }
          </TableBody>
        </Box>
      </TableContainer>
      <TablePagination
        component="div"
        // count={-1}
        count={totalSize}
        page={pageNumber - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[50, 100, 500, 1000, { label: 'All', value: -1 }]}
        onChangePage={($evt, val) => {
          console.log('change page', val);
          const value = val;
          setPage(value);

        }}
        onChangeRowsPerPage={($evt, val) => {
          const value = val.props.value;
          console.log('rows per page', value);
          SetPageSize(value);
          fetchData({ pageSize: value });
        }}
      />
      {/* <Pagination

        count={pageSize}
        onChange={($evt, val) => {
          console.log(val);
          setPage(val);
        }}
        variant="outlined" 
        shape="rounded"  
        showFirstButton 
        showLastButton
      /> */}
    </>
  );
}


const RenderTableRow = ({ accountNumber, amount, commisionEarned, billType, vendor, token, purpose, arrearsPaid, transactionDate, transactionRef, classes }) => {
  // const classes = useStyles();
  // useEffect(() => {}, []);
  return (
    <TableRow>
      <TableCell
        classes={{
          root:
            classes.tableCellRoot +
            " " +
            classes.tableCellRootBodyHead,
        }}
        component="th"
        variant="head"
        scope="row"
      >
        {accountNumber}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {transactionRef}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {amount}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {commisionEarned}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {billType}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {vendor}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {token}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {purpose}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {arrearsPaid}
      </TableCell>
      <TableCell classes={{ root: classes.tableCellRoot }}>
        {prepareValue('datetime', transactionDate)}
      </TableCell>
    </TableRow>
  );
}