import React from "react";
import { Dialog, DialogTitle, CircularProgress } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

function ActLoader({ running }) {
  return (
    <>
      <div>
        <Dialog open={running} onClose={null}>
          <DialogTitle></DialogTitle>
          {/* <DialogContent color="transperent" alignItems="center"> */}
          <center>
            <CircularProgress disableShrink />
          </center>

          {/* </DialogContent> */}
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </>
  );
}

export default ActLoader;
