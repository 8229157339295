import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Grid,
  Card,
  CardHeader,
  Box,
  Button,
  Typography,
  CardContent,
  Menu,
  MenuItem,
  Divider,
  Badge,
} from "@material-ui/core";
//import { Edit } from "@material-ui/icons";
import swal from "sweetalert";
import componentStyles from "assets/theme/views/admin/profile.js";
import AutoForms from "../components/AutoForm";
import {
  //LoadSql,
  LoadTrinity,
} from "./../utils/apis";
import AccountFormModel from "model/account_model";
import LocationFormModel from "model/location_model";
import MeterFormModel from "./../model/meter_model";
import { generateKey } from "../utils/utils";
import BasicMenu from "./SimpleMenu";
import {
  getFromLocalStorage,
  writeToLocalStorage,
} from "../utils/localStorage.util";

function TrinityCard({ opt, refresh, setRefresh }) {
  const useStyles = makeStyles(componentStyles);
  const [menu, setMenu] = React.useState(null);
  const [line, setLine] = React.useState({});
  const [assign, setAssign] = React.useState(false);

  const [add, setAdd] = React.useState(false);
  let AssignFormModel = {};

  React.useEffect(() => {
    let mounted = true;

    //LoadSql(sql)
    LoadTrinity(opt.caller, opt.title, opt.requester)
      .then((res) => {
        if (mounted === true) {
          if (res.length > 0) {
            const _returned = res[0];
            setLine(_returned);
            // get url
            // write object to local storage with data returned
            // on update, if not empty, get object and modify
            // if empty, write new
            const hrefKey = window.location.href.split("//")[1];
            const cardData = getFromLocalStorage(hrefKey);
            let cardInfo = {};
            if (cardData) {
              cardInfo = { ...cardData, ..._returned };
            } else {
              cardInfo = res[0];
            }

            if (cardInfo) writeToLocalStorage(hrefKey, cardInfo);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });

    return () => (mounted = false);
  }, [refresh]);
  const classes = useStyles();

  const handleClick = ($evt) => {
    $evt.preventDefault();
    setMenu($evt.currentTarget);
  };
  const getAdd = () => {
    let model = {};
    switch (opt.title) {
      case "Account":
        model = AccountFormModel;

        model.fields.push({
          name: "locid",
          type: "hidden",
          value: opt.requester,
        });
        break;
      case "Location":
        model = LocationFormModel;
        model.control = "create_update";
        let [params, table] = [null, null];
        switch (opt.caller) {
          case "meter":
            params = "meter_no";
            table = "meter_details";
            break;
          default:
            params = "acc_no";
            table = "res_partner";
            break;
        }

        let formData = {};
        formData[params] = opt.requester;
        formData["table"] = table;
        formData["editCol"] = params;
        formData["edit"] = true;
        model["key"] = "locid";
        model["updateForm"] = formData;
        break;
      default:
        model = MeterFormModel;
        model.fields.push({
          name: "locid",
          type: "hidden",
          value: opt.requester,
        });
        break;
    }
    return model;
  };
  const getAssign = () => {
    let params = "locid";
    let table = "";
    switch (opt.caller) {
      case "meter":
        params = "meter_no";
        table = "meter_details";
        break;
      case "locid":
        if (opt.title === "Account") {
          params = "acc_no";
          table = "res_partner";
        } else {
          params = "meter_no";
          table = "meter_details";
        }
        break;
      default:
        params = "acc_no";
        table = "res_partner";
        break;
    }

    let srchConf =
      opt.title === "Account"
        ? [
            { name: "locid", type: "hidden", value: opt.requester },
            {
              value: "acc_no",
              name: "acc_no",
              type: "select_db_auto",
              sort: "name",
              fullWidth: true,
              table: "res_partner",
              field: "name",
              label: "Select Account",
              picks: [],
            },
          ]
        : opt.title === "Location"
        ? [
            { name: params, type: "hidden", value: opt.requester },
            {
              value: "locid",
              name: "locid",
              type: "select_db_auto",
              sort: "locid",
              fullWidth: true,
              table: "prepaid_location",
              field: "locid",
              label: "Location",
              picks: [],
            },
          ]
        : [
            { name: "locid", type: "hidden", value: opt.requester },
            {
              name: "meter_no",
              type: "select_db_auto",
              sort: "meter_no",
              fullWidth: true,
              table: "meter_details",
              value: "meter_no",
              field: "concat(meter_no)",
              //field: "concat(id,' - ', meter_no)",
              label: "Select Meter",
              picks: [],
            },
          ];

    AssignFormModel = {
      edit: true,
      editPram: { col: params },
      table: table,
      respText: "Update was successful!!!",
      fields: srchConf,
      desc: `Assign ${opt.title} to ${
        opt.caller ? opt.caller.toUpperCase() : ""
      }`,
      title: `${opt.title} Setup`,
    };

    return AssignFormModel;
  };

  const sendSubmit = () => {
    setRefresh(!refresh);
  };
  const addOrAssign = ($evt) => {
    switch (opt.caller) {
      case "meter":
        if (opt.title === "Account") {
          swal("Account can only be assigned to location!!!", {
            icon: "warning",
          });
          return;
        }

        break;
      case "locid":
        break;
      default:
        if (opt.title === "Meter") {
          swal("Meter can only be assigned to location!!!", {
            icon: "warning",
          });
          return;
        }
        break;
    }
    swal({
      title: "Add / Assign",
      text: "Choose your preffered option...",
      icon: "info",
      buttons: [
        `Add ${$evt.currentTarget.getAttribute("owner")}`,
        `Assign ${$evt.currentTarget.getAttribute("owner")}`,
      ],
      dangerMode: true,
    }).then((willAssign) => {
      if (willAssign) {
        setAssign(true);
      } else {
        setAdd(true);
      }
    });
  };

  const handleClose = ($evt) => {
    setMenu(null);
  };

  return (
    <Grid
      item
      xs={12}
      xl={4}
      component={Box}
      marginBottom="3rem"
      classes={{ root: classes.gridItemRoot + " " + classes.order2 }}
    >
      <Card
        classes={{
          root: classes.cardRoot + " " + classes.cardRootSecondary,
        }}
      >
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  {opt.title}
                </Box>
              </Grid>
              <Grid item xs="auto">
                {opt.title.toLowerCase() === "account" &&
                  Object.keys(line).length !== 0 &&
                  line["hasMeter"] &&
                  line["hasLocation"] && (
                    <Badge
                      badgeContent={line["Vending"]}
                      title="vend"
                      color={
                        line["Vending"].toLowerCase() === "vending enabled"
                          ? "success"
                          : "error"
                      }
                      style={{ borderRadius: "7px", marginLeft: -35 }}
                    ></Badge>
                  )}
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <CardContent>
          {Object.keys(line).length === 0 ? (
            <Grid item xs="auto">
              <Box
                justifyContent="center"
                display="flex"
                alignItems="center"
                // flexWrap="wrap"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={addOrAssign}
                  owner={opt.title}
                >
                  Add / Assign - {opt.title}
                </Button>
              </Box>
            </Grid>
          ) : (
            Object.entries(line).map((item_data) => {
              if (
                (opt.title.toLowerCase() === "account" &&
                  item_data[0].toLowerCase() === "vending") ||
                item_data[0] === "hasMeter" ||
                item_data[0] === "hasLocation"
              ) {
                return <></>;
              }

              return (
                <>
                  <Grid
                    container
                    component={Box}
                    alignItems="center"
                    justifyContent="space-between"
                    key={generateKey()}
                  >
                    <Grid item xs="auto">
                      <Box
                        component={Typography}
                        variant="h4"
                        marginBottom="0!important"
                      >
                        {item_data[0]}
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Box
                        justifyContent="flex-end"
                        display="flex"
                        flexWrap="wrap"
                      >
                        <Box
                          component={Typography}
                          variant="h4"
                          fontWeight="normal !important"
                          color="primary"
                          marginBottom="0!important"
                        >
                          {item_data[1]}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    component={Divider}
                    marginTop="1rem!important"
                    marginBottom="1rem!important"
                  ></Box>
                </>
              );
            })
          )}
        </CardContent>
        <Box
          padding="1rem 0"
          justifyContent="center"
          display="flex"
          className={classes.mtMd5}
        >
          <Box
            component={Divider}
            marginTop="1.5rem!important"
            marginBottom="1.5rem!important"
          ></Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize="1rem"
          >
            {Object.keys(line).length !== 0 && (
              <>
                {/* <a key={generateKey()}
                href="#mui"
                className={classes.cardProfileLink}
                onClick={handleClick}
                data-testid={`${opt.caller}-menu`}
              >
                menu...
              </a> */}
                <BasicMenu
                  options={opt.controls}
                  data-testid={`${opt.caller}-menu`}
                />
              </>
            )}
          </Box>
        </Box>
      </Card>
      <AutoForms
        key={0}
        keyId={1567}
        form={getAssign()}
        cancel={setAssign}
        open={assign}
        submit={sendSubmit}
      />
      <AutoForms
        key={generateKey()}
        keyId={1567}
        form={getAdd()}
        cancel={setAdd}
        open={add}
        submit={sendSubmit}
      />

      <Menu
        // key={generateKey()}
        id="simple-menu-6"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleClose}
      >
        <MenuItem disabled>Pick An Option.</MenuItem>
        {opt.controls !== undefined ? (
          opt.controls.map((mItem) => {
            return (
              <MenuItem
                key={generateKey()}
                dataname=""
                name={mItem.name}
                onClick={mItem.action}
              >
                {mItem.name}
              </MenuItem>
            );
          })
        ) : (
          <></>
        )}
      </Menu>
    </Grid>
  );
}

export default TrinityCard;
