import { roles } from "permissions";
import { getRoles } from "./../../utils/utils";
import { checkUserRoleAccess } from "utils/utils";

export const ControlComponent = ({ controlAccess, Component }) => {
  if (!controlAccess) {
    controlAccess = [roles.admin];
  }
  console.log("Control Component", controlAccess);
  const userRoles = getRoles();

  return (
    <>
      {
        (!controlAccess || checkUserRoleAccess({
          access: controlAccess,
          userRoles,
        })) ? <Component /> : <></>
      }
    </>
  );
}