import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  // eslint-disable-next-line unused-imports/no-unused-imports
  DialogContent,
} from "@material-ui/core";
import AccountTransactionTree from "./../views/admin/AccountTransactions";

export default function PopupFetchTable({ keyId, open, handleClose, title }) {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    // fetchData();
  }, [search]);

  // should render a popup form *
  // should fetch data for table
  // should render from gotten dataset
  // should be closable
  return (
    <>
      <Dialog key={keyId} open={open} onClose={handleClose}>
        {/* <DialogTitle>{title}</DialogTitle> */}
        <AccountTransactionTree />
        <DialogActions>
          <Button actionsend="cancel" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
