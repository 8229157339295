//CRUD Controller
import {
  CreateinDb,
  updateDb,
  MeterAction,
  GetTransactionHistory,
  GetWalletTransactionHistory,
  GetCustomerConsumption,
  GenerateReport,
  AssignMeter,
  httpRequest,
} from "./apis";
import { urls } from "./utils";

const Controller = async (data) => {
  let response = null;
  let requestBody;
  //alert(data.control);
  switch (data.control) {
    case "create":
      data.formData["table"] = data.table;
      await CreateinDb(data.formData)
        .then((resl) => {
          console.log(resl);
          response = resl;
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "edit":
      console.log("edit", data);
      data.formData["table"] = data.table;
      data.formData["editCol"] = data.editconf.col;
      data.formData["edit"] = true;
      await updateDb(data.formData, false)
        .then((resl) => {
          console.log(resl);
          response = resl;
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "meter_action":
      data.formData["table"] = data.table;
      data.formData["edit"] = false;
      await MeterAction(data.formData)
        .then((resl) => {
          console.log(resl);
          if (resl.error != undefined) {
            response = { ...resl, control: "meter_action", };
          }
          else {
            if (resl.status != undefined && !resl.status) {
              if (resl.message && resl.message.eMsgEn)
                response = { status: false, message: resl.message.eMsgEn, control: "meter_action" };
            }
            else {
              if (typeof resl.message === 'object') {
                response = { status: false, message: 'Something went wrong. Please verify Meter no is valid.', control: "meter_action" };
              }
              else {
                response = { ...resl, control: "meter_action" };
              }
            }
          }
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "render_card_action":
      await CardAction().then((response) => { });
      break;
    case "prepaid_transactions":
    case "prepaid_transaction_table":
      await GetTransactionHistory(data, data.opts)
        .then((_response) => {
          console.log("_response", _response);
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "wallet_transactions":
      await GetWalletTransactionHistory(data)
        .then((_response) => {
          response = { ..._response, control: "wallet_transactions" };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "render_consumption_card":
      await GetCustomerConsumption(data)
        .then((_response) => {
          response = { ..._response, control: "render_consumption_card" };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "account_payment":
      await GenerateReport("generate_bill", data)
        .then((_response) => {
          response = { ..._response, control: "generate_bill" };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "assign_meter":
      await AssignMeter(data.formData)
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "audit":
      await httpRequest({
        url: urls.auditReport({
          startDate: data.startDate,
          endDate: data.endDate,
          action: data.action,
          report: data.report,
        }),
        method: "get",
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "vendors":
      await httpRequest({
        url: urls.vendors,
        method: "get",
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "owner_vendors":
      await httpRequest({
        url: urls.vendorOwner,
        method: "post",
        data: data.data,
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "postpaid_adjustment":
      console.log(data, "data");
      await httpRequest({
        url: urls.reportAdjustment,
        method: "post",
        data: data.data,
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "internal_vendors_model":
      console.log(data, "data");
      let _url = urls.internalVendor;
      if (data?.data?.report) {
        _url += `?report=1`;
      }
      await httpRequest({
        url: _url,
        method: "get",
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "external_vendors_model":
      console.log(data, "data");
      await httpRequest({
        url: urls.externalVendor,
        method: "get",
      })
        .then((_response) => {
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "http_action":
      console.log(data, "data");
      requestBody = {
        url: data.url,
        method: data.method,
        baseUrl: data.baseUrl,
      };
      if (data.method.toUpperCase() === "POST") {
        requestBody.data = data.data;
      }

      await httpRequest(requestBody)
        .then((_response) => {
          response = { ..._response, control: data.control };
          console.log("_response", _response);
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "vendor_transaction_http_action":
      console.log(data, "data");
      requestBody = {
        url: data.url,
        method: data.method,
        baseUrl: data.baseUrl,
      };
      if (data.method.toUpperCase() === "POST") {
        requestBody.data = data.data;
      }

      await httpRequest(requestBody)
        .then(async (_response) => {
          if (_response && !_response.status) {
            if (!data.counter) {
              // retry request could be network  failure
              return await Controller({ ...data, ...{ counter: 1 } });
            }
          }
          response = { ..._response, control: data.control };
          console.log("_response", _response);
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;
    case "postpaid_transaction_table":
      data.opts = {...data.opts, ...{ type: 'postpaid' }};
      await GetTransactionHistory(data, data.opts)
        .then((_response) => {
          console.log("_response", _response);
          response = { ..._response, control: data.control };
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });
      break;

    default:
      let sendData = { create: data.formData, update: data.updatePayload };
      sendData.create["table"] = data.table;
      await updateDb(sendData, "special")
        .then((resl) => {
          console.log(resl);
          response = resl;
        })
        .catch((err) => {
          console.error(err);
          response = false;
        });

      break;
  }
  return response;
};
export default Controller;

function CardAction(obj) { }
