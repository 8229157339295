import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const RemoveMeterModel = {
  edit: true,
  editPram: { col: "locid" },
  respText: "Meter removal was successful!",
  table: "res_partner",
  fields: [
    {
      name: "locid",
      type: "hidden",
      value: "",
    },
    {
      name: "meter_no",
      type: "hidden",
      value: "",
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Remove Meter",
  title: "Remove Meter Setup",
};

export const RemoveMeterReadModel = [];

// const
export default RemoveMeterModel;
