import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { httpRequest } from "../../utils/apis";
import { showToast, urls } from "../../utils/utils";
import { Button, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

export default function VendorChangePassword() {
  const { requester } = useParams();

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  let checkboxRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!oldPassword || !password || !newPassword) {
      showToast({
        type: "error",
        message: `Please fill all fields.`,
      });
      return;
    }
    if (password != newPassword) {
      showToast({
        type: "error",
        message: `Passwords do not match, please confirm both new passwords is correct.`,
      });
      return;
    }

    const payload = {
      oldPassword,
      password,
      newPassword
    };
    payload.id = "";
    // console.log("payloaload", payload);
    showToast({ type: "success", message: 'Request submitted.' });

    httpRequest({
      url: urls.vendorChangePassword,
      method: "post",
      data: payload,
    }).then((result) => {
      if (result && result.status) {
        showToast({ type: "success", message: result.message });
      } else {
        showToast({
          type: "error",
          message: result.message || `An error occurred.`,
        });
      }
    });
  };

  useEffect(() => {
  }, []);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Change Password</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Old Password</label>
                      <input
                        type={"text"}
                        placeholder="Old Password"
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>New Password</label>
                      <input
                        type={"text"}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div class="fields card">
              <Grid>
                <Grid container lg={12} xs={12}>
                  <div class="input-field" style={{ width: "46%" }}>
                    <label>Confirm New Password</label>
                    <input
                      type={"text"}
                      placeholder="Confirm password"
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                  <div class="input-field" style={{ width: "66px" }}></div>
                </Grid>
              </Grid>
            </div>
            {/* </div> */}
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Submit
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}
