import React from "react";
import { useHistory, useParams } from "react-router-dom";

const RouteNotFound = (props) => {
  const history = useHistory();
  const { caller, requester } = useParams();
  console.log("caller, requester", caller, requester);
  return (
    <div>
      <button onClick={() => history.push(props?.url || "/receipt")} />
    </div>
  );
};

export default RouteNotFound;
