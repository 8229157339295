/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import "assets/css/report.css";
import { AddBoxRounded } from "@mui/icons-material";
import SelectDBAuto from "../selectDbAuto";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { generateKey, showToast, urls } from "../../utils/utils";
import Autocomplete from "@mui/material/Autocomplete";
import { Stack } from "@mui/material";
import { DeleteOutline } from "@material-ui/icons";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { httpRequest } from "../../utils/apis";
import swal from "sweetalert";
import BulkOutputPopup from "../BulkOutputPopup";

function AutoBulkAssign({
  recordObj,
  toastObj,
  title,
  newRecordButtonText,
  newSubmitButtonText,
  outputModalTitle,
  rowElements,
}) {
  const [output, setOutput] = useState(undefined);
  const [outputExcelBase64, setOutputExcelBase64] = useState(undefined);
  const [showOutput, setShowOutput] = useState(false);

  const createRecord = () => {
    return Object.assign({}, recordObj);
  };

  const [records, setRecords] = useState({ 0: createRecord() });
  // const [records, setRecords] = useState({
  //     "0": {
  //         "meter_no": "N90S2597518",
  //         "locid": "L00276673",
  //         "acc_no": "1232257955"
  //     },
  //     "1": {
  //         "meter_no": "3NP06166",
  //         "locid": "L00332891",
  //         "acc_no": "2347303032"
  //     },
  //     "2": {
  //         "meter_no": "45060434540",
  //         "locid": "L00232815",
  //         "acc_no": "8967098989"
  //     }
  // });

  const addNewRecord = (e) => {
    e.preventDefault();

    const _tmpRecords = records;
    _tmpRecords[Object.keys(records).length] = createRecord();
    setRecords({ ..._tmpRecords });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const payload = Object.values(records).filter((x) => x && x);
    console.log(payload);
    showToast({
      message: toastObj.pendingMessage || "Processing started",
      type: "success",
    });
    httpRequest({
      url: urls.assignMeter(true),
      data: payload,
      method: "post",
    }).then((response) => {
      console.log("response", response);
      if (response && response.status) {
        swal(
          response?.message ||
            toastObj.succesfulMessage ||
            "Action successuful.",
          {
            icon: "success",
            timer: 1000,
          }
        );
        setOutput(response.data);
        setOutputExcelBase64(response.base64);
        setShowOutput(true);
      } else {
        swal(
          response?.message || toastObj.errorMessage || "An error occurred.",
          {
            icon: "warning",
          }
        );
      }
    });
  };

  const onItemChange = (index, data) => {
    records[index] = { ...records[index], ...data };
    setRecords({ ...records });
  };

  const onItemRemove = (index) => {
    records[index] = null;
    setRecords({ ...records });
  };

  useEffect(() => {}, [records]);

  const recordKeys = Object.keys(records);
  return (
    <>
      <Header />

      <form action="#" className="form">
        <div class="form first" style={{ width: "100%" }}>
          <div class="container">
            <div class="details personal">
              <span class="title">{title || "Bulk Assign"}</span>
              <br />

              {recordKeys.length > 0 &&
                recordKeys.map((record, index) => {
                  if (!records[record]) return;
                  return (
                    <RenderRow
                      index={index}
                      onChange={onItemChange}
                      onRemove={onItemRemove}
                      elements={[...rowElements]}
                    />
                  );
                })}
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="outlined" onClick={addNewRecord}>
                {newRecordButtonText || "Add New Record"}
              </Button>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  {newSubmitButtonText || "Submit"}
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
      <BulkOutputPopup
        open={showOutput}
        output={output}
        handleClose={() => setShowOutput(!showOutput)}
        title={outputModalTitle || "Result"}
        base64={outputExcelBase64}
      />
    </>
  );
}

export default AutoBulkAssign;

const RenderRow = ({ index, col, onRemove, onChange, elements }) => {
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState(null);
  const [value, setValue] = React.useState({});

  const reLoadOptions = (evt) => {
    if (evt.target.value) setSearch(evt.target.value);
  };

  const onMeterChange = (e) => {
    e.preventDefault();
    const updateState = { ...value, ...{ meter_no: e.target.textContent } };
    setValue(updateState);
    onChange(index, updateState);
  };

  const onLocationChange = (e) => {
    e.preventDefault();
    const updateState = { ...value, ...{ locid: e.target.textContent } };
    setValue(updateState);
    onChange(index, updateState);
    // check if this location is assigned to a meter, is yes, then fetch
  };

  const onAccountChange = (e) => {
    e.preventDefault();
    const updateState = { ...value, ...{ acc_no: e.target.textContent } };
    setValue(updateState);
    onChange(index, updateState);
  };

  const handleChange = (e, key) => {
    e.preventDefault();
    const updateState = { ...value, ...{ [key]: e.target.textContent } };
    console.log("updateState", updateState);
    setValue(updateState);
    onChange(index, updateState);
  };

  return (
    <div class="fields card">
      <Grid>
        <Grid container lg={12} xs={12}>
          {/* {
                        elements && elements.map((element) => {
                            return (
                                <SelectDBAuto
                                    col={{
                                        name: "meter_no",
                                        type: "select_db_auto",
                                        sort: "meter_no",
                                        fullWidth: false,
                                        table: "meter_details",
                                        value: "meter_no",
                                        field: "concat(meter_no)",
                                        label: "Select Meter",
                                        picks: [],
                                        lg: 4
                                    }}
                                    indx={index * 5}
                                    //handleChange={e => handleChange(e, element.name)} 
                                    handleChange={onAccountChange}

                                    key={generateKey()} />
                            );
                        })
                    } */}
          <SelectDBAuto
            col={{
              name: "meter_no",
              type: "select_db_auto",
              sort: "meter_no",
              fullWidth: false,
              table: "meter_details",
              value: "meter_no",
              field: "concat(meter_no)",
              label: "Select Meter",
              picks: [],
              lg: 4,
            }}
            indx={index * 5}
            handleChange={onMeterChange}
          />
          <SelectDBAuto
            col={{
              name: "locid",
              type: "select_db_auto",
              sort: "locid",
              fullWidth: false,
              table: "prepaid_location",
              value: "locid",
              field: "locid",
              label: "Select Location",
              picks: [],
              lg: 4,
            }}
            indx={index * 5}
            handleChange={onLocationChange}
          />
          <SelectDBAuto
            col={{
              name: "acc_no",
              type: "select_db_auto",
              sort: "acc_no",
              fullWidth: false,
              table: "res_partner",
              value: "acc_no",
              field: "acc_no",
              label: "Select Account",
              picks: [],
              lg: 3,
            }}
            indx={index * 5}
            handleChange={onAccountChange}
          />
          <Grid item lg={1} xs={1}>
            <RemoveCircleOutlineIcon onClick={() => onRemove(index)} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
