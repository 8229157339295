import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const UpdateSealModel = {
  edit: true,
  editPram: { col: "locid" },
  respText: "Update was successful!",
  table: "res_partner",
  fields: [
    { name: "locid", type: "hidden", value: "" },
    {
      fullWidth: true,
      validate: { required: true },
      name: "seal",
      type: "text",
      label: "New Seal",
    },
    {
      name: "installedby",
      type: "text",
      value: uniqId,
      label: "Installed By",
      validate: { required: true },
      fullWidth: true,
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Update Seal Create/Edit",
  title: "Update Seal Setup",
};

export default UpdateSealModel;
