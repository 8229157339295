export const cardView = {};
let AccountReadModel = {
  fields: [
    {
      name: "acc_no",
      label: "Account No.",
      type: "selflink",
      link: "/admin/trinity/acc_no/",
      postpaidLink: "/admin/postpaid/acc_no/",
    },
    { name: "name", type: "text", label: "Name" },
    { name: "meter_no", type: "text", label: "Meter No." },
    {
      name: "metering_type",
      type: "text",
      label: "Billing Type",
    },
    {
      co_name: "customer_class",
      name: "customer_class.name#customer_class#id",
      type: "select_db",
      label: "Tariff",
    },
    // {
    //   co_name: "district",
    //   name: "district.name#res_district#id",
    //   type: "select_db",
    //   label: "District",
    // },
    {
      co_name: "service_id",
      name: "service_id.name#service_center#id",
      type: "select_db",
      label: "Service Center",
    },
    {
      co_name: "feeder_id",
      name: "feeder_id.name#feeder_feeder#id",
      type: "text",
      label: "Feeder",
    },
    {
      co_name: "transformer_id",
      name: "transformer_id.name#feeder_transformer#id",
      type: "text",
      label: "Transformer",
    },
  ],
  opts: {
    // sort: "desc",
    // order: "id",
    sort: "desc",
    order: "(case when bill_status2 is null then 0 when lower(bill_status2) = 'active' then 2 else 1 end)",
  },
};
export default AccountReadModel;
