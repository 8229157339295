/* eslint-disable unused-imports/no-unused-imports */
import { TableChart } from "@material-ui/icons";
import {
  smartCashAnalyticsDashboardUrls,
  yearStartAndEnd,
} from "../../utils/utils";

export const sumArrearsTransactionsByBillTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumArrearsTransactionsByBillType({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
      countField: "count",
    },
    title: "Arrears by Bill Type",
  }
);

export const sumArrearsTransactionsByChannelModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumArrearsTransactionsByChannel({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
      countField: "count",
    },
    title: "Arrears by Channel",
  }
);

export const sumArrearsTransactionsByPaymentMode = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumArrearsTransactionsByPaymentMode({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "payment_mode",
      valueField: "result",
      countField: "count",
    },
    title: "Arrears by Payment Mode",
  }
);

export const sumArrearsTransactionsByPurpose = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumArrearsTransactionsByPurpose({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "purpose",
      valueField: "result",
      countField: "count",
    },
    title: "Arrears by Purpsose",
  }
);

export const sumTransactionsByBillTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumTransactionsByBillType({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "bill_type",
      valueField: "result",
      countField: "count",
    },
    title: "Transactions by Bill Type",
  }
);

export const sumTransactionsByChannelModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumTransactionsByChannel({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "channel",
      valueField: "result",
      countField: "count",
    },
    title: "Transactions by Payment Channel",
  }
);

export const sumTransactionsByPaymentModeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumTransactionsByPaymentMode({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "payment_mode",
      valueField: "result",
      countField: "count",
    },
    title: "Transactions by Payment Mode",
  }
);

export const sumTransactionsByPurposeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumTransactionsByPurpose({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "purpose",
      valueField: "result",
      countField: "count",
    },
    title: "Transactions by Purpose",
  }
);

export const SumTransactionsModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Vendors transactions sum",
    placeholder: "0",
    field: "result",
    transform: "number",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumTransactions({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    title: "Sum of Transactions",
  }
);

export const sumArrearsTransactionsModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Vendors arrears transactions sum",
    placeholder: "0",
    field: "result",
    transform: "currency",
    request: {
      url: smartCashAnalyticsDashboardUrls.sumArrearsTransactions({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: smartCashAnalyticsDashboardUrls.baseURL,
    },
    title: "Sum of Arrears",
  }
);
