import { Button, Grid } from "@material-ui/core";
import { FormControl } from "@material-ui/core";

export const AutoformButton = ({ field, key, handleChange }) => {
  return (
    <Grid item xl={3} lg={6} xs={12}>
      <FormControl focused={true} fullWidth={field.fullWidth}>
        <>
          <Button
            key={key}
            type={"button"}
            name={field.name}
            value={field.value}
            onClick={(evt) => {
              console.log("handleChange", handleChange);
              handleChange && handleChange(evt, field.value);
            }}
          >
            {field.label}
          </Button>
        </>
      </FormControl>
    </Grid>
  );
};
