import * as React from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { EventTracker } from "@devexpress/dx-react-chart";
import { useState } from "react";
import { useEffect } from "react";
import Controller from "./../../utils/controls";
import { prepareValue } from "utils/utils";

const testData = [
  { year: "1950", population: 2.525 },
  { year: "1960", population: 3.018 },
  { year: "1970", population: 3.682 },
  { year: "1980", population: 4.44 },
  { year: "1990", population: 5.31 },
  { year: "2000", population: 6.127 },
  { year: "2010", population: 6.93 },
];

export const AutoBarVisualization = ({ form }) => {
  if (!form) form = {};
  const [targetItem, setTargetItem] = useState();
  const changeTargetItem = (targetItem) => setTargetItem(targetItem);
  const [data, setData] = useState(undefined);
  const [valueField, setValueField] = useState(undefined);
  const [totalCountField, setTotalCountField] = useState(undefined);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    Controller({
      control: form?.control,
      // limit: settings.limit,
      url: form?.request?.url,
      data: form?.fields,
      method: form?.request?.method,
      baseUrl: form?.request?.baseUrl,
    }).then((response) => {
      if (response && response.status) {
        const _value = response.data.map(element => {
            if (form && form.fields && !element[form.fields.argumentField]) {
                element[form.fields.argumentField] = 'n/a';
            }
            
            return element;
        });
        setData(_value);

        let valueTotal = 0;
        let countTotal = 0;

        response.data.map((item) => {
          valueTotal += item[form.fields.valueField];
          if (form?.fields?.countField) {
            countTotal += item[form.fields.countField];
          }
        });
        if (valueTotal > 0) setValueField(valueTotal);
        if (countTotal > 0) setTotalCountField(countTotal);
      } else {
        console.log("error ", response.message);
      }
    });
  };

  return (
    <div style={{ marginBottom: 30 }}>
      <Paper style={{ width: form?.style?.width || 250 }}>
        <div
          style={{
            fontSize: 13,
            marginBottom: 5,
            marginLeft: 5,
            paddingTop: 5,
          }}
        >
          {(form && form.title) || ""}{" "}
        </div>
        <Chart
          data={data || testData}
          height={form?.style?.height || 250}
          width={form?.style?.width || 250}
        >
          <ArgumentAxis />
          <ValueAxis />

          <BarSeries
            valueField={form?.fields?.valueField }
            argumentField={form?.fields?.argumentField}
          />
          {/* <Title
                
                    text={form?.title || "World population (billion)"}
                /> */}
          <EventTracker />
          <Tooltip
            targetItem={targetItem}
            onTargetItemChange={changeTargetItem}
          />
        </Chart>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {(valueField && form?.fields?.showValueField) && (
            <div style={{ fontSize: 13 }}>
              Total Amount of NGN {prepareValue("currency", valueField)}
            </div>
          )}

          {(totalCountField && form?.fields?.countField) && (
            <div style={{ fontSize: 13 }}>
              Total Count {prepareValue("number", totalCountField)}
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};
