/* eslint-disable unused-imports/no-unused-imports */
import { UniqueCode } from "utils/events";
import DownloadDoneOutlined from "@mui/icons-material/DownloadDoneOutlined";
import { DownloadRounded } from "@mui/icons-material";
import { urls } from "./../utils/utils";
import { baseHost } from "./../utils/apis";

const uniqId = `L${UniqueCode(1000000)}`;
export const PrepaidReportModel = {
  control: "http_action",
  request: {
    url: urls.prepaidReport,
    method: "get",
    baseUrl: baseHost + "/",
  },
  fields: [
    {
      field_name: "account_number",
      name: "account_number",
      label: "Acc No.",
    },
    {
      field_name: "amount",
      name: "amount",
      label: "Amount.",
    },
    {
      field_name: "payment_mode",
      name: "payment_mode",
      label: "Payment Mode.",
    },
    {
      field_name: "vendor",
      name: "vendor",
      label: "Vendor.",
    },
    {
      field_name: "channel",
      name: "channel",
      label: "Channel.",
    },
    {
      field_name: "transaction_ref",
      name: "transaction_ref",
      label: "Ref.",
    },
    {
      field_name: "token",
      name: "token",
      label: "Token.",
    },
    {
      field_name: "arrears",
      name: "arrears",
      label: "Arrears.",
    },
    {
      field_name: "arrears",
      name: "arrears",
      label: "Arrears.",
    },
    {
      field_name: "purpose",
      name: "purpose",
      label: "Purpose.",
    },
    {
      field_name: "trans_date",
      name: "trans_date",
      label: "Trans Date.",
      transform: "datetime",
    },
  ],
  fabs: {
    downloadReport: {
      icon: <DownloadRounded />,
      onClick: () => {},
    },
  },
};
