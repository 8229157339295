/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import "assets/css/report.css";
import AutoBulkAssign from "./AutoBulkAssign";

export default function AssignMeters() {
  return (
    <AutoBulkAssign
      newRecordButtonText={"New Meter Record"}
      newSubmitButtonText={"Process Meters"}
      outputModalTitle={"Result"}
      recordObj={{ meter_no: "", locid: "", acc_no: "" }}
      title={"Bulk Meter Assign Hello World"}
      toastObj={{
        pendingMessage: "Meters upload started...",
        succesfulMessage: "Meters uploaded.",
        errorMessage: "Something went wrong...",
      }}
      rowElements={[
        {
          fields: {
            name: "meter_no",
            type: "select_db_auto",
            sort: "meter_no",
            fullWidth: false,
            table: "meter_details",
            value: "meter_no",
            field: "concat(meter_no)",
            label: "Select Meter",
            picks: [],
            lg: 4,
          },
          name: "meter_no",
        },
        {
          fields: {
            name: "locid",
            type: "select_db_auto",
            sort: "locid",
            fullWidth: false,
            table: "prepaid_location",
            value: "locid",
            field: "locid",
            label: "Select Location",
            picks: [],
            lg: 4,
          },
          name: "locid",
        },
        {
          fields: {
            name: "acc_no",
            type: "select_db_auto",
            sort: "acc_no",
            fullWidth: false,
            table: "res_partner",
            value: "acc_no",
            field: "acc_no",
            label: "Select Account",
            picks: [],
            lg: 3,
          },
          name: "acc_no",
        },
      ]}
    />
  );
}
