import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import componentStyles from "assets/theme/views/admin/tables.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

const CustomerTableHead = () => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        ></TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Name
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Juice Acc No
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Loc-ID
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Meter No.
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Acc No.
        </TableCell>
        {/* <TableCell
        classes={{
          root:
            classes.tableCellRoot + " " + classes.tableCellRootHead,
        }}
      >
        Address
      </TableCell> */}
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Phone
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          State
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          District
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        >
          Customization Readiness
        </TableCell>
        <TableCell
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CustomerTableHead;
