// view vendors
// view subvendors
// view cashiers
// view agents
// view vendor's subvendors
// view subvendor's cashiers
// view subvendor's agent

import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
// import { VendorsModel } from "../../model/vendors/vendors_model";
import React from "react";
import { useHistory } from "react-router-dom";
import { ListLocalVendorsModel } from "../../model/vendors/list_local_vendors_model";

export default function ListLocalVendors() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Local Vendors",
          table: ListLocalVendorsModel.table,
          control: ListLocalVendorsModel.control,
        }}
        columns={ListLocalVendorsModel.fields}
        records={[]}
        form={ListLocalVendorsModel}
      />
    </>
  );
}
