/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import "assets/css/bulk.css";
import AutoBulkUpload from "./AutoBulkUpload";
import {
  BulkFreeIssueModel,
  BulkKeyChangeModel,
  BulkModel,
} from "../../model/meter/bulk_processes";
import Header from "components/Headers/Header";
import { generateKey } from "utils/utils";

function BulkFreeIssue() {
  return (
    <>
      <Header />
      <AutoBulkUpload
        key={generateKey()}
        //form={BulkKeyChangeModel}
        form={BulkModel({ uploadType: "bulk_freeissue" })}
      />
    </>
  );
}
export default BulkFreeIssue;
