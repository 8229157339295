import React from "react";
import "assets/plugins/nucleo/css/style.css";
import "assets/plugins/nucleo/css/button.css";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { useLocation, useParams } from "react-router-dom";
import { getLabel } from "./dialog";
import { GetCustomerDetails } from "./../../utils/apis";
import { prepareValue } from "./../../utils/utils";

const Invoice = (props) => {
  const [accountName, setAccountName] = React.useState(props.accountName || "");
  const [address, setAddress] = React.useState(props.address || "");
  const [accountNo, setAccountNo] = React.useState(props.accountNo || "");
  const [amount, setAmount] = React.useState(props.amount || "");
  const [meterNo, setMeterNo] = React.useState(props.meterNo || "");
  const [invoiceType, setInvoiceType] = React.useState(props.invoiceType || "");
  const [invoiceToken, setInvoiceToken] = React.useState(
    props.invoiceToken || ""
  );
  const [rate, setRate] = React.useState(props.rate || "");
  const [units, setUnits] = React.useState(props.units || "");
  const [transactionVAT, settransactionVAT] = React.useState(props.vat);
  const [vat, setVat] = React.useState(props.vat || 7.5);
  const [invoiceId, setInvoiceId] = React.useState(props.invoiceId || "");
  const [subTotal, setSubTotal] = React.useState(props.subTotal || "");
  const [invoiceTypeLabel, setInvoiceTypeLabel] = React.useState(
    props.invoiceTypeLabel || ""
  );
  const [ststi, setStsti] = React.useState(props.ststi || "");
  const [phases, setPhases] = React.useState(props.phases || "");
  const [sgc, setSGC] = React.useState(props.sgc || "");
  const [createDate, setCreateDate] = React.useState(new Date());
  const [tarrifName, setTarrifName] = React.useState(props.tariff || "");
  const [arrears, setArrears] = React.useState(props.arrears || "");
  const [meteringType, setMeteringType] = React.useState(props.meteringType || "prepaid");

  //<p> {sgc}/{ststi}/{phases}</p>
  const location = useLocation();
  const { caller, requester } = useParams();
  console.log('receipt props', props);

  let title = `${invoiceType ? invoiceType : "token"}${moment(
    new Date()
  ).utc().add(1, 'hour').format("yyyyMMDD_hhmmss")}.pdf`;

  const handlePrint = () => {
    //window.print();
    print();
  };

  async function print() {
    const result = await processPDF();
    result.autoPrint();
    result.save(title);
    //result.output('dataurlnewwindow',{filename: title});
    //result.output('bloburl', {filename: title});
    //window.open(result.output('bloburl', { filename: title }));
  }

  const generatePDF = async () => {
    const result = await processPDF();
    result.save(title);
  };

  const processPDF = async () => {
    const domElement = document.getElementById("receipt-div");
    const width = domElement.offsetWidth;
    const height = domElement.offsetHeight;
    return await html2canvas(domElement, {
      onclone: (document) => {
        document.getElementById("generate-pdf").style.visibility = "hidden";
        document.getElementById("handle-print").style.visibility = "hidden";
      },
    }).then((canvas) => {
      // const widthInMM = canvas.width * 0.264583;
      //const heightInMM = canvas.height * 0.264583;

      const widthInMM = 100;
      const heightInMM = canvas.height * 0.264583;

      const imgData = canvas.toDataURL("image/png");
      //const doc = new jsPdf("p", "mm", "a4");
      const doc = new jsPdf("p", "mm", [widthInMM, heightInMM]);
      doc.setProperties({
        title: title,
      });
      //delete the first page
      doc.deletePage(1);
      //recreate a new page with the new measurements
      doc.addPage([widthInMM, heightInMM], "p");
      // add image to the page
      doc.addImage(imgData, "PNG", 0, 0, widthInMM, heightInMM);
      return doc;
    });
  };
  const buttons = {
    backgroundColor: "#4CAF50" /* Green */,
    border: "none",
    padding: "15px 32px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "16px",
    margin: "4px 2px",
    cursor: "pointer",
    color: "#fff",
    fontWeight: "bold",
    webkitTransitionDuration: "0.4s" /* Safari */,
    transitionDuration: "0.4s",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
  };

  const handleInvoiceToken = () => {
    if (props.invoiceToken) setInvoiceToken(props.invoiceToken);
    // setInvoiceToken
  }

  const prepareInvoiceLabelType = (invoiceTypeLabel) => {
    if (invoiceTypeLabel) {
      const _label = getLabel(invoiceTypeLabel);
      if (_label) setInvoiceTypeLabel(_label);
      else setInvoiceTypeLabel(invoiceTypeLabel);
    }
  }

  const loadReceiptData = async () => {
    console.log("location.state", location?.state, props, props.ststi);
    prepareInvoiceLabelType(props.invoiceTypeLabel);
    if (props.invoiceToken) setInvoiceToken(props.invoiceToken);
    if (props.ststi) setStsti(props.ststi);

    if (location.state) {
      // load data in
      const { state } = location;

      // if (!state.created) {
      //   return;
      // }

      const created = state.created;

      if (state.transaction) setInvoiceId(state.transaction);
      if (state.amount) setAmount(state.amount);
      if (state.units) setUnits(state.units);
      if (state.ststoken) setInvoiceToken(state.ststoken);
      if (state.serialnumber) setMeterNo(state.serialnumber);
      if (created) setCreateDate(created);
      if (state.arrears) setArrears(state.arrears);
      if (state.meteringType !== undefined) setMeteringType(state.meteringType);
      if (state.vat) settransactionVAT(state.vat);
      if (state.token_type) {
        prepareInvoiceLabelType(state.token_type);
      }
      console.log("state.meteringType", state.meteringType, state.token_type, getLabel(state.token_type));


      if (invoiceType) title = `${invoiceType ? invoiceType : "token"}${prepareValue('date', created)}.pdf`;

      if (!state.caller && state.serialnumber) {
        state.caller = "meter_no";
        state.requester = state.serialnumber;
      }

      //get customer info
      await getCustomerData(state.caller, state.requester);

      switch (state.action) {
        case "download_pdf":
          break;
        case "print_pdf":
          break;
        default:
          break;
      }
    }
  };


  React.useEffect(() => {

    loadReceiptData();
    prepareData();
  }, [amount]);

  const prepareData = () => {

    if (transactionVAT) {
      const _amount = parseFloat(amount) - parseFloat(transactionVAT); // 125 - 8.71 = X
      console.log('amount', _amount, amount, arrears, vat, transactionVAT);
      let _vat;
      //cal total price 
      let subTotalTmpAmount = _amount;
      if (subTotalTmpAmount < 0) {
        subTotalTmpAmount = subTotalTmpAmount * -1;
      }

      _vat = transactionVAT;

      console.log('subTotalTmpAmount + arrears', (subTotalTmpAmount + arrears));

      if (arrears) {
        setSubTotal(subTotalTmpAmount ? (subTotalTmpAmount + arrears) : "");

      } else {
        setSubTotal(subTotalTmpAmount ? (subTotalTmpAmount) : "");
      }
      // setSubTotal(subTotalTmpAmount ? subTotalTmpAmount - _vat : "");

    } else {
      const _amount = parseFloat(amount) + parseFloat(arrears);
      let _vat;
      //cal total price 
      let subTotalTmpAmount = _amount;
      if (subTotalTmpAmount < 0) {
        subTotalTmpAmount = subTotalTmpAmount * -1;
      }

      _vat = vat;
      console.log('subTotalTmpAmount', subTotalTmpAmount, subTotalTmpAmount - ((_vat / 100) * subTotalTmpAmount));
      setSubTotal(subTotalTmpAmount ? subTotalTmpAmount - ((_vat / 100) * subTotalTmpAmount) : "");
    }



  };

  const getCustomerData = (_caller, _requester) => {
    if (!_caller || !_requester) {

      if (caller && requester) {
        _caller = caller;
        _requester = requester;
      }
    }
    console.log('_caller', _caller, '_requester', _requester);
    GetCustomerDetails(_caller, _requester).then((response) => {
      console.log('response data', response);
      if (response && response?.data) {
        const _data = response.data;
        if (_data.length < 1) {
          return;
        }
        setAccountNo(_data[0].acc_no);
        setAccountName(_data[0].name);
        setAddress(_data[0].street);
        setRate(_data[0].tarrif_rate);
        setTarrifName(_data[0].tarrif_name);
        setPhases(_data[0].phases);
        setMeterNo(_data[0].meter_no);
        setSGC(_data[0].sgc);
        if (_data[0].sts_ti) setStsti(_data[0].sts_ti);
      }
    });
  };

  console.log('receipt invoiceToken ?  invoiceToken : "-"', (invoiceToken ? invoiceToken : "-"), invoiceToken);

  return (
    <>
      <br />
      <br />
      <br />
      <div class="wrapper" id="receipt-div">
        <div class="invoice_wrapper">
          <div class="header">
            <br />
            <div class="logo_invoice_wrap">
              <div class="logo_sec">
                <img
                  src={
                    require(`./../../assets/img/brand/${process.env.REACT_APP_APP_LOGO
                      ? process.env.REACT_APP_APP_LOGO
                      : "argon-react.png"
                      }`).default
                  }
                  alt=""
                  style={{ height: "70px", width: "85px" }}
                  className="img-fluid font"
                />
                <div class="title_wrap">
                  <p class="title bold">{process.env.REACT_APP_TITLE || "Smart Power"}</p>
                  <p class="sub_title">{process.env.REACT_APP_PHONE_NO || ""}</p>
                </div>
              </div>
              <div class="invoice_sec" style={{ whiteSpace: 'pre-wrap' }}>
                <p class="invoice bold">Receipt</p>
                <p class="invoice_no"  >
                  <span>
                    {/* {" "} */}
                    {invoiceId && (<> Invoice No. </>)}
                  </span>
                  <span style={{ wordWrap: 'break-word', fontWeight: 'bold' }}>
                    {invoiceId && (
                      <> <span>{invoiceId || "XXXX"}</span> </>
                    )}
                  </span>
                </p>
                <p class="date">
                  <span>
                    <div class="bold">Date</div>
                    <div>
                      {prepareValue('date', createDate) ||
                        ""}
                    </div>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="body">
            <div class="main_table">
              <div class="table_header">
                <div class="row">
                  <div class="col col_no"></div>
                  <div class="col col_des">DESCRIPTION</div>
                  <div class="col col_price"></div>
                  <div class="col col_total"></div>
                  <div class="col col_total"></div>
                </div>
              </div>
              <div class="table_body">
                <div class="row">
                  <div class="col col_no">
                    <p>01</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Account Name:</p>
                  </div>
                  <div class="col col_price">
                    <p> {accountName}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col col_no">
                    <p>02</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Account No :</p>
                  </div>
                  <div class="col col_price">
                    <p> {accountNo}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col col_no">
                    <p>03</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Address :</p>
                  </div>
                  <div class="col col_price">
                    <p> {address}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col col_no">
                    <p>04</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Tarrif :</p>
                  </div>
                  <div class="col col_price">
                    <p> {rate}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col col_no">
                    <p>05</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Tariff Name</p>
                  </div>
                  <div class="col col_price">
                    <p> {tarrifName}</p>
                    <p>
                      {meteringType === "prepaid" && <>{" "}{sgc}/{ststi}/{phases}</>}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col col_no">
                    <p>06</p>
                  </div>
                  <div class="col col_des">
                    <p class="bold">Meter No:</p>
                  </div>

                  <div class="col col_total">
                    <p> {meterNo ? meterNo.toString() : ""}</p>
                  </div>
                </div>
                {
                  meteringType === 'prepaid' &&
                    units ?
                    <>
                      <div class="row">
                        <div class="col col_no">
                          <p>07</p>
                        </div>
                        <div class="col col_des">
                          <p class="bold">
                            {" "}
                            {`${invoiceTypeLabel ? invoiceTypeLabel : "-"} :`}{" "}
                          </p>
                        </div>
                        <div class="col col_price">
                          <p> {invoiceToken ? invoiceToken : "-"}</p>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div class="row">
                        <div class="col col_no">
                          <p>07</p>
                        </div>
                        <div class="col col_des">
                          <p class="bold">
                            {" "}
                            {`Type :`}{" "}
                          </p>
                        </div>
                        <div class="col col_price">
                          <p> {invoiceTypeLabel ? invoiceTypeLabel : "-"}</p>
                        </div>
                      </div>
                    </>
                }
                {(amount != 0 || (meteringType === "prepaid" && units != 0)) && (
                  <>
                    <div class="row">
                      <div class="col col_no">
                        <p>
                          {
                            meteringType === "prepaid" && prepareValue('zeropad', 8)
                          }
                          {
                            meteringType === "postpaid" && prepareValue('zeropad', 8 - 1)
                          }
                        </p>
                      </div>
                      <div class="col col_des">
                        <p class="bold">
                          {meteringType == "prepaid" && <>Units</>}
                          {meteringType == "postpaid" && <>Amount</>}
                        </p>
                      </div>
                      <div class="col col_price">
                        <p>
                          {meteringType === "prepaid" && units ?
                            <>
                              {prepareValue('currency', units) || ""} KWh @ {rate || ""}</>
                            :
                            <>
                              0 KWh
                            </>
                          }
                          {meteringType === "postpaid" && <>{'₦' + prepareValue('currency', amount) || ""}</>}
                        </p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col col_no">
                        <p>
                          {
                            meteringType === "prepaid" && prepareValue('zeropad', 9)
                          }
                          {
                            meteringType === "postpaid" && prepareValue('zeropad', 9 - 1)
                          }
                        </p>
                      </div>
                      <div class="col col_des">
                        <p class="bold"> VAT </p>
                      </div>
                      <div class="col col_price">
                        {
                          transactionVAT
                            ?
                            <p>₦{prepareValue('currency', transactionVAT)}</p>
                            :
                            (arrears > 0
                              ?
                              <p>₦{prepareValue('currency', (vat / 100) * (parseFloat(amount) + parseFloat(arrears))) || ""}</p>
                              :
                              <p>₦{prepareValue('currency', (vat / 100) * amount) || ""}</p>)
                        }

                      </div>
                    </div>

                    <div class="row">
                      <div class="col col_no">
                        <p>
                          {
                            meteringType === "prepaid" && prepareValue('zeropad', 10)
                          }
                          {
                            meteringType === "postpaid" && prepareValue('zeropad', 10 - 1)
                          }
                        </p>
                      </div>
                      <div class="col col_des">
                        <p class="bold"> ARREARS </p>
                      </div>
                      <div class="col col_price">
                        <p>₦{prepareValue('currency', arrears) || "0"}</p>
                      </div>
                    </div>
                    {
                      meteringType === "prepaid" &&
                      <div class="row">
                        <div class="col col_no">
                          <p>
                            {
                              meteringType === "prepaid" && prepareValue('zeropad', 11)
                            }
                            {
                              meteringType === "postpaid" && prepareValue('zeropad', 11 - 1)
                            }
                          </p>
                        </div>
                        {subTotal && (
                          <>
                            <div class="col col_des">
                              <p class="bold"> Sub total </p>
                            </div>
                            <div class="col col_price">
                              <p>₦{(prepareValue('currency', subTotal)) || ""}</p>
                              {/* {
                                arrears > 0
                                  ?
                                  // <p>₦{(amount - arrears) || ""}</p>
                                  <p>₦{(subTotal) || ""}</p>
                                  // <p>₦{(arrears > amount ? (parseFloat(amount) + parseFloat(arrears)) : eval(parseFloat(amount) - parseFloat(arrears))) || ""}</p>
                                  :
                                  <p>₦{(subTotal) || ""}</p>
                              } */}

                            </div>
                          </>
                        )}
                      </div>
                    }

                    <div class="row">
                      <div class="col col_no">
                        <p>
                          {
                            meteringType === "prepaid" && prepareValue('zeropad', 12)
                          }
                          {
                            meteringType === "postpaid" && prepareValue('zeropad', 11 - 1)
                          }
                        </p>
                      </div>
                      <div class="col col_des">
                        <p class="bold"> Total </p>
                      </div>
                      <div class="col col_price">
                        {
                          meteringType === "prepaid" ? (arrears > 0 ? <p>₦{prepareValue('currency', (parseFloat(amount) + parseFloat(arrears))) || ""} </p> : <p>₦{prepareValue('currency', (amount)) || ""}</p>) : null
                        }
                        {
                          meteringType === "postpaid" && (<p>₦{prepareValue('currency', (amount)) || ""}</p>)
                        }
                        

                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <br />
            <div class="paymethod_grandtotal_wrap">
              <div class="paymethod_sec">
                <span
                  className="pr-2"
                  style={{ color: "blue", fontWeight: "bold" }}
                >
                  <button
                    id="handle-print"
                    data-testid="test-handle-print"
                    class="custom-btn btn-12"
                    onClick={handlePrint}
                  >
                    <span>Click!</span>
                    <span>
                      <i className="fas fa-print"></i> print
                    </span>
                  </button>
                </span>

                <span className="pr-2" style={{ marginLeft: "70px" }}>
                  <button
                    id="generate-pdf"
                    data-testid="test-generate-pdf"
                    class="custom-btn btn-12"
                    onClick={generatePDF}
                  >
                    <span>Click!</span>
                    <span>
                      <i className="fas fa-download"></i> download
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
