/* eslint-disable unused-imports/no-unused-imports */
// core components
// import Login from "views/auth/Login.js";
// import Customers from "views/admin/Customers";

import LocationTree from "./views/admin/Location";
import TariffTree from "views/admin/Tariff";
import FlashOn from "@material-ui/icons/FlashOn";
import { GroupAdd, TitleOutlined } from "@material-ui/icons";
import {
  // SettingsApplications,
  Map,
} from "@material-ui/icons";
import { VerifiedUser } from "@material-ui/icons";
import TrinityItem from "components/TrinityItem";
import AccountTree, { PostpaidAccountTree } from "./views/admin/Accounts";
import { Book } from "@material-ui/icons";
// import Profile from "views/admin/Profile"
import { MonetizationOn } from "@material-ui/icons";
import Grain from "@material-ui/icons/Grain";
import MeterTree from "views/admin/Meter";
import TransactionTree, {
  PostpaidTransactionTree,
} from "./views/admin/Transactions";
import receipt from "./components/receipt/receipt";
import AccountTransactionTree from "./views/admin/AccountTransactions";
import PostpaidItem from "./components/Postpaid/PostpaidItem";
import PostpaidReportForm from "./components/Postpaid/PostpaidReportForm";
//import BulkKeyChange from "./components/Bulk/bulk_change";
import BulkMeter from "components/Bulk/bulk_meter";
import BulkFreeIssue from "components/Bulk/bulk_freeissue";
import BulkMeterAssign from "./components/Bulk/BulkMeterAssign";
import { LogoutOutlined } from "@mui/icons-material";
import AuditRecord from "./views/admin/AuditRecords";
import { roles } from "./permissions";
import { logOut, pageSwitcher } from "./utils/utils";
import SupportTicketForm from "./components/SupportTicket/SupportTicketForm";
import VendorsTree from "./components/Vendors/FetchVendor";
import CreateVendorForm from "./components/Vendors/NewVendor";
import FetchOwnerVendors from "./components/Vendors/FetchOwnerVendors";
import { AdjustmentReversal } from "./components/AdjustmentReversal/Adjustments";
import { PrepaidReversals } from "components/AdjustmentReversal/Reversals";
import ExternalVendors from "./components/Vendors/ExternalVendors";
import InternalVendors from "./components/Vendors/InternalVendors";
import PrepaidReportTable from "./components/PrepaidReport";
import FundVendorForm from "./components/Vendors/FundVendorForm";
import WithdrawVendorForm from "./components/Vendors/WithdrawVendorForm";
import PendingVendors from "components/Vendors/PendingVendors";
import ListLocalVendors from "components/Vendors/ListLocalVendors";
import AutoVisualization from "./components/AutoVisualization";
import TransactionDashboard from "./views/admin/Dashboard/TransactionDashboard";
import ArrearsDashboard from "views/admin/Dashboard/ArrearsDashboard";
import CustomersDashboard from "./views/admin/Dashboard/CustomersDashboard";
import MetersDashboard from "./views/admin/Dashboard/MetersDashboard";
import VendorsTransactions from "./components/Vendors/VendorsTransactions";
import BulkChange from "./components/Bulk/bulk_change";
import VendorsDashboard from "views/admin/Dashboard/VendorsDashboard";
import PrepaidReportForm from "./components/PrepaidReport/ReportForm";
import VendorChangePassword from "components/Vendors/ChangePassword";
import VendorTransactionReportForm from "components/Vendors/ReportForm";
import ArrearsVend from "components/ArrearsVend/ArrearsVend";
import MeterRequest from "./components/MeterRequest/MeterRequest";
import SingleMeterRequest from "./components/MeterRequest/SingleMeterRequest";

// import VpnKey from "@material-ui/icons/VpnKey";

/** 
 * action routes are not rendered
dividerHeader is route header
uniqueKey is used to identify the route
parentKey is used on children routes to identify the parent route
icon is used to render the icon
label is used to render set route label **/

// roles access
const baseAccess = [roles.admin];

const routes = [
  {
    href: "#pablo",
    name: "©  APLE-SMB",
    icon: FlashOn,
    upgradeToPro: true,
  },
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Prepaid",
    icon: TitleOutlined,
    // the child determines the access of the parent
    uniqueKey: "prepaid",
    parentKey: null,
  },
  {
    path: "/index",
    name: "Prepaid Customers",
    icon: GroupAdd,
    iconColor: "Primary",
    component: AccountTree,
    layout: "/admin",
    access: [...baseAccess, roles.customerCare, roles.headbilling, roles.accountant, roles.headacct],
    parentKey: "prepaid",
    uniqueKey: null,
  },
  {
    path: "/trinity/:caller/:requester",
    name: "Trinity",
    action: true,
    icon: Grain,
    iconColor: "Primary",
    component: TrinityItem,
    layout: "/admin",
  },
  // {
  //   path: "/prepaid-reversals",
  //   name: "Reversals",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: PrepaidReversals,
  //   layout: "/admin",
  //   access: [...baseAccess],
  //   parentKey: 'prepaid',
  //   uniqueKey: null,
  // },
  {
    path: "/prepaid-transactions",
    name: "Prepaid Transactions",
    icon: MonetizationOn,
    iconColor: "Primary",
    component: TransactionTree,
    layout: "/admin",
    access: [...baseAccess, roles.accountant, roles.auditors, roles.headbilling, roles.headacct, roles.prepaidOfficer, roles.ICT],
    parentKey: "prepaid",
    uniqueKey: null,
  },
  {
    path: "/receipts",
    name: "Receipts",
    component: receipt,
  },
  {
    path: "/postpaid/:caller/:requester",
    name: "Postpaid",
    action: true,
    icon: Grain,
    iconColor: "Primary",
    component: PostpaidItem,
    layout: "/admin",
  },
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Postpaid",
    icon: TitleOutlined,
    uniqueKey: "postpaid",
    parentKey: null,
  },
  {
    path: "/postpaid",
    name: "Postpaid Customers",
    icon: GroupAdd,
    iconColor: "Primary",
    component: PostpaidAccountTree,
    layout: "/admin",
    access: [...baseAccess, roles.customerCare, roles.headbilling, roles.accountant,roles.headacct, roles.billingManager, roles.ICT],
    parentKey: "postpaid",
    uniqueKey: null,
  },
  {
    path: "/postpaid-transactions",
    name: "Postpaid Transactions",
    icon: MonetizationOn,
    iconColor: "Primary",
    component: PostpaidTransactionTree,
    layout: "/admin",
    access: [...baseAccess, roles.accountant, roles.auditors, roles.headbilling, roles.billingManager, roles.ICT],
    parentKey: "postpaid",
    uniqueKey: null,
  },
  {
    path: "/adjustment",
    name: "Adjustments",
    icon: Book,
    iconColor: "Primary",
    component: AdjustmentReversal,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.headbilling],
    parentKey: "postpaid",
    uniqueKey: null,
  },
  // engineering
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Engineering",
    icon: TitleOutlined,
    parentKey: null,
    uniqueKey: "metering",
  },
  {
    path: "/bulk-meter-assign",
    name: "Bulk Assign",
    icon: Book,
    iconColor: "Primary",
    component: BulkMeterAssign,
    layout: "/admin",
    access: [...baseAccess, roles.metering],
    parentKey: "metering",
    uniqueKey: null,
  },
  // {
  //   path: "/bulk-meter-upload",
  //   name: "Bulk Meter Upload",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: BulkMeter,
  //   layout: "/admin",
  //   access: [...baseAccess, roles.metering],
  //   parentKey: "metering",
  //   uniqueKey: null,
  // },
  {
    path: "/bulk-change",
    name: "Bulk Engineering",
    icon: Book,
    iconColor: "Primary",
    component: BulkChange,
    layout: "/admin",
    access: [...baseAccess, roles.metering],
    parentKey: "metering",
    uniqueKey: null,
  },
  // {
  //   path: "/bulk-free-issue",
  //   name: "Bulk Free Issue",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: BulkFreeIssue,
  //   layout: "/admin",
  //   access: [...baseAccess, roles.metering],
  //   parentKey: "metering",
  //   uniqueKey: null,
  // },
  // vendors section
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Vendors",
    icon: TitleOutlined,
    uniqueKey: "vendors",
    parentKey: null,
  },
  // {
  //   name: "Vendors",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: VendorsTree,
  //   layout: "/admin",
  //   path: "/vendors",
  //   access: [...baseAccess, roles.headacct],
  //   parentKey: "vendors",
  //   uniqueKey: null,
  // },
  {
    name: "Vendors",
    icon: Book,
    iconColor: "Primary",
    component: InternalVendors,
    layout: "/admin",
    path: "/internal-vendors",
    access: [...baseAccess, roles.headacct],
    parentKey: "vendors",
    uniqueKey: null,
  },
  {
    name: "Create Vendor",
    icon: Book,
    iconColor: "Primary",
    component: CreateVendorForm,
    layout: "/admin",
    path: "/create-vendor",
    access: [...baseAccess, roles.headacct],
    parentKey: "vendors",
    uniqueKey: null,
  },
  // {
  //   name: "External Vendors",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: ExternalVendors,
  //   layout: "/admin",
  //   path: "/external-vendors",
  //   access: [...baseAccess, roles.vendor],
  //   parentKey: "vendors",
  //   uniqueKey: null,
  // },
  {
    name: "Pending Vendors",
    icon: Book,
    iconColor: "Primary",
    component: PendingVendors,
    layout: "/admin",
    path: "/pending-vendors",
    access: [...baseAccess, roles.accountant, roles.headacct],
    parentKey: "vendors",
    uniqueKey: null,
  },
  {
    name: "Vendors Creation History",
    icon: Book,
    iconColor: "Primary",
    component: ListLocalVendors,
    layout: "/admin",
    path: "/list-local-vendors",
    access: [...baseAccess, roles.auditors, roles.headacct],
    parentKey: "vendors",
    uniqueKey: null,
  },

  // utilities
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Utilities",
    icon: TitleOutlined,
    uniqueKey: "utilities",
    parentKey: null,
  },
  // audit
  // {
  //   layout: "/admin",
  //   dividerHeader: true,
  //   title: 'Audit',
  //   icon: TitleOutlined,
  //   parentKey: null,
  //   uniqueKey: 'audit',
  // },
  {
    path: "/audit-report",
    name: "Audit Report",
    icon: Book,
    iconColor: "Primary",
    component: AuditRecord,
    layout: "/admin",
    access: [...baseAccess, roles.auditors],
    parentKey: "utilities",
    uniqueKey: null,
  },
  // {
  //   path: "/sms",
  //   name: "SMS",
  //   icon: Book,
  //   iconColor: "Primary",
  //   component: AuditRecord,
  //   layout: "/admin",
  //   access: [...baseAccess, roles.auditors],
  //   parentKey: 'utilities',
  //   uniqueKey: null,
  // },
  {
    path: "/wallets",
    name: "Wallets",
    icon: Book,
    iconColor: "Primary",
    component: AccountTransactionTree,
    layout: "/admin",
    access: [...baseAccess, roles.customerCare, roles.accountant, roles.auditors, roles.headbilling,roles.headacct],
    parentKey: "utilities",
    uniqueKey: null,
  },
  {
    path: "/location",
    name: "Locations",
    icon: Map,
    iconColor: "Primary",
    component: LocationTree,
    layout: "/admin",
    access: [...baseAccess, roles.headbilling],
    parentKey: "utilities",
    uniqueKey: null,
  },
  {
    path: "/meters",
    name: "Meters",
    icon: Grain,
    iconColor: "Primary",
    component: MeterTree,
    layout: "/admin",
    access: [...baseAccess, roles.metering, roles.headbilling],
    parentKey: "utilities",
    uniqueKey: null,
  },
  {
    path: "/tariff",
    name: "Tariffs",
    icon: Book,
    iconColor: "Primary",
    component: TariffTree,
    layout: "/admin",
    access: [...baseAccess],
    parentKey: "utilities",
    uniqueKey: null,
  },
  {
    path: "/meter-request",
    name: "Meter Request",
    icon: Book,
    iconColor: "Primary",
    component: MeterRequest,
    layout: "/admin",
    access: [...baseAccess, roles.headacct],
    parentKey: "utilities",
    uniqueKey: null,
  },
  
  {
    path: "/support-ticket",
    name: "Support Ticket",
    icon: Book,
    iconColor: "Primary",
    component: SupportTicketForm,
    layout: "/admin",
    access: [...baseAccess, roles.customerCare, roles.headbilling],
    parentKey: "utilities",
    uniqueKey: null,
  },
  //end

  // {
  //   path: "/index",
  //   name: "Engineering",
  //   icon: SettingsApplications,
  //   iconColor: "Primary",
  //   component: Customers,
  //   layout: "/admin",
  // },

  // action route
  {
    path: "/transactions/:caller/:requester",
    name: "Account Transactions",
    icon: VerifiedUser,
    iconColor: "Primary",
    component: AccountTransactionTree,
    layout: "/account",
  },
  {
    divider: true,
    layout: "/admin",
  },
  // {
  //   path: "/create-vendor",
  //   name: "Create Vendor",
  //   component: CreateVendorForm,
  //   layout: "/admin",
  //   action: true,
  // },
  {
    path: "/vendor/users/:username",
    name: "Vendors List",
    component: FetchOwnerVendors,
    layout: "/admin",
    action: true,
  },
  {
    path: "/vendor/action/:requester",
    name: "Vendor Action",
    component: FundVendorForm,
    layout: "/admin",
    action: true,
  },
  {
    path: "/vendor/change-password",
    name: "Vendor Change Password",
    component: VendorChangePassword,
    layout: "/admin",
    action: true,
  },
  {
    path: `/vendor/transactions/:vendor`,
    name: "Vendor's Transaction",
    component: VendorsTransactions,
    layout: "/admin",
    action: true,
  },
  {
    path: "/single-meter-request/:uuid",
    name: "Detailed Meter Request",
    component: SingleMeterRequest,
    layout: "/admin",
    action: true,
  },
  // action logout
  {
    path: "/web-logout",
    name: "Logout",
    icon: LogoutOutlined,
    iconColor: "Primary",
    component: () => {
      logOut();
    },
    layout: "/admin",
    action: true,
  },
  {
    path: "/arrears-vend/:caller/:requester",
    name: "Arrears Vend",
    component: ArrearsVend,
    layout: "/admin",
    action: true,
  },
  
];

 const actionRoutes = [
  {
    path: "/switch",
    name: "Pager Auto Switch",
    component: () => {
      pageSwitcher();
    },
    layout: "/admin",
    action: true,
  }
 ];

const reportingRoutes = [
  // vendors section
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Reporting",
    icon: TitleOutlined,
    uniqueKey: "reporting",
    parentKey: null,
  },
  {
    path: "/prepaid-reports",
    name: "Prepaid Reports",
    icon: Grain,
    iconColor: "Primary",
    component: PrepaidReportForm,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.headbilling, roles.prepaidOfficer, roles.ICT],
    parentKey: "reporting",
    uniqueKey: null,
  },
  {
    path: "/prepaid-table",
    name: "Prepaid Reports",
    icon: Grain,
    iconColor: "Primary",
    component: PrepaidReportTable,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.customerCare, roles.headbilling,roles.headacct, roles.prepaidOfficer, roles.ICT],
    parentKey: "reporting",
    uniqueKey: null,
    action: true,
  },
  {
    path: "/postpaid-reports",
    name: "Postpaid Reports",
    icon: Grain,
    iconColor: "Primary",
    component: PostpaidReportForm,
    layout: "/admin",
    access: [...baseAccess, roles.accountant, roles.customerCare, roles.auditors, roles.headbilling,roles.headacct, roles.billingManager, roles.ICT],
    parentKey: "reporting",
    uniqueKey: null,
  },
  {
    path: "/vendor-transaction-report",
    name: "Vendor Reports",
    icon: Grain,
    iconColor: "Primary",
    component: VendorTransactionReportForm,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.headbilling,roles.headacct, roles.accounts],
    parentKey: "reporting",
    uniqueKey: null,
  },
];

const dashboardRoutes = [
  //AutoVisualization
  {
    layout: "/admin",
    dividerHeader: true,
    title: "Dashboard",
    icon: TitleOutlined,
    uniqueKey: "dashboard",
    parentKey: null,
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: Grain,
    iconColor: "Primary",
    component: TransactionDashboard,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.accountant, roles.headbilling,roles.headacct],
    parentKey: "dashboard",
    uniqueKey: null,
    brandText: false,
  },
  {
    path: "/arrears",
    name: "Arrears",
    icon: Grain,
    iconColor: "Primary",
    component: ArrearsDashboard,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.accountant, roles.headbilling,roles.headacct],
    parentKey: "dashboard",
    uniqueKey: null,
    brandText: false,
  },
  {
    path: "/customers",
    name: "Customers",
    icon: Grain,
    iconColor: "Primary",
    component: CustomersDashboard,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.accountant, roles.headbilling],
    parentKey: "dashboard",
    uniqueKey: null,
    brandText: false,
  },
  {
    path: "/meter-status",
    name: "Meters",
    icon: Grain,
    iconColor: "Primary",
    component: MetersDashboard,
    layout: "/admin",
    access: [...baseAccess, roles.auditors, roles.accountant, roles.headbilling],
    parentKey: "dashboard",
    uniqueKey: null,
    brandText: false,
  },
  {
    path: "/dashboard/vendors",
    name: "Vendors",
    icon: Grain,
    iconColor: "Primary",
    component: VendorsDashboard,
    layout: "/admin",
    access: [...baseAccess, roles.accounts],
    parentKey: "dashboard",
    uniqueKey: null,
    brandText: false,
  },
];

export default [...dashboardRoutes, ...routes, ...reportingRoutes, ...actionRoutes];
