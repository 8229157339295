import React from "react";
import FetchTable from "../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
//import TransactionTreeModel from "../../model/transactions/transactions_model";
import { useParams } from "react-router-dom";
import {
  base64ToArrayBuffer,
  prepareParamCaller,
  saveByteArray,
  showToast,
  urls,
} from "../../utils/utils";
import AuditModel from "../../model/audit_model";
import { Grid } from "@material-ui/core";
import { httpRequest } from "../../utils/apis";

function AuditRecord() {
  const [datas, setDatas] = React.useState([]);
  let { caller, requester } = useParams();

  caller = prepareParamCaller(caller);
  React.useEffect(() => {
    initFab();
  }, [datas]);
  const initFab = () => {
    // modify audit model to attach actions to the fabs
    try {
      AuditModel.fabs.downloadReport.onClick = () => {
        showToast({ message: "Downloading report...", type: "success" });
        // make api request to generate report
        httpRequest({
          url: urls.auditReport({ report: true }),
          method: "get",
        }).then((response) => {
          if (response.status) {
            saveByteArray(
              response.data.fileName,
              base64ToArrayBuffer(response.data.base64),
              ".xlsx"
            );
          } else {
            showToast({
              message: response.message || "An error occurred",
              type: "error",
            });
          }
        });
      };
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header />
      <Grid className="">
        <FetchTable
          settings={{
            name: "Audit Activities",
            add: () => {},
            super: (args) => {
              setDatas(args);
            },
            table: "",
            control: AuditModel.control,
            data: {
              caller,
              requester,
            },
          }}
          columns={AuditModel.fields}
          records={[]}
          form={AuditModel}
        />
      </Grid>
    </>
  );
}

export default AuditRecord;
