import { DownloadRounded } from "@mui/icons-material";

export const OwnerVendorsModel = Object.assign(
  {},
  {
    control: "owner_vendors",
    table: "",
    fabs: {
      downloadReport: {
        icon: <DownloadRounded />,
        onClick: () => {},
      },
    },
    fields: [
      {
        name: "name",
        label: "Name",
        // type: "selflink",
        // link: "/admin/vendors/owner/",
      },
      {
        name: "username",
        label: "Username",
      },
      {
        name: "user_type",
        label: "Vendor Type",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        name: "owner_user",
        label: "Owner",
      },
      {
        name: "create_date",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
