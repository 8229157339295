/* eslint-disable unused-imports/no-unused-imports */
import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { GenerateReport, httpRequest } from "../../utils/apis";
import swal from "sweetalert";
import {
  base64ToArrayBuffer,
  saveByteArray,
  showToast,
  smartCashAnalyticsDashboardUrls,
  urls,
} from "../../utils/utils";
import { Button, Checkbox, Grid } from "@material-ui/core";
// import SelectDBAuto from "../../components/selectDbAuto";
import { Stack } from "@mui/material";
import SelectDB from "../selectDb";
import SelectDBAuto from "../selectDbAuto";
import { TypeheadSingle } from "../Typehead/typehead";
//import { toast } from "react-toastify";

function PrepaidReportForm() {

  const [accounts, setAccounts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [tokenTypes, setTokenTypes] = useState([]);

  const [transactionType, setTransactionType] = useState("");
  const [tokenType, setTokenType] = useState("");
  const [account, setAccount] = useState("");
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [fileName, setFileName] = useState("");
  const [vendor, setVendor] = useState("");

  const [minAmount, setMinAmount] = useState(undefined);
  const [maxAmount, setMaxAmount] = useState(undefined);

  const [reportTypes, setReportTypes] = useState([
    {
      value: 'transaction',
      label: 'Transaction Report',
    },
    {
      value: 'feeder_details',
      label: 'Feeder Details Report',
    },
  ]);
  const [reportType, setReportType] = useState("transaction");
  const [feederInfo, setFeederInfo] = useState([
    { value: 'feeder_total_energy', label: 'Feeder Total Energy'},
    { value: 'feeder_total_payments', label: 'Feeder Total Payments' },
  ]);


  let checkboxRef = useRef();

  const onSubmitFeederReport = async () => {
    showToast({
      message: "Generating report...",
      type: "success",
    });
    await httpRequest({
      url: urls.generateFeederReport,
      method: "post",
      data: {
        transactionType,
        startDate,
        endDate,
      },
    }).then((response) => {
      if (response && response.status) {
        setFileName(response.data.fileName);
        saveByteArray(
          `prepaid_${transactionType}_${response.data.fileName}`,
          base64ToArrayBuffer(response.data.base64),
          response.data.extension
        );

        swal("Report Generated.", {
          icon: "success",
        });
      } else {
        swal(response?.message || "An error occurred, please try again.", {
          icon: "warning",
        });
      }
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (reportType === 'feeder_details') return onSubmitFeederReport();

    showToast({
      message: "Generating report...",
      type: "success",
    });

    await httpRequest({
      url: urls.generatePrepaidTransactionReport,
      method: "post",
      data: {
        meterNo: account,
        transactionType,
        tokenType,
        startDate,
        endDate,
        vendorName: vendor,
        minAmount,
        maxAmount
      },
    }).then((response) => {
      if (response && response.status) {
        setFileName(response.data.fileName);
        saveByteArray(
          `prepaid_${transactionType}_report_${response.data.fileName}`,
          base64ToArrayBuffer(response.data.base64),
          response.data.extension
        );

        swal("Report Generated.", {
          icon: "success",
        });
      } else {
        swal(response?.message || "An error occurred, please try again.", {
          icon: "warning",
        });
      }
    });
  };

  const fetchFormFields = () => {
    httpRequest({
      url: urls.prepaidReportFormFields,
      method: "get",
    })
      .then((result) => {
        if (result.status) {
          setTransactionTypes(result.data.transactionType);
          setTokenTypes(result.data.tokenType);
          setVendors(result.data.vendorNames);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const onAccountChange = (e) => {
    e.preventDefault();
    setAccount(e.target.textContent);
  };

  const onVendorChange = (e) => {
    e.preventDefault();
    setVendor(e.target.textContent);
  };

  useEffect(() => {
    fetchFormFields();
    console.log('tokenTypes', tokenTypes);
  }, []);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Prepaid Report</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field ml-5">
                      <label>Account Number</label>
                      {/* <input type="text" placeholder="SmartPower Account" onChange={(e) => setAccount(e.target.value)} /> */}
                      <TypeheadSingle
                        form={{
                          name: "meter_no",
                          type: "select_db_auto",
                          fullWidth: false,
                          field: "meter_no",
                          value: "meter_no",
                          label: "Select Account",
                          table: "res_partner",
                          // picks: accounts,
                          lg: 3,
                          sort: "meter_no",
                        }}
                        idField={"meter_no"}
                        onChange={(e) => {
                          console.log("e", e);
                          onAccountChange(e);
                        }}
                      />
                    </div>

                    <div class="input-field ml-5">
                      <label>Vendor</label>
                      {/* <input type="text" placeholder="SmartPower Account" onChange={(e) => setAccount(e.target.value)} /> */}
                      <TypeheadSingle
                        form={{
                          name: "vendor",
                          type: "select_db_auto",
                          sort: "vendor",
                          fullWidth: false,
                          value: "vendor",
                          field: "vendor",
                          label: "Select Vendor",
                          picks: vendors,
                          lg: 3,
                        }}
                        idField={"vendornumber"}
                        onChange={(e) => {
                          console.log("e", e);
                          onVendorChange(e);
                        }}
                      />
                    </div>

                    <div class="input-field">
                      <label>Select Start Date</label>
                      <input
                        type={"date"}
                        placeholder="Start Date"
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>

                    <div class="input-field">
                      <label>Select End Date</label>
                      <input
                        type={"date"}
                        placeholder="End Date"
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>

                    <div class="input-field">
                      <label>Report Type</label>
                      <select onChange={(e) => setReportType(e.target.value)} value={reportType}>
                        <option value={""}>Select Type</option>
                        {reportTypes && reportTypes.map((key) => {
                          return (
                            <option value={key["value"]}>
                              {key["label"]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {
                      reportType === reportTypes[1].value &&
                      <div class="input-field">
                        <label>Type</label>
                        <select onChange={(e) => setTransactionType(e.target.value)}>
                          <option value={""}>Select Type of Report</option>
                          {feederInfo && feederInfo.map((key) => {
                            return (
                              <option value={key["value"]}>
                                {key["label"]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    }
                    {

                      reportType === reportTypes[0].value &&
                      <>
                        <div class="input-field">
                          <label>Type</label>
                          <select onChange={(e) => setTransactionType(e.target.value)}>
                            <option value={""}>Select Type</option>
                            {transactionTypes && transactionTypes.map((key) => {
                              if (!key["type_t"]) return;
                              return (
                                <option value={key["type_t"]}>
                                  {key["type_t"]}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div class="input-field">
                          <label>Transaction Type</label>
                          <select onChange={(e) => setTokenType(e.target.value)}>
                            <option value={""}>Select Transaction Type</option>
                            {tokenTypes && tokenTypes.map((item, index) => {
                              if (!item["token_type"]) return;
                              return (
                                <option key={index} value={item["token_type"]}>
                                  {item["token_type"]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </>
                    }

                    <div class="input-field">
                      <label>Minimum Amount</label>
                      <input
                        type={"number"}
                        placeholder="Minimum Amount"
                        onChange={(e) => setMinAmount(e.target.value)}
                      />
                    </div>

                    <div class="input-field">
                      <label>Maximum Amount</label>
                      <input
                        type={"number"}
                        placeholder="Maximum Amount"
                        onChange={(e) => setMaxAmount(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Generate Report
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}

export default PrepaidReportForm;
