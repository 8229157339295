import { FormControlLabel, Grid, RadioGroup } from "@material-ui/core";
import { FormControl, InputLabel, Radio } from "@material-ui/core";
import { useState } from "react";

export const AutoformRadioButton = ({ field, key, handleChange }) => {
  const [checked, setChecked] = useState(field.checked || false);
  console.log();
  return (
    <Grid item xl={3} lg={6} xs={12}>
      <FormControl focused={true} fullWidth={field.fullWidth}>
        <>
          {field.picks && field.picks.length > 1 ? (
            <RadioGroup
              id={field.name}
              name={field.name}
              onChange={(evt) =>
                handleChange && handleChange(evt, evt.target.value)
              }
            >
              {field.picks.map((pick) => {
                return (
                  <>
                    <FormControlLabel
                      value={pick.value}
                      control={<Radio />}
                      label={pick.label}
                      onChange={(evt) => {}}
                    />
                    {/* <InputLabel 
                                        key={`${pick.label}label`} 
                                        id={`select_label_${pick.label}`}
                                    >
                                        
                                    </InputLabel>  */}
                    {/* {pick.label}
                                    <Radio 
                                        title={pick.label}
                                        // checked={checked} 
                                        
                                        value={pick.value} 
                                        onClick={(evt) => {
                                            handleChange && handleChange(evt, evt.target.value);
                                        }} 
                                    /> */}
                  </>
                );
              })}
            </RadioGroup>
          ) : (
            <>
              <InputLabel key={`${key}label`} id={`select_label_${field.name}`}>
                {field.label}
              </InputLabel>
              <Radio
                checked={checked}
                name={field.name}
                id={field.name}
                value={field.value}
                onClick={(evt) => {
                  setChecked(!checked);
                  handleChange && handleChange(evt, evt.target.value);
                }}
              />
            </>
          )}
        </>
      </FormControl>
    </Grid>
  );
};
