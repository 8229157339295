import React, { useEffect, useState } from "react";
import "assets/css/report.css";
import { httpRequest } from "./utils/apis";
import { Button, Grid } from "@material-ui/core";
// import SelectDBAuto from "../../components/selectDbAuto";
import { Stack } from "@mui/material";
import { showToast, urls } from "./utils/utils";
import {
  getFromLocalStorage,
  writeToLocalStorage,
} from "./utils/localStorage.util";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { roles } from "./permissions";

function VendorLogin() {
  // const [login, setLogin] = useState('');
  // const [password, setPassword] = useState('');

  const [login, setLogin] = useState("admin");
  const [password, setPassword] = useState("@!#UtiliSmart@123");

  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    const user = getFromLocalStorage("userData");
    if (user) {
      // redirect into app
      history.push("/admin/vendors", {
        pathname: `/admin/vendors`,
        state: "",
      });
    } else {
      // make login api call
      httpRequest({
        url: urls.vendorSignin,
        method: "post",
        data: {
          username: login,
          password: password,
        },
      }).then((data) => {
        console.log("data", data);
        if (data.status) {
          // window.localStorage.setItem('userData2', JSON.stringify(data.data));
          const _data = data.data;
          //_data.token = "";
          writeToLocalStorage("userData", { ..._data, ...{ username: login } });
          writeToLocalStorage("roles", [roles.vendor]);
          showToast({
            message: "Login successful",
            type: "success",
          });
          history.push("/admin/internal-vendors", {
            pathname: `/admin/internal-vendors`,
            state: "",
          });
        } else {
          showToast({
            message: data.message || "An error occurred",
            type: "error",
          });
        }
      });
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      {/*<Header />*/}

      <div
        className="login-page"
        style={{ width: "360px", padding: "8% 0 0", margin: "auto" }}
      >
        <div
          className="form"
          style={{
            position: "relative",
            zIndex: 1,
            background: "#FFFFFF",
            width: "140%",
            marginLeft: "-90px",
            padding: "27px",
            textAlign: "center",
            boxShadow:
              "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
          }}
        >
          <Avatar
            alt="..."
            src={
              require(`./assets/img/brand/${
                process.env.REACT_APP_APP_LOGO
                  ? process.env.REACT_APP_APP_LOGO
                  : "argon-react.png"
              }`).default
            }
            style={{ marginBottom: "10px", width: "70px", height: "60px" }}
          />
          <form className="login-form">
            <Grid item lg={12} xs={12}>
              <input
                type="text"
                placeholder="admin"
                onChange={(e) => setLogin(e.target.value)}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  outline: 0,
                  background: "#f2f2f2",
                  width: "100%",
                  border: 0,
                  margin: "0 0 15px",
                  padding: "15px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                }}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <input
                type={"password"}
                placeholder="@!#UtiliSmart@123"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  outline: 0,
                  background: "#f2f2f2",
                  width: "100%",
                  border: 0,
                  margin: "0 0 15px",
                  padding: "15px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                }}
              />
            </Grid>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  textTransform: "uppercase",
                  outline: 0,
                  background: "#017cc2",
                  width: "100%",
                  border: 0,
                  padding: "15px",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  transition: "all 0.3 ease",
                  cursor: "pointer",
                }}
              >
                login
              </Button>
            </Stack>
          </form>
        </div>
      </div>
    </>
  );
}

export default VendorLogin;
