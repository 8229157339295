import React from "react";
import { TableBody, TableRow, TableCell, Button } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";

import { LinearProgress } from "@material-ui/core";

import componentStyles from "assets/theme/views/admin/tables.js";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons components
import MoreVert from "@material-ui/icons/MoreVert";
import { FormControlLabel, Checkbox } from "@material-ui/core";
const useStyles = makeStyles(componentStyles);
function CustomerTableBody({ records = [], handleClick, theme }) {
  // const [checked,setChecked] = React.useState(false)
  const classes = useStyles();
  const handleChange = (evt) => {
    console.log(evt.currentTarget);
    evt.currentTarget.setAttribute("checked", !evt.currentTarget.checked);
  };
  return (
    <TableBody>
      {records &&
        records.map((cus, indx) => (
          <TableRow key={cus.id}>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              <FormControlLabel
                control={<Checkbox onChange={handleChange} />}
              />
            </TableCell>

            <TableCell
              classes={{
                root:
                  classes.tableCellRoot + " " + classes.tableCellRootBodyHead,
              }}
              component="th"
              variant="head"
              scope="row"
            >
              <Box alignItems="center" display="flex">
                <Box
                  component={Avatar}
                  marginRight="1rem"
                  alt="..."
                  src={require("assets/img/icons/sideicon.jpg").default}
                />
                <Box display="flex" alignItems="flex-start">
                  <Box fontSize=".875rem" component="span">
                    {cus.name}
                  </Box>
                </Box>
              </Box>
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.juice_acc_no}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.locid}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.meter_no}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.acc_no}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.phone}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.state}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              {cus.district}
            </TableCell>
            <TableCell
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              <Box display="flex" alignItems="center">
                <Box component="span" marginRight=".5rem">
                  100%
                </Box>
                <Box width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    classes={{
                      root: classes.linearProgressRoot,
                      bar: classes.bgGradientSuccess,
                    }}
                  />
                </Box>
              </Box>
            </TableCell>
            <TableCell classes={{ root: classes.tableCellRoot }} align="right">
              <Box
                aria-controls="simple-menu-5"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
                rowid={indx}
                key={indx}
                component={Button}
                width="2rem!important"
                height="2rem!important"
                minWidth="2rem!important"
                minHeight="2rem!important"
              >
                <Box
                  component={MoreVert}
                  width="1.25rem!important"
                  height="1.25rem!important"
                  position="relative"
                  top="2px"
                  color={theme.palette.gray[500]}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      {/* */}
    </TableBody>
  );
}

export default CustomerTableBody;
