import { Button, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import Header from "components/Headers/Header";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { prepareValue, showToast, urls } from "./../../utils/utils";
import { httpRequest } from "./../../utils/apis";

export default function SingleMeterRequest() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const [account, setAccount] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [approvedBy, setApprovedBy] = React.useState('');
  const [approvedDate, setApprovedDate] = React.useState('');
  const [bld, setBld] = React.useState([]);
  const [createDate, setCreateDate] = React.useState('');
  const [currentBillingInfo, setCurrentBillingInfo] = React.useState('');
  const [district, setDistrict] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [dataSubmitted, setDataSubmitted] = React.useState(false);
  const [idDocument, setIdDocument] = React.useState('');
  const { uuid } = useParams();
  const history = useHistory();

  const onSubmit = async (e, action) => {
    e.preventDefault();
    // make api call to persit info to db
    const request = await httpRequest({
      url: urls.approveMeterRequest, data: {
        ids: uuid,
        action,
      }, method: 'PATCH'
    });
    console.log('request', request);
    if (request.status) {
      showToast({ type: 'success', message: request.message || `Map request record was ${action}.` });
      setDataSubmitted(true);
    } else {
      showToast({ type: 'error', message: request.message || 'Request failed, please try again.' });
    }

  };

  React.useEffect(() => {
    console.log("uuid", uuid);
    fetchData(uuid);
  }, []);

  const fetchData = async (_uuid = uuid) => {
    // fetch data for this meter request staging record
    const request = await httpRequest({ url: urls.getPendingCustomerInfoById({ id: _uuid }), data: undefined, method: 'get' });
    if (!request.status) {
      showToast({ type: 'error', message: request.message || 'Map request record was not found' });
      return;
    }
    console.log('request', request);
    loadCustomerData(request.data);
  }

  const loadCustomerData = (payload) => {
    if (payload) {
      // account_no
      setAccount(payload.account_no);
      // address
      setAddress(payload.address);
      // approved_by
      setApprovedBy(payload.approved_by);
      // approveddate
      setApprovedDate(payload.approveddate);
      // bld
      setBld(payload.bld);
      // createdate
      setCreateDate(payload.createdate);
      // currentbillinginfo
      setCurrentBillingInfo(payload.currentbillinginfo)
      // district
      setDistrict(payload.district);
      // email
      setEmail(payload.email);
      // fullname
      setFullName(payload.fullname);
      // idDocument
      setIdDocument(payload.idDocument);
    }
  }

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Map Meter Request</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field ml-5">
                      <label>Account Number</label>
                      <input type="text" placeholder={account} readOnly={true} />
                    </div>
                    <div class="input-field ml-5">
                      <label>Full Name</label>
                      <input type="text" placeholder={fullName} readOnly={true} />
                    </div>
                    <div class="input-field ml-5">
                      <label>Email</label>
                      <input type="text" placeholder={email} readOnly={true} />
                    </div>

                    <div class="input-field ml-5">
                      <label>Address</label>
                      <input type="text" placeholder={address} readOnly={true} />
                    </div>

                    <div class="input-field">
                      <label>Approved By</label>
                      <input
                        type={"text"}
                        placeholder={approvedBy}
                        readOnly={true}
                      />
                    </div>

                    <div class="input-field">
                      <label>Approve Date</label>
                      <input
                        type={"text"}
                        placeholder={prepareValue('date', approvedDate)}
                        readOnly={true}
                      />
                    </div>

                    <div class="input-field">
                      <label>Create Date</label>
                      <input
                        type={"text"}
                        placeholder={prepareValue('date', createDate)}
                        readOnly={true}
                      />
                    </div>
                    <div class="input-field">
                      <label>Current Billing Info</label>
                      <input
                        type={"text"}
                        placeholder={currentBillingInfo}
                        readOnly={true}
                      />
                    </div>
                    <div class="input-field">
                      <label>District</label>
                      <input
                        type={"text"}
                        placeholder={district}
                        readOnly={true}
                      />
                    </div>
                  </Grid>
                </Grid>
                {
                  bld  && (<div>
                    <div style={{fontWeight: 'bold'}}>Basic Load Declaration</div>
                    <span style={{marginRight: 5}}>{bld}</span>
                  </div>)
                }
              </div>
              <div>
              </div>
            </div>
            {
              !dataSubmitted &&
              <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
                <Button variant="contained" onClick={(e) => onSubmit(e, 'approved')}>
                  <span
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      justifySelf: "center",
                    }}
                  >
                    Approve Customer Information Update
                  </span>
                </Button>
                <Button onClick={(e) => onSubmit(e, 'rejected')}>
                  <span
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      justifySelf: "center",
                      color: 'red',
                    }}
                  >
                    Reject Customer Information Update
                  </span>
                </Button>
              </Stack>
            }
          </div>
        </div>
      </form>
    </>
  );
}
