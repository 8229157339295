import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { httpRequest } from "../../utils/apis";
import { showToast, urls } from "../../utils/utils";
import { Button, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";

function CreateVendorForm() {

  
  const [selectedPriority, setSelectedPriority] = useState("");

  const [subject, setSubject] = useState("");

  const [limitAmount, setLimitAmount] = useState("");
  // const [ownerUser, setOwnerUser] = useState('');
  const [userType, setUserType] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [mdCommisionRate, setMdCommisionRate] = useState(0);
  const [nmdCommisionRate, setNmdCommisionRate] = useState(0);
  const [vendEnabled, setVendEnabled] = useState('Yes');

  


  const onSubmit = async (e) => {
    e.preventDefault();
    if (!name || !username || !password || !userType || !limitAmount) {
      showToast({
        message: 'Please fill all fields',
        type: 'error',
      });
      return;
    }

    const payload = {
      name,
      username,
      password,
      user_type: userType,
      limit_amount: limitAmount,
      nmd_commision_rate: nmdCommisionRate,
      md_commision_rate: mdCommisionRate,
      vend_enabled: vendEnabled === 'Yes' ? true : false,
    };
    

    // payload._headers = {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`
    // };
    // make http request to support server
    // has header
    console.log("payloaload", payload);
    httpRequest({
      url: urls.vendorSignup,
      method: "post",
      data: payload,
    }).then((result) => {
      if (result && result.status) {
        showToast({ type: "success", message: result.message });
        setName('');
        setUsername('');
        setUserType('');
        setLimitAmount(0);
        setNmdCommisionRate(0);
        setMdCommisionRate(0);
      } else {
        showToast({
          type: "error",
          message: result.message || `An error occurred.`,
        });
      }
    });
  };



  useEffect(() => {}, []);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Create Vendor</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Name</label>
                      <input
                        type="text"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Username</label>
                      <input
                        type={"text"}
                        placeholder="Username"
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Password</label>
                      <input
                        type={"password"}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Limit</label>
                      <input
                        type={"number"}
                        placeholder="Limit Amount"
                        onChange={(e) => setLimitAmount(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>MD Commision Rate</label>
                      <input
                        type={"number"}
                        placeholder="Md Commision Rate"
                        onChange={(e) => setMdCommisionRate(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Non-MD Commision Rate</label>
                      <input
                        type={"number"}
                        placeholder="Non-MD Commision Rate"
                        onChange={(e) => setNmdCommisionRate(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>User Type</label>
                      <select onChange={(e) => {
                        setUserType(e.target.value);
                        if (e.target.value == "meter-supplier") {
                          setVendEnabled('No');
                        }
                      }}>
                        {["Select user type","vendor", "meter-supplier", "subvendor", "cashier", "agent"].map(
                          (item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Vend Enabled</label>
                      <select value={vendEnabled} onChange={(e) => setVendEnabled(e.target.value)}>
                        {["Yes", "No"].map(
                          (item, index) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Submit
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateVendorForm;
