import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const ChangeSGCCodeModel = Object.assign(
  {},
  {
    edit: true,
    editPram: { col: "meter_no" },
    table: "meter_details",
    uniqId: uniqId,
    fields: [
      { name: "meter_no", label: "", type: "hidden" },
      {
        fullWidth: true,
        validate: { required: true },
        name: "sgc",
        type: "text",
        label: "Change SGC Code",
      },
      // {
      //   name: "lifenotes",
      //   type: "text",
      //   validate: { required: true },
      //   fullWidth: true,
      //   label: "Life Notes",
      // },
    ],
    desc: "Change SGC Code Index Create/Edit",
    title: "Change SGC Code Index Setup",
  }
);

export const ChangeSGCCodeReadModel = [];

// const
export default ChangeSGCCodeModel;
