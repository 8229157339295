const VendModel = {
  edit: false,
  table: "res_partner",
  control: "meter_action",
  fields: [
    {
      name: "field",
      type: "hidden",
      value: "",
    },
    {
      name: "value",
      type: "hidden",
      value: "",
    },
    {
      name: "meter_no",
      type: "hidden",
      value: "",
    },
    {
      name: "units",
      type: "text",
      fullWidth: true,
      label: "Cash Amount",
    },
    {
      name: "action",
      type: "hidden",
      value: "vend",
    },
    {
      name: "source",
      type: "hidden",
      value: "cashier",
    },
  ],
  desc: "Vend",
  title: "Vend Setup",
};

export default VendModel;
