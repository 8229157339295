import * as React from "react";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
// import ToolTip from './NavToolTip';

function NewHeader(props) {
  // const { sections } = props;
  const sections = [
    {
      title: "home",
      url: "/home",
    },
    {
      title: "away",
      url: "/away",
    },
  ];

  return (
    <React.Fragment>
      {/* <Toolbar sx={{ borderBottom: 1, borderColor: 'divider' }}></Toolbar> */}
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          justifyContent: "flex-start",
          overflowX: "auto",
          marginLeft: "10%",
        }}
      >
        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            sx={{ p: 1, flexShrink: 0 }}
            style={{ marginLeft: 10 }}
          >
            {section.title}
          </Link>
        ))}
        {/* <ToolTip /> */}
      </Toolbar>
    </React.Fragment>
  );
}

NewHeader.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};

export default NewHeader;
