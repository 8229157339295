import React from "react";
import FetchTable from "./../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
import MeterFormModel from "model/meter_model";
import MeterReadModel from "./../../model/meter_fetch_model";
import AutoForms from "components/AutoForm";

function MeterTree() {
  const [datas, setDatas] = React.useState([]);
  const [addmeter, setAddMeter] = React.useState(false);

  React.useEffect(() => {}, [datas]);
  const cols = [
    { name: "locid", type: "id", label: "LOC-ID" },
    { name: "address", type: "large-text", label: "Address" },
    { name: "state.name#res_state", type: "large-text", label: "State" },
  ];
  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Meter Detials",
          table: "meter_details",
          add: setAddMeter,
        }}
        columns={MeterReadModel.fields}
        records={[]}
        form={MeterReadModel}
      />

      <AutoForms
        key={0}
        keyId={1567}
        form={MeterFormModel}
        cancel={setAddMeter}
        open={addmeter}
      />
    </>
  );
}

export default MeterTree;
