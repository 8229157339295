import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const Activate4VendingModel = {
  edit: true,
  editPram: { col: "meter_no" },
  respText: "Action was successful!",
  table: "res_partner",
  uniqId: uniqId,
  fields: [
    { name: "meter_no", label: "", type: "hidden" },
    {
      name: "bill_status",
      type: "switch",
      label: " ",
      validate: { required: true },
      value: true,
    },
  ],
  desc: "Activate 4 Vending",
  title: "Activate 4 Vending Setup",
};

export const Activate4VendingReadModel = [];

// const
export default Activate4VendingModel;
