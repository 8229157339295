import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { QueryTableWhere } from "../utils/apis";
import { numberWithCommas } from "utils/utils";
import { generateKey } from "utils/utils";
// import { Refresh } from "@material-ui/icons";
// import Select from '@mui/material/Select';

export default function AutoformRenderInput({
  keyId,
  open,
  handleClose,
  fullWidth,
  condition,
  form,
  renderValue,
}) {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(
    renderValue ? renderValue : form.defaultValue
  );

  React.useEffect(() => {
    setLoading(true);
    if (form.type !== "render") {
      fetchData();
    }
  }, []);

  const fetchData = () => {
    QueryTableWhere(form.table, form.columnToRender, condition, form?.limit, form?.opts)
      .then((recs) => {
        if (recs && recs.length > 0) {
          if (recs[0][form.columnToRender]) {
            const _value = parseFloat(recs[0][form.columnToRender]).toFixed(2);
            setValue(cleanValue(_value));
          } else {
            setValue(cleanValue(0));
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const cleanValue = (value, type) => {
    switch (type) {
      default:
        value = `NGN ${numberWithCommas(value)}`;
        break;
    }
    return value;
  };

  // should render a popup form *
  // should fetch data
  // should render from gotten dataset
  // should be closable
  return (
    <>
      <Dialog key={keyId} open={open} onClose={handleClose}>
        <DialogTitle>{form.title}</DialogTitle>
        <DialogContent>
          <Grid
            key={generateKey()}
            container
            item
            xs={12}
            lg={fullWidth === undefined || fullWidth === false ? 6 : 12}
            marginTop="10px"
          >
            <FormControl
              focused={true}
              fullWidth={true}
              style={{ marginTop: "5px" }}
            >
              <InputLabel
                key={`${new Date().toDateString()}_label`}
                id={`${form.name}`}
                style={{ color: "green", fontSize: "30px" }}
              >
                {form.prefix} {value}
              </InputLabel>
              <InputLabel
                key={`${new Date().toDateString()}_label`}
                // id={`${form.name}`}
                style={{ color: "green", fontSize: "15px", marginTop: "20px" }}
              >
                {form.defaultHeader}
              </InputLabel>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button actionsend="cancel" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
