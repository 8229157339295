/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { AutoBarVisualization } from "../../../components/AutoVisualization/bar.charts";
import componentStyles from "assets/theme/components/header.js";
import {
  WalletSingleModel,
  ArrearsSingleModel,
  PostpaidTransactionSingleModel,
  LastPaymentModel,
} from "../../../model/postpaid_cardstat_model";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { AutoformRenderCard } from "../../../components/AutoFormRenderCard";
import {
  DailySumTransactionByTypeModel,
  DailySumTransactionModel,
  MonthlySumTransactionByTypeModel,
  MonthlySumTransactionModel,
  WeeklySumTransactionByTypeModel,
  WeeklySumTransactionModel,
  YearlySumTransactionByTypeModel,
  YearlySumTransactionModel,
} from "../../../model/dashboard/transaction_model";
import { WeeklySumPrepaidArrearsModel } from "model/dashboard/arrears_model";
import { MonthlySumPrepaidArrearsModel } from "model/dashboard/arrears_model";
import {
  WeeklySumArrearsByCustomerCategoryModel,
  MonthlySumArrearsCustomerCategoryModel,
  YearlySumPrepaidArrearsModel,
  YearlySumArrearsCustomerCategoryModel,
  DailySumArrearsByCustomerClassModel,
  WeeklySumArrearsByCustomerClassModel,
  MonthlySumArrearsCustomerClassModel,
  YearlySumArrearsCustomerClassModel,
  TotalPrepaidArrearsByServiceCentersModel,
  TotalActivePostpaidArrearsByServiceCentersModel,
  TotalSuspendedPostpaidArrearsByServiceCentersModel,
  TotalPrepaidArrearsByCustomerCategoryModel,
  TotalActivePostpaidArrearsByCustomerCategoryModel,
} from "./../../../model/dashboard/arrears_model";

export default function ArrearsDashboard() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  React.useEffect(() => { }, []);
  const _caller = "acc_no";
  const requester = "3437469750";

  return (
    <div>
      <div
        className={classes.header}
        style={{ height: 200, paddingTop: 15, marginBottom: 10 }}
      >
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={4} lg={4} xs={12}>
                <AutoformRenderCard
                  condition={``}
                  form={WeeklySumPrepaidArrearsModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={4} lg={4} xs={12}>
                <AutoformRenderCard
                  condition={``}
                  form={MonthlySumPrepaidArrearsModel}
                  prefix={"NGN "}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={4} lg={6} xs={12}>
                <AutoformRenderCard
                  condition={`'`}
                  form={YearlySumPrepaidArrearsModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div style={{ display: "flex", flexDirection: "column", marginBottom: 20, alignItems: 'center' }}>
        {/* Arrears Customer Category */}
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 60, }}>
          <div
            style={{
              width: TotalPrepaidArrearsByServiceCentersModel.style.width,
              height: TotalPrepaidArrearsByServiceCentersModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={TotalPrepaidArrearsByServiceCentersModel} />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 60 }}>
          <div
            style={{
              width: TotalActivePostpaidArrearsByServiceCentersModel.style.width,
              height: TotalActivePostpaidArrearsByServiceCentersModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization
              form={TotalActivePostpaidArrearsByServiceCentersModel}
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 60 }}>
          <div
            style={{
              width: TotalPrepaidArrearsByCustomerCategoryModel.style.width,
              height: TotalPrepaidArrearsByCustomerCategoryModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={TotalPrepaidArrearsByCustomerCategoryModel} />
          </div>
        {/* </div>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: 60 }}> */}
          <div
            style={{
              width: TotalActivePostpaidArrearsByCustomerCategoryModel.style.width,
              height: TotalActivePostpaidArrearsByCustomerCategoryModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={TotalActivePostpaidArrearsByCustomerCategoryModel} />
          </div>
        </div>
      </div>
    </div>
  );
}
