import { TableChart } from "@material-ui/icons";
import {
  dailyStartAndEnd,
  managementDashboardUrls,
  monthStartAndEnd,
  weekStartAndEnd,
  yearStartAndEnd,
} from "../../utils/utils";

// Prepaid Transactions
export const DailySumTransactionByTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumPrepaidTransactionByType({
        startDate: dailyStartAndEnd().start,
        endDate: dailyStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Transactions for Today By Type",
  }
);

export const WeeklySumTransactionByTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumPrepaidTransactionByType({
        startDate: weekStartAndEnd().start,
        endDate: weekStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Transactions for Week By Type",
  }
);

export const MonthlySumTransactionByTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumPrepaidTransactionByType({
        startDate: monthStartAndEnd().start,
        endDate: monthStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Transactions for Month By Type",
  }
);

export const YearlySumTransactionByTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.sumPrepaidTransactionByType({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 320,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Transactions for Year By Type",
  }
);

// Prepaid sums
export const DailySumTransactionModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Sum of Transactions for the Day",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumPrepaidTransaction({
        startDate: dailyStartAndEnd().start,
        endDate: dailyStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Daily Transactions",
  }
);

export const WeeklySumTransactionModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Sum of Transactions for the Week",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumPrepaidTransaction({
        startDate: weekStartAndEnd().start,
        endDate: weekStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Weekly Prepaid Transactions",
  }
);

export const MonthlySumTransactionModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Sum of Transactions for the Month",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumPrepaidTransaction({
        startDate: monthStartAndEnd().start,
        endDate: monthStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Monthly Prepaid Transactions",
  }
);

export const YearlySumTransactionModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Sum of Transactions for the Year",
    placeholder: "0.00",
    field: "records",
    transform: "currency",
    request: {
      url: managementDashboardUrls.sumPrepaidTransaction({
        startDate: yearStartAndEnd().start,
        endDate: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Yearly Prepaid Transactions",
  }
);

// Prepaid Transactions
export const SumPrepaidTransactionWeeklyModel = Object.assign(
    {},
    {
      control: "http_action",
      request: {
        url: managementDashboardUrls.sumPrepaidTransactionWeekly({
          startDate: yearStartAndEnd().start,
          endDate: yearStartAndEnd().end,
        }),
        method: "get",
        baseUrl: managementDashboardUrls.baseURL,
      },
      style: {
        width: 950,
        height: 250,
      },
      fields: {
        argumentField: "weekly",
        valueField: "records",
      },
      title: "Weekly Prepaid Transactions",
    }
);


export const SumPrepaidTransactionMonthlyModel = Object.assign(
    {},
    {
      control: "http_action",
      request: {
        url: managementDashboardUrls.sumPrepaidTransactionMonthly({
          startDate: yearStartAndEnd().start,
          endDate: yearStartAndEnd().end,
        }),
        method: "get",
        baseUrl: managementDashboardUrls.baseURL,
      },
      style: {
        width: 950,
        height: 250,
      },
      fields: {
        argumentField: "collection",
        valueField: "records",
      },
      title: "Monthly Prepaid Transactions",
    }
);

// Postpaid Transactions
export const PostpaidTransactionByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalCollectionByCustomersCategory({
        start: yearStartAndEnd().start,
        end: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 950,
      height: 250,
    },
    fields: {
      argumentField: "customer_category",
      valueField: "collection",
    },
    title: "Postpaid Transaction By Customer Category",
  }
);

export const PostpaidTransactionByBillTypeModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalCollectionByBillType({
        start: yearStartAndEnd().start,
        end: yearStartAndEnd().end,
      }),
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 950,
      height: 250,
    },
    fields: {
      argumentField: "bill_description",
      valueField: "collection",
    },
    title: "Postpaid Transaction By Bill Type",
  }
);
