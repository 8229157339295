// view vendors
// view subvendors
// view cashiers
// view agents
// view vendor's subvendors
// view subvendor's cashiers
// view subvendor's agent

import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
import React from "react";
import { useHistory } from "react-router-dom";
import { PendingVendorsModel } from "../../model/vendors/pending_vendors_model";

export default function PendingVendors() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Pending Vendors Creation",
          table: PendingVendorsModel.table,
          control: PendingVendorsModel.control,
        }}
        columns={PendingVendorsModel.fields}
        records={[]}
        form={PendingVendorsModel}
      />
    </>
  );
}
