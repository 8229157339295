// import { Add } from "@material-ui/icons";

export const ExternalVendorsModel = Object.assign(
  {},
  {
    control: "external_vendors_model",
    table: "",
    fabs: {},
    action: {
      component: [
        // ({ data, history }) => {
        //   return (
        //     <span
        //       style={{ color: "green", cursor: "pointer" }}
        //       onClick={() => {
        //         history.push(`/admin/vendor/action/${data["username"]}`, {
        //           pathname: `/admin/vendor/action/${data["username"]}`,
        //           state: data,
        //         });
        //       }}
        //     >
        //       Fund{" "}
        //     </span>
        //   );
        // },
        ({ data, history }) => {
          return (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push(`/admin/vendor/transactions/${data["token"]}`, {
                  pathname: `/admin/vendor/transactions/${data["token"]}`,
                  state: data,
                });
              }}
            >
              View transactions
            </span>
          );
        },
      ],
    },
    fields: [
      {
        name: "username",
        label: "Username",
        // link: "/admin/vendors/owner/",
        // link: "/admin/vendor/action/",
        // type: "selflink",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "amount",
        label: "Amount",
      },
      {
        name: "limit_amount",
        label: "Limit Amount",
      },
      {
        name: "user_type",
        label: "Vendor Type",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        name: "owner_user",
        label: "Owner",
      },
      {
        name: "create_date",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
