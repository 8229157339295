import Header from "components/Headers/Header";
import FetchTable from "./../../components/Tables/FetchTable";
import { useEffect } from "react";
import Controller from "./../../utils/controls";
import { AdjustmentModel } from "./../../model/postpaid_adjustment_model";
import { saveByteArray, base64ToArrayBuffer } from "./../../utils/utils";

export function AdjustmentReversal() {
  useEffect(() => {
    AdjustmentModel.fabs.downloadReport.onClick = () => {
      Controller({
        control: AdjustmentModel.control,
        // limit: settings.limit,
        data: {
          report: true,
          table: AdjustmentModel.table,
          data: AdjustmentModel.fields,
        },
      }).then((response) => {
        //response.data.fileName
        saveByteArray(
          response.data.fileName,
          base64ToArrayBuffer(response.data.base64),
          ".xlsx"
        );
      });
    };
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        styles={{ marginTop: "0" }}
        settings={{
          name: "Postpaid Bill Adjustments",
          table: AdjustmentModel.table,
          limit: AdjustmentModel.limit,
          control: AdjustmentModel.control,
          rowAction: AdjustmentModel?.action?.component,
          data: {
            table: AdjustmentModel.table,
            data: AdjustmentModel.fields,
          },
        }}
        columns={AdjustmentModel.fields}
        records={[]}
        form={AdjustmentModel}
      />
    </>
  );
}
