import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FormControl, Grid, InputLabel } from "@material-ui/core";
import {
  //LoadSql,
  LoadFromTableAuto,
} from "../utils/apis";
// import { Refresh } from "@material-ui/icons";
// import Select from '@mui/material/Select';
export default function SelectDBAuto({ col, indx, handleChange, form={} }) {
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(undefined);

  React.useEffect(() => {
    let mounted = true;
    setLoading(true);
    LoadFromTableAuto(col.table, col.field, col.value, search, col.sort, form?.opts)
      .then((res) => {
        if (mounted !== false) {
          console.log(res);
          setRows(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
    return () => (mounted = false);
  }, [search]);

  const reLoadOptions = (evt) => {
    if (evt.target.value) setSearch(evt.target.value);
  };
  const onHandleChange = (evt) => {
    evt.preventDefault();
    console.log(evt.target.value);
    setValue(evt.target.value);
    console.log("value", evt, evt.target.value);
    col.handleChange === undefined
      ? handleChange(evt, evt.target.value)
      : col.handleChange(evt, evt.target.value);
    //col.handleChange && col.handleChange(evt);
  };
  console.log("rows", rows);
  return (
    <>
      <Grid
        item
        xs={12}
        lg={
          col.lg
            ? col.lg
            : col.fullWidth === undefined || col.fullWidth === false
            ? 6
            : 12
        }
        marginTop="10px"
      >
        <FormControl focused={true} fullWidth={true}>
          <InputLabel key={`${indx}label`} id={`select_label_${col.name}`}>
            {col.label}
          </InputLabel>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            name={col.name}
            options={rows}
            loading={loading}
            onChange={
              //onHandleChange
              col.handleChange === undefined ? handleChange : col.handleChange
            }
            size="medium"
            onInputChange={reLoadOptions}
            renderInput={(params) => {
              console.log("params", params);
              return <TextField {...params} />;
            }}
            value={value}
            // defaultValue={"N90S2597518"}
          />
        </FormControl>
      </Grid>
    </>
  );
}
