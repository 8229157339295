import { OpenInNewOffRounded } from "@mui/icons-material";
import AutoForms from "components/AutoForm";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { UniqueCode } from "utils/events";
import { showToast } from "utils/utils";
import { prepareParamCaller } from "utils/utils";
import TransactionReversalForm from "./transaction_reversal_form";
import { handleReversalAction } from "./transactions_model";

const uniqId = `L${UniqueCode(1000000)}`;
// fields.co_name is the alias name to be used when data is returned
// fields.name is the autoform database query to be interpreted
// fields.label is the label to be used in the UI
// fields.type is the type of the field
// fields.transform is the transform action to be performed on the data
// fields.table_name is the table name to be used creating a join query

const PostpaidPaymentModel = {
  control: "postpaid_transaction_table",
  table: "",
  // limit: 10,
  action: {
    component: [
      ({ data, history }) => {
        // console.log('data', data);
        // alert('')
        const { caller, requester } = useParams();
        return (
          <span>
            <OpenInNewOffRounded
              onClick={() => {
                history.push({
                  pathname: `/receipt`,
                  state: {...data, ...{
                    meteringType: 'postpaid',
                    action: "download_pdf",
                    caller: prepareParamCaller(caller),
                    requester,
                  }} 
                });
              }}
            />
          </span>
        );
      },
      ({ data, history }) => {
        const [showGrid, setShowGrid] = useState(false);
        return (
          <>
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setShowGrid(true);
              }}
            >
              Reverse Transaction
            </span>
            {
              showGrid &&
              <AutoForms
                key={10001}
                keyId={156001}
                form={TransactionReversalForm}
                cancel={setShowGrid}
                open={showGrid}
                submit={({formData}) => {
                  formData.opts = {
                    meteringType: 'postpaid',
                  }
                  console.log('TransactionReversalForm', formData, data);
                  if (!formData.actionType || formData.actionType === '') {
                    showToast({
                      message: 'Please select action type.',
                      type: 'error',
                    })
                    return;
                  }
                  // make api call here and handle
                  showToast({
                    message: 'Reversing Transaction, please wait.',
                    type: 'success',
                  })
                  handleReversalAction({ formData: formData, data: data });
                }}
              />
            }
          </>

        );
      },
    ],
  },
  fields: [
    {
      co_name: "id",
      label: "Transaction Id",
      table_name: "account_payment",
      // name: "id",
      name: "id.id#account_payment#partner_id",
      type: 'hidden',
    },
    {
      label: "Transaction Ref",
      table_name: "account_payment",
      // name: "id.amount#account_payment#partner_id",
      name: "trans_ref",
    },
    {
      label: "Amount",
      table_name: "account_payment",
      // name: "id.amount#account_payment#partner_id",
      name: "amount",
      transform: "currency",
    },
    {
      label: "Account",
      table_name: "res_partner",
      name: "acc_no",
    },
    {
      label: "Bill Type",
      table_name: "account_payment",
      name: "bill_description",
    },
    {
      label: "Arrears",
      table_name: "account_payment",
      name: "arrears",
      transform: "currency",
    },
    {
      label: "Payment Date",
      table_name: "account_payment",
      name: "payment_date",
      transform: "date",
    },
  ],
  opts: {
    sort: 'desc',
    order: 'payment_date',
    caller: '',
    requester: '',
  },
  limit: 100,
};

export default PostpaidPaymentModel;
