const MaxPowerToken = {
  edit: false,
  table: "res_partner",
  control: "meter_action",
  fields: [
    // {
    //   name: "meter_no",
    //   type: "select_db_auto",
    //   sort: "meter_no",
    //   fullWidth: true,
    //   table: "meter_details",
    //   value: "meter_no",
    //   field: "concat(id,' - ', meter_no)",
    //   label: "Select Meter",
    //   picks: [],
    // },
    {
      name: "field",
      type: "hidden",
      value: "",
    },
    {
      name: "value",
      type: "hidden",
      value: "",
    },
    {
      name: "kilowatt",
      type: "select",
      fullWidth: false,
      label: "Select Kilos(KWh)",
      picks: [
        { id: "0", text: "0 KWh", selected: true },
        { id: "1", text: "1 KWh", selected: false },
        { id: "2", text: "2 KWh", selected: false },
        { id: "3", text: "3 KWh", selected: false },
        { id: "4", text: "4 KWh", selected: false },
        { id: "5", text: "5 KWh", selected: false },
      ],
    },
    {
      name: "lifenotes",
      type: "text",
      validate: { required: true },
      fullWidth: true,
      label: "Reason",
    },
    {
      name: "action",
      type: "hidden",
      value: "setmaxpower",
    },
  ],
  desc: "Set Max Power",
  title: "Set Max Power Setup",
};

export default MaxPowerToken;
