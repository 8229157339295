/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { AutoBarVisualization } from "../../../components/AutoVisualization/bar.charts";
import componentStyles from "assets/theme/components/header.js";
import {
  WalletSingleModel,
  ArrearsSingleModel,
  PostpaidTransactionSingleModel,
  LastPaymentModel,
} from "../../../model/postpaid_cardstat_model";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { AutoformRenderCard } from "../../../components/AutoFormRenderCard";
import {
  DailySumTransactionByTypeModel,
  DailySumTransactionModel,
  MonthlySumTransactionByTypeModel,
  MonthlySumTransactionModel,
  WeeklySumTransactionByTypeModel,
  WeeklySumTransactionModel,
  YearlySumTransactionByTypeModel,
  YearlySumTransactionModel,
} from "../../../model/dashboard/transaction_model";
import { WeeklySumPrepaidArrearsModel } from "model/dashboard/arrears_model";
import { MonthlySumPrepaidArrearsModel } from "model/dashboard/arrears_model";
import {
  WeeklySumArrearsByCustomerCategoryModel,
  MonthlySumArrearsCustomerCategoryModel,
  YearlySumPrepaidArrearsModel,
  YearlySumArrearsCustomerCategoryModel,
  DailySumArrearsByCustomerCategoryModel,
  DailySumArrearsByCustomerClassModel,
  WeeklySumArrearsByCustomerClassModel,
  MonthlySumArrearsCustomerClassModel,
  YearlySumArrearsCustomerClassModel,
} from "../../../model/dashboard/arrears_model";
import {
  CustomersByCustomerCategoryModel,
  CustomersByCustomerClassModel,
  TotalActivePostpaidCustomersModel,
  TotalCustomersModel,
  TotalPrepaidCustomersModel,
  TotalSuspendedPostpaidCustomersModel,
  TotalUnMeteredCustomersModel,
} from "../../../model/dashboard/customers_model";
import { TotalAssignedPrepaidMetersModel } from "model/dashboard/meters_model";
import { TotalUnassignedMetersModel } from "model/dashboard/meters_model";
import { TotalAssignedPrepaidMetersByCustomerCategoryModel } from "model/dashboard/meters_model";
import { TotalAssignedPrepaidMetersByCustomerClassModel } from "model/dashboard/meters_model";

export default function MetersDashboard() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  React.useEffect(() => {}, []);

  return (
    <div>
      <div
        className={classes.header}
        style={{ height: 200, paddingTop: 15, marginBottom: 10 }}
      >
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={6} lg={6} xs={6}>
                <AutoformRenderCard
                  condition={``}
                  form={TotalAssignedPrepaidMetersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={6} lg={6} xs={6}>
                <AutoformRenderCard
                  condition={``}
                  form={TotalUnassignedMetersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              {/* <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={`'`}
                  form={TotalSuspendedPostpaidCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={`'`}
                  form={TotalUnMeteredCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid> */}
            </Grid>
          </div>
        </Container>
      </div>
      {/* Customer Category */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width:
              TotalAssignedPrepaidMetersByCustomerCategoryModel.style.width,
            height:
              TotalAssignedPrepaidMetersByCustomerCategoryModel.style.height,
            marginLeft: 5,
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization
            form={TotalAssignedPrepaidMetersByCustomerCategoryModel}
          />
        </div>
        <div
          style={{
            width: TotalAssignedPrepaidMetersByCustomerClassModel.style.width,
            height: TotalAssignedPrepaidMetersByCustomerClassModel.style.height,
            marginLeft: 5,
            marginBottom: 20,
          }}
        >
          <AutoBarVisualization
            form={TotalAssignedPrepaidMetersByCustomerClassModel}
          />
        </div>
        {/* <div
          style={{
            width: MonthlySumArrearsCustomerCategoryModel.style.width,
            height: MonthlySumArrearsCustomerCategoryModel.style.height,
            marginLeft: 5, marginBottom: 20
          }}
        >
          <AutoBarVisualization
            form={MonthlySumArrearsCustomerCategoryModel}
          />
        </div>
        <div
          style={{
            width: YearlySumArrearsCustomerCategoryModel.style.width,
            height: YearlySumArrearsCustomerCategoryModel.style.height,
            marginLeft: 5, marginBottom: 20
          }}
        >
          <AutoBarVisualization
            form={YearlySumArrearsCustomerCategoryModel}
          />
        </div> */}
      </div>
      {/* Arrears Customer Class */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: "5%",
          marginBottom: 60,
        }}
      >
        {/* <div
          style={{
            width: MonthlySumArrearsCustomerClassModel.style.width,
            height: MonthlySumArrearsCustomerClassModel.style.height,
            marginLeft: 5, marginBottom: 20
          }}
        >
          <AutoBarVisualization
            form={MonthlySumArrearsCustomerClassModel}
          />
        </div>
        <div
          style={{
            width: YearlySumArrearsCustomerClassModel.style.width,
            height: YearlySumArrearsCustomerClassModel.style.height,
            marginLeft: 5, marginBottom: 20
          }}
        >
          <AutoBarVisualization
            form={YearlySumArrearsCustomerClassModel}
          />
        </div> */}
      </div>
    </div>
  );
}
