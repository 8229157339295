import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const MoveInModel = {
  edit: true,
  editPram: { col: "acc_no" },
  respText: "Move in was successful!",
  table: "res_partner",
  fields: [
    // {name:"status",label:"Activate?.",type:"switch"},
    {
      name: "acc_no",
      type: "hidden",
      value: "",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "meter",
      type: "radio",
      label: "Now",
      checked: true,
    },
    {
      name: "meter",
      type: "text",
      label: "Meter / Location",
    },
    {
      validate: { required: true },
      name: "locid",
      type: "select_db",
      fullWidth: true,
      table: "res_district",
      field: "name",
      label: "Loc District",
      picks: [],
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Move In Create/Edit",
  title: "Move In Setup",
};

export const MoveInReadModel = [];

// const
export default MoveInModel;
