export const roles = {
  admin: "admin",
  auditors: "auditors",
  accountant: "accountant",
  metering: "metering",
  customerCare: "customercare",
  vendor: "vendor",
  haudit: "haudit",
  billing: "billing",
  feeder: "feeder",
  headbilling: "headbilling",
  officer: "officer",
  operations: "operations",
  supervisor: "supervisor",
  headacct: "headacct",
  accounts: "ACCOUNTS".toLowerCase(),
  metermgt: "METER-MGT".toLowerCase(),
  billingManager: "BILLING MANAGERS".toLowerCase(),
  prepaidOfficer: "PREPAID OFFICERS".toLowerCase(),
  ICT: "ICT".toLowerCase(),
  test: "test",
  guest: "guest",
};
