import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "assets/theme/theme.js";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "assets/css/print.css";

import Invoice from "./components/receipt/receipt";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteNotFound from "components/receipt/RouteNotFound";
//import Customers from "views/admin/Customers";
import Login from "./Login";
import { logOut } from "./utils/utils";
import VendorLogin from "VendorLogin";
import { createBrowserHistory } from "history";

//
console.log("process.env", process.env);
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />

        <Route path="/receipt"  component={() => <Invoice />} />
        {/* <Route path="/customers"  component={() => <Customers/>} /> */}
        <Route path="/customers"  component={() => <Login />} />
        <Route path="/web-login"  component={() => <Login />} />
        <Route path="/vendor-login"  component={() => <VendorLogin />} />
        <Route
          path="/web-logout"
          
          component={() => {
            logOut();
          }}
        />
        {/* <Route path="/login"  component={() => <Login/>} /> */}
        <Route
          path="/notfound/:caller/:requester"
          
          component={() => <RouteNotFound />}
        />
        {/* // <Route path="/receipt" render={<receipt/>} />
        // <Redirect from="/receipt" to="/receipt" /> */}
        <Redirect from="/" to="/web-login" />
      </Switch>
    </BrowserRouter>
    <ToastContainer />
  </ThemeProvider>,
  document.querySelector("#root")
);
