import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { generateKey } from "utils/utils";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { base64ToArrayBuffer, saveByteArray, showToast } from "../utils/utils";
// import { Refresh } from "@material-ui/icons";
// import Select from '@mui/material/Select';

export default function BulkOutputPopup({
  open,
  handleClose,
  output,
  title,
  base64,
}) {
  const [value, setValue] = React.useState("");

  React.useEffect(() => {
    // setLoading(true);
  }, []);

  const onExcel = (e) => {
    e.preventDefault();
    if (!base64) {
      //toastify here
      showToast({
        type: "error",
        message: "Excel report not availabe",
      });
      return;
    }
    saveByteArray(generateKey(), base64ToArrayBuffer(base64), ".xlsx");
  };

  // should render a popup form *
  // should render output data
  // should be closable
  const defaultErrorMessage = "Something went wrong, No data returned.";
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Grid
            key={generateKey()}
            container
            item
            xs={12}
            lg={12}
            marginTop="10px"
          >
            {output &&
              Object.keys(output).map((key) => {
                console.log("output", output, key, output[key]);
                return (
                  <>
                    <Box
                      component="form"
                      sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <div>
                        <TextField
                          label="Meter No"
                          variant="standard"
                          color="success"
                          focused
                          defaultValue={key}
                        />
                      </div>
                    </Box>
                    {(output[key] === undefined || output[key] == null) && (
                      <RenderItem
                        label={key.toUpperCase()}
                        value={defaultErrorMessage}
                      />
                    )}

                    {typeof output[key] === "string" && (
                      <RenderItem
                        label={key.toUpperCase()}
                        value={output[key]}
                      />
                    )}
                    {Array.isArray(output[key]) && output[key].length < 1 && (
                      <RenderItem
                        label={key.toUpperCase()}
                        value={defaultErrorMessage}
                      />
                    )}
                    {output[key] &&
                      Array.isArray(output[key]) &&
                      output[key].length > 0 && (
                        <>
                          <RenderItem label={key.toUpperCase()} single />
                          {Object.keys(output[key][0]).map((itemKey) => {
                            return (
                              <RenderItem
                                label={itemKey.toUpperCase()}
                                value={output[key][0][itemKey]}
                              />
                            );
                          })}
                        </>
                      )}
                    {output[key] &&
                      !Array.isArray(output[key]) &&
                      Object.keys(output[key]).map((itemKey) => {
                        return (
                          <RenderItem
                            label={itemKey.toUpperCase()}
                            value={output[key][itemKey]}
                          />
                        );
                      })}
                  </>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          {base64 && (
            <Button actionsend="cancel" onClick={onExcel}>
              Download As Excel
            </Button>
          )}
          <Button actionsend="cancel" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function RenderItem({ single, label, value, title }) {
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="standard-error"
          // label={title || "Meter no"}
          defaultValue={label}
          variant="standard"
          disabled
        />
        {!single && (
          <TextField
            id="standard-error-helper-text"
            label="Result"
            defaultValue={value || "n/a"}
            helperText="Value returned."
            variant="standard"
            disabled
          />
        )}
      </div>
    </Box>
  );
}
