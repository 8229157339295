import Header from "components/Headers/Header";
import FetchTable from "components/Tables/FetchTable";
import { ReversalsModel } from "./../../model/prepaid_reversals_model";

export function PrepaidReversals() {
  return (
    <>
      <Header />
      <FetchTable
        styles={{ marginTop: "0" }}
        settings={{
          name: "Prepaid Payment Reverlsals",
          table: ReversalsModel.table,
          limit: ReversalsModel.limit || 5,
          control: ReversalsModel.control,
          data: {
            table: ReversalsModel.table,
            data: ReversalsModel.fields,
          },
        }}
        columns={ReversalsModel.fields}
        records={[]}
        form={ReversalsModel}
      />
    </>
  );
}
