const descriptionText = `Upload file (CSV/XLSX) with a maximum of 100 rows.`;

export const BulkKeyChangeModel = Object.assign(
  {},
  {
    uploadType: `bulk_keychange`,
    accept: '".csv,.xlsx',
    descritpionText: descriptionText,
    buttonText: "Process File",
  }
);

export const BulkModel = ({ uploadType, accept, text, buttonText }) => {
  return {
    uploadType: uploadType || ``,
    accept: accept || '".csv,.xlsx',
    descritpionText: text || descriptionText,
    buttonText: buttonText || "Process File",
  };
};

export const BulkMeterModel = {
  uploadType: `bulk_meter`,
  accept: '".csv,.xlsx',
  descritpionText: descriptionText,
  buttonText: "Process File",
};

export const BulkFreeIssueModel = Object.assign(
  {},
  {
    uploadType: `bulk_freeissue`,
    accept: '".csv,.xlsx',
    descritpionText: descriptionText,
    buttonText: "Process File",
  }
);
