import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const TariffFormModel = {
  edit: false,
  table: "tariff_extention",
  uniqId: uniqId,
  fields: [
    // {name:"status",label:"Activate?.",type:"switch"},
    {
      fullWidth: true,
      validate: { required: true },
      name: "name",
      type: "text",
      label: "Tariff Name",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "rate",
      type: "number",
      label: "Tariff Rate",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "start_date",
      type: "datetime",
      label: "Start Date",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "end_date",
      type: "datetime",
      label: "End Date",
    },
  ],
  desc: "Tariff Create/Edit",
  title: "Tariff Setup",
};

export const TariffReadModel = [
  { name: "name", label: "Tariff Name." },
  { name: "rate", type: "text", label: "Rate" },
  { name: "start_date", type: "text", label: "Start Date." },
  { name: "end_date", type: "text", label: "End Date." },
];

// const
export default TariffFormModel;
