import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const AssignLocationModel = {
  edit: true,
  editPram: { col: "acc_no" },
  respText: "Update was successful!",
  table: "res_partner",
  fields: [
    { name: "acc_no", type: "hidden", value: "" },
    {
      value: "locid",
      name: "locid",
      type: "select_db_auto",
      sort: "locid",
      fullWidth: true,
      table: "prepaid_location",
      field: "locid",
      label: "Location",
      picks: [],
    },
  ],
  desc: "Assign Location",
  title: "Assign Location Setup",
};

export default AssignLocationModel;
