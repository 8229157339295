import React, { useEffect } from "react";
import PostpaidHeader from "./PostpaidHeader";
import componentStyles from "assets/theme/components/header.js";
import { Grid, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PostpaidCard from "./PostpaidCard";
import { useParams } from "react-router-dom";
import AutoForms from "../AutoForm";
import SwapMeterModel from "model/swap_meter_model";
import { triggerMenuActionController } from "../../controllers/trinity-item.controller";
import AutoformRenderInput from "../AutoformRenderInput";
import PopupFetchTable from "../PopupFetchTable";
import VendAccountModel, {
  CashVendAccountModel,
} from "../../model/vend_account";
import { generateKey, prepareParamCaller } from "../../utils/utils";
import { PreviousArrearsModel } from "model/autoform_renderinput_model";
import PostpaidPaymentModel from "../../model/transactions/postpaid_transactions_model";
import FetchTable from "../../components/Tables/FetchTable";
import "assets/css/tab.css";
import WalletActivitiesModel from "../../model/wallet_model";
import GeneratedBillsModel from "../../model/generated_bill_wallet_model";

const PostpaidItem = () => {
  const useStyles = makeStyles(componentStyles);

  const classes = useStyles();
  const [refresh, setRefresh] = React.useState(false);
  const { caller, requester } = useParams();

  const _caller = prepareParamCaller(caller);
  //console.log(caller);
  let trinities = [];

  const [showMenu, setShowMenu] = React.useState(false);
  const [callerAction, setCallerAction] = React.useState("");
  const [formModel, setFormModel] = React.useState(SwapMeterModel);
  const [showArrears, setShowArrears] = React.useState(false);
  const [showWalletActivies, setShowWalletActivies] = React.useState(false);
  const [showVend, setShowVend] = React.useState(false);
  const [vendType, setVendType] = React.useState("");

  switch (caller) {
    case "meter":
      trinities = [
        {
          caller: caller,
          requester: requester,
          title: "PostpaidMeter",
          controls: [
            {
              name: "Change SGC Code",
              action: () => {
                triggerMenuActionController(
                  "change-sgc-code",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Change Tariff Index",
              action: () => {
                triggerMenuActionController(
                  "change-tariff-index",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Remove Meter",
              action: () => {
                triggerMenuActionController(
                  "remove-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Set Max Power",
              action: () => {
                triggerMenuActionController(
                  "max-power",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Free Issue",
              action: () => {
                triggerMenuActionController(
                  "free-issue",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Vend",
              action: () => {
                triggerMenuActionController(
                  "vend",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Clear Credit Token",
              action: () => {
                triggerMenuActionController(
                  "clear-credit-token",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Tamper Token",
              action: () => {
                triggerMenuActionController(
                  "tamper-token",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            //{ name: "Transactions", action: null },
            {
              name: "Key Change",
              action: () => {
                triggerMenuActionController(
                  "key-change",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
          ],
        },
        {
          caller: caller,
          requester: requester,
          title: "Electricity",
          controls: [
            // {
            //   name: "Adding a Tariff",
            //   action: () => {
            //     triggerMenuActionController(
            //       "add-tariff",
            //       setShowMenu,
            //       setFormModel
            //     );
            //   },
            // },
            {
              name: "Swap Meter",
              action: () => {
                triggerMenuActionController(
                  "swap-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Remove Meter",
              action: () => {
                triggerMenuActionController(
                  "remove-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Install Meter",
              action: () => {
                triggerMenuActionController(
                  "assign-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Update Seal",
              action: () => {
                triggerMenuActionController(
                  "update-seal",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
          ],
        },
        {
          caller: caller,
          requester: requester,
          title: "PostpaidAccount",
          controls: [
            // { name: "Activate 4 Vending", action: () => {
            //   triggerMenuActionController(
            //     "activate-vending",
            //     setShowMenu,
            //     setFormModel,
            //     requester,caller
            //   );
            // } },
            // {
            //   name: "Add Location",
            //   action: () => {
            //     triggerMenuActionController(
            //       "add-location",
            //       setShowMenu,
            //       setFormModel,
            //       requester,
            //       caller
            //     );
            //   },
            // },
            {
              name: "Assign Meter",
              action: () => {
                triggerMenuActionController(
                  "assign-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Wallets Activites",
              action: () => setShowWalletActivies(!showWalletActivies),
            },
            {
              name: "Arrears Lookup",
              action: () => setShowArrears(!showArrears),
            },
            {
              name: "Add Tariff",
              action: () => {
                triggerMenuActionController(
                  "add-tarrif",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Move Out",
              action: () => {
                triggerMenuActionController(
                  "move-out",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Move IN",
              action: () => {
                triggerMenuActionController(
                  "move-in",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
          ],
        },
      ];

      break;
    default:
      trinities = [
        {
          caller: caller,
          requester: requester,
          title: "PostpaidAccount",
          controls: [
            // { name: "Activate 4 Vending", action: () => {
            //   triggerMenuActionController(
            //     "activate-vending",
            //     setShowMenu,
            //     setFormModel,
            //     requester,caller
            //   );
            // } },
            // {
            //   name: "Add Location",
            //   action: () => {
            //     triggerMenuActionController(
            //       "assign-location",
            //       setShowMenu,
            //       setFormModel,
            //       requester,
            //       caller
            //     );
            //   },
            // },
            {
              name: "Assign Meter",
              action: () => {
                triggerMenuActionController(
                  "assign-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Wallets Activites",
              action: () => setShowWalletActivies(!showWalletActivies),
            },
            {
              name: "Arrears Lookup",
              action: () => setShowArrears(!showArrears),
            },
            {
              name: "Add Tariff",
              action: () => {
                triggerMenuActionController(
                  "add-tariff",
                  setShowMenu,
                  setFormModel
                );
              },
            },
            // {
            //   name: "Payment Mode",
            //   action: () => {
            //     triggerMenuActionController(
            //       "payment-mode",
            //       setShowMenu,
            //       setFormModel,
            //       requester,caller
            //     );
            //   },
            // },
            {
              name: "Vending Control",
              action: () => {
                triggerMenuActionController(
                  "vending-control",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Move Out",
              action: () => {
                triggerMenuActionController(
                  "move-out",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Move In",
              action: () => {
                triggerMenuActionController(
                  "move-in",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
          ],
        },
        {
          caller: caller,
          requester: requester,
          title: "Electricity",
          controls: [
            // {
            //   name: "Adding a Tariff",
            //   action: () => {
            //     triggerMenuActionController(
            //       "add-tariff",
            //       setShowMenu,
            //       setFormModel
            //     );
            //   },
            // },
            {
              name: "Swap Meter",
              action: () => {
                triggerMenuActionController(
                  "swap-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Remove Meter",
              action: () => {
                triggerMenuActionController(
                  "remove-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Install Meter",
              action: () => {
                triggerMenuActionController(
                  "assign-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Update Seal",
              action: () => {
                triggerMenuActionController(
                  "update-seal",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
          ],
        },
        {
          caller: caller,
          requester: requester,
          title: "PostpaidMeter",
          controls: [
            {
              name: "Change SGC Code",
              action: () => {
                triggerMenuActionController(
                  "change-sgc-code",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Change Tariff Index",
              action: () => {
                triggerMenuActionController(
                  "change-tariff-index",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Remove Meter",
              action: () => {
                triggerMenuActionController(
                  "remove-meter",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Set Max Power",
              action: () => {
                triggerMenuActionController(
                  "max-power",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Free Issue",
              action: () => {
                triggerMenuActionController(
                  "free-issue",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Vend",
              action: () => {
                triggerMenuActionController(
                  "vend",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Clear Credit Token",
              action: () => {
                triggerMenuActionController(
                  "clear-credit-token",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Tamper Token",
              action: () => {
                triggerMenuActionController(
                  "tamper-token",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            {
              name: "Key Change",
              action: () => {
                triggerMenuActionController(
                  "key-change",
                  setShowMenu,
                  setFormModel,
                  requester,
                  caller
                );
              },
            },
            //{ name: "Transactions", action: null },
          ],
        },
      ];
      break;
  }

  const getBillStatus = async () => {};

  useEffect(() => {
    getBillStatus();
  }, []);
  
  PostpaidPaymentModel.opts.caller = _caller;
  PostpaidPaymentModel.opts.requester = requester;

  return (
    <>
      <PostpaidHeader caller={caller} requester={requester} />
      <Container
        maxWidth={false}
        component={Box}
        marginTop="-6rem"
        classes={{ root: classes.containerRoot }}
      >
        <Grid container>
          {/* <Button
              onClick={(e) => {
                onTriggerVend(e, "cash");
              }}
              style={{marginBottom: 20, background: 'white', marginLeft: 20}}
            >
              Cash Vend
          </Button>
           */}
        </Grid>
        <Grid container>
          {trinities.map((item) => {
            return (
              <PostpaidCard
                key={generateKey()}
                opt={item}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            );
          })}
        </Grid>
        <AutoForms
          key={10001}
          keyId={156001}
          form={formModel}
          cancel={setShowMenu}
          open={showMenu}
          submit={() => {
            setRefresh(!refresh);
          }}
        />
        <AutoformRenderInput
          fullWidth
          handleChange={(e) => {}}
          handleClose={() => setShowArrears(false)}
          open={showArrears}
          keyId={120001}
          key={291}
          // label={'Arrears Lookup'}
          condition={`${prepareParamCaller(caller)}='${requester}'`}
          form={PreviousArrearsModel}
        />
        <PopupFetchTable
          handleClose={() => setShowWalletActivies(false)}
          open={showWalletActivies}
          keyId={12001}
          key={21}
          title={`${requester}'s Wallet Activities`}
        />
        <AutoForms
          key={10}
          keyId={101567}
          form={vendType === "wallet" ? VendAccountModel : CashVendAccountModel}
          cancel={setShowVend}
          open={showVend}
          submit={(e) => {}}
        />

        <br />
        <div class="tabPanel-widget">
          <label for="tab-1" tabindex="0" style={{ width: "30%" }}></label>
          <input
            id="tab-1"
            type="radio"
            name="tabs"
            checked="true"
            aria-hidden="true"
          />
          <h2 style={{ width: "30%" }}>Payment Transactions </h2>
          <div>
            <FetchTable
              styles={{ marginTop: "0" }}
              settings={{
                name: "Payment Activities",
                // table: PostpaidPaymentModel.table,
                //where: `${_caller}='${requester}'`,
                limit: PostpaidPaymentModel.limit,
                control: PostpaidPaymentModel.control,
                data: {
                  caller: _caller,
                  requester,
                },
              }}
              columns={PostpaidPaymentModel.fields}
              records={[]}
              form={PostpaidPaymentModel}
            />
          </div>
          <label
            for="tab-2"
            tabindex="1"
            style={{ width: "30%", marginLeft: "18%" }}
          ></label>
          <input id="tab-2" type="radio" name="tabs" aria-hidden="true" />
          <h2 style={{ width: "30%", marginLeft: "18%" }}>Generated Bills</h2>
          <div>
            <FetchTable
              styles={{ marginTop: "0" }}
              settings={{
                name: "Generated Bills",
                table: GeneratedBillsModel.table,
                // where: `${prepareParamCaller(caller)}='${requester}'`,
                limit: GeneratedBillsModel?.limit,
                control: GeneratedBillsModel.control,
                data: {
                  caller: _caller,
                  requester,
                },
                rowAction: GeneratedBillsModel.action.component,
              }}
              columns={GeneratedBillsModel.fields}
              records={[]}
              form={GeneratedBillsModel}
            />
          </div>
          <label
            for="tab-3"
            tabindex="3"
            style={{ width: "30%", marginLeft: "36%" }}
          ></label>
          <input id="tab-3" type="radio" name="tabs" aria-hidden="true" />
          <h2 style={{ width: "30%", marginLeft: "36%" }}>
            Wallet Transactions{" "}
          </h2>
          <div>
            <FetchTable
              styles={{ marginTop: "0" }}
              settings={{
                name: "Wallet Activities",
                table: "",
                where: `${caller}='${requester}'`,
                control: WalletActivitiesModel.control,
                data: {
                  caller,
                  requester,
                },
                limit: 100,
              }}
              columns={WalletActivitiesModel.fields}
              records={[]}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default PostpaidItem;
