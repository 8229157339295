/* eslint-disable unused-imports/no-unused-imports */
import { UniqueCode } from "utils/events";
import DownloadDoneOutlined from "@mui/icons-material/DownloadDoneOutlined";
import { DownloadRounded } from "@mui/icons-material";

const uniqId = `L${UniqueCode(1000000)}`;
const AuditModel = {
  control: "audit",
  fields: [
    {
      name: "name",
      label: "Name.",
    },
    { name: "source", type: "text", label: "Audit Source" },
    { name: "action", type: "text", label: "Audit Action" },
    { name: "timestamp", transform: "datetime", label: "Date" },
  ],
  fabs: {
    downloadReport: {
      icon: <DownloadRounded />,
      onClick: () => {},
    },
  },
};

// const
export default AuditModel;
