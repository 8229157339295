import AutoForms from "./../../components/AutoForm";
import { useState } from "react";
import { UniqueCode } from "utils/events";
import TransactionReversalForm from "./transaction_reversal_form";
import { httpRequest } from "./../../utils/apis";
import { showToast, urls } from "./../../utils/utils";

const uniqId = `L${UniqueCode(1000000)}`;
export const handleReversalAction = ({ formData, data }) => {
  if (formData.actionType && formData.actionType === 'internal-reversal') {
    let transactionRef = '';
    if (formData.opts && formData.opts.meteringType == "prepaid") {
      transactionRef = data.transaction;
    } else if (formData.opts && formData.opts.meteringType == "postpaid") {
      transactionRef = data.trans_ref;
    }
    httpRequest({
      url: urls.internalTransactionReversal, data: {
        'transactionRef': transactionRef,
        type: formData.opts ? formData.opts.meteringType : '',
        actionType: formData.actionType,
        reason: formData.reason,
      }, method: 'post'
    }).then(result => {
      if (result && result.status) {
        showToast({
          message: result.message || 'Transaction reversed.',
          type: 'success',
        })
      } else {
        showToast({
          message: result.message || 'Transaction failed to be reversed. please try again.',
          type: 'error',
        })
      }
    });
  } else {
    httpRequest({
      url: urls.transactionReversal, data: {
        'transactionRef': data.transaction,
        type: 'prepaid',
        actionType: formData.actionType,
        reason: formData.reason,
      }, method: 'post'
    }).then(result => {
      if (result && result.status) {
        showToast({
          message: result.message || 'Transaction reversed.',
          type: 'success',
        })
      } else {
        showToast({
          message: result.message || 'Transaction failed to be reversed. please try again.',
          type: 'error',
        })
      }
    });
  }
}
const TransactionTreeModel = {
  control: "prepaid_transaction_table",
  table: "",
  fields: [
    {
      name: "transaction",
      label: "Transaction",
    },
    {
      name: "type_t",
      label: "Transaction Type",
    },
    { name: "amount", type: "text", label: "Amount", transform: 'currency' },
    { name: "units", type: "text", label: "Units", transform: 'toFixed' },
    { name: "ststoken", type: "text", label: "STS Token" },
    { name: "serialnumber", type: "text", label: "Serial Number" },
    { name: "token_type", type: "text", label: "Type" },
    { name: "arrears", type: "text", label: "Arrears", transform: 'currency' },
    { name: "vat", type: "text", label: "VAT", transform: 'currency' },
    { name: "created", type: "text", label: "Created on" },

    //{ name: "created", type: "hidden", label: "Created on" },

    // { name: "wallet_balance", type: "text", label: "Wallet Balance" },
  ],
  action: {
    component: [
      ({ data, history }) => {
        const [showGrid, setShowGrid] = useState(false);
        return (
          <>
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setShowGrid(true);
              }}
            >
              Reverse Transaction
            </span>
            {
              showGrid &&
              <AutoForms
                key={10001}
                keyId={156001}
                form={TransactionReversalForm}
                cancel={setShowGrid}
                open={showGrid}
                submit={({ formData }) => {
                  formData.opts = {
                    meteringType: 'prepaid',
                  }
                  console.log('TransactionReversalForm', formData, data);
                  // make api call here and handle
                  if (!formData.actionType || formData.actionType === '') {
                    showToast({
                      message: 'Please select action type.',
                      type: 'error',
                    })
                    return;
                  }
                  showToast({
                    message: 'Reversing Transaction, please wait.',
                    type: 'success',
                  })
                  handleReversalAction({ formData: formData, data: data });
                }}
              />
            }
          </>

        );
      },
    ],
  },
  opts: {
    sort: "desc",
    order: "id",
  },
};

// const
export default TransactionTreeModel;
