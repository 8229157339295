import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
// import { VendorsModel } from "../../model/vendors/vendors_model";
import React from "react";
import { useHistory } from "react-router-dom";
import { InternalVendorsModel } from "./../../model/vendors/internal_vendors_model";
import { showToast } from "utils/utils";
import Controller from "./../../utils/controls";
import { saveByteArray } from "utils/utils";
import { base64ToArrayBuffer } from "utils/utils";

export default function InternalVendors() {
  // const [datas,setDatas] = React.useState([])
  const history = useHistory();

  React.useEffect(() => {
    InternalVendorsModel.fabs.downloadReport.onClick = () => {
      showToast({ message: "Downloading report...", type: "success" });
      Controller({
        control: InternalVendorsModel.control,
        data: { report: 1 }
      }).then(response => {
        if (response.status) {
          saveByteArray(
            response.data.fileName,
            base64ToArrayBuffer(response.data.base64),
            response.data.extension
          );
        } else {
          showToast({
            message: response.message || "An error occurred",
            type: "error",
          });
        }
      })
    };
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Vendors",
          table: InternalVendorsModel.table,
          control: InternalVendorsModel.control,
        }}
        columns={InternalVendorsModel.fields}
        records={[]}
        form={InternalVendorsModel}
      />
    </>
  );
}
