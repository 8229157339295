import React from "react";
import FetchTable from "../../components/Tables/FetchTable";
import Header from "../../components/Headers/Header";
import AccountFormModel from "../../model/account_model";
import AccountReadModel from "./../../model/account_fetch_model";
import AutoForms from "../../components/AutoForm";
// import { getParam } from "utils/utils";

export default function AccountTree() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);

  React.useEffect(() => {}, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Account Details",
          table: "res_partner",
          add: setAddAccount,
          type: "prepaid",
        }}
        columns={AccountReadModel.fields}
        records={[]}
        form={AccountReadModel}
      />

      <AutoForms
        key={0}
        keyId={1567}
        form={AccountFormModel}
        cancel={setAddAccount}
        open={addaccount}
      />
    </>
  );
}

export function PostpaidAccountTree() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);

  React.useEffect(() => {}, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Account Details",
          table: "res_partner",
          add: setAddAccount,
          type: "postpaid",
        }}
        columns={AccountReadModel.fields}
        records={[]}
        form={AccountReadModel}
      />

      <AutoForms
        key={0}
        keyId={1567}
        form={AccountFormModel}
        cancel={setAddAccount}
        open={addaccount}
      />
    </>
  );
}
