import { Grid } from "@material-ui/core";
import { FormControl, InputLabel, Checkbox } from "@material-ui/core";
import { useState } from "react";

export const AutoformCheckbox = ({ field, key, handleChange }) => {
  const [checked, setChecked] = useState(false);

  return (
    <Grid item xl={3} lg={6} xs={12}>
      <FormControl focused={true} fullWidth={field.fullWidth}>
        <>
          <InputLabel key={`${key}label`} id={`select_label_${field.name}`}>
            {field.label}
          </InputLabel>
          <Checkbox
            name={field.name}
            value={field.value}
            checked={checked}
            onClick={(evt) => {
              console.log("evt", evt);
              setChecked(!checked);
              handleChange && handleChange(evt, evt.target.value);
            }}
          />
        </>
      </FormControl>
    </Grid>
  );
};
