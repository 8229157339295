/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import { AutoBarVisualization } from "../../../components/AutoVisualization/bar.charts";
import componentStyles from "assets/theme/components/header.js";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { AutoformRenderCard } from "../../../components/AutoFormRenderCard";
import {
  ActivePostpaidCustomersByServiceCenterModel,
  CustomersByCustomerCategoryModel,
  // CustomersByCustomerClassModel,
  PrepaidCustomersByServiceCenterModel,
  SuspendedPostpaidCustomersByServiceCenterModel,
  TotalActivePostpaidCustomersModel,
  TotalPrepaidCustomersModel,
  TotalSuspendedPostpaidCustomersModel,
  TotalUnMeteredCustomersModel,
} from "./../../../model/dashboard/customers_model";
import { AutoPieVisualizationBinary } from "components/AutoVisualization/pie.charts";

export default function CustomersDashboard() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const useStyles = makeStyles(componentStyles);
  const classes = useStyles();

  React.useEffect(() => { }, []);

  return (
    <div>
      <div
        className={classes.header}
        style={{ height: 200, paddingTop: 15, marginBottom: 10 }}
      >
        <Container
          maxWidth={false}
          component={Box}
          classes={{ root: classes.containerRoot }}
        >
          <div>
            <Grid container>
              <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={``}
                  form={TotalPrepaidCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={``}
                  form={TotalActivePostpaidCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={`'`}
                  form={TotalSuspendedPostpaidCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
              <Grid item xl={3} lg={3} xs={6}>
                <AutoformRenderCard
                  condition={`'`}
                  form={TotalUnMeteredCustomersModel}
                  prefix={""}
                  key={new Date().toLocaleTimeString()}
                />
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 20,
          justifyContent: 'center',
          marginLeft: '10%',
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "5%",
            marginBottom: 60,
          }}
        >
          <div
            style={{
              width: PrepaidCustomersByServiceCenterModel.style.width,
              height: PrepaidCustomersByServiceCenterModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={PrepaidCustomersByServiceCenterModel} />
          </div>
        </div>
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "5%",
            marginBottom: 60,
          }}
        >
          <div
            style={{
              width: ActivePostpaidCustomersByServiceCenterModel.style.width,
              height: ActivePostpaidCustomersByServiceCenterModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={ActivePostpaidCustomersByServiceCenterModel} />
          </div>
        </div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            // marginLeft: "5%",
            marginBottom: 60,
          }}
        >
          <div
            style={{
              width: SuspendedPostpaidCustomersByServiceCenterModel.style.width,
              height: SuspendedPostpaidCustomersByServiceCenterModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoPieVisualizationBinary form={SuspendedPostpaidCustomersByServiceCenterModel} />
          </div>
        </div> */}
        
        {/* Customer Category */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 60,
            justifyContent: 'space-between',
            maxWidth: '80%'
          }}
        >
          {/* <div
            style={{
              width: CustomersByCustomerClassModel.style.width,
              height: CustomersByCustomerClassModel.style.height,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={CustomersByCustomerClassModel} />
          </div> */}
          <div
            style={{
              width: CustomersByCustomerCategoryModel.style.width,
              height: CustomersByCustomerCategoryModel.style.height,
              marginLeft: 5,
              marginBottom: 20,
            }}
          >
            <AutoBarVisualization form={CustomersByCustomerCategoryModel} />
          </div>
        </div>
      </div>
    </div>
  );
}
