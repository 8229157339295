const MeterReadModel = {
  fields: [
    {
      name: "meter_no",
      type: "selflink",
      link: "/admin/trinity/meter/",
      label: "Meter No.",
    },
    { name: "communication", type: "text", label: "Communication" },
    { name: "sgc", type: "text", label: "SGC Code" },
    { name: "sts_ti", type: "text", label: "STS TI" },
    { name: "units", type: "text", label: "Units" },
  ],
  opts: {
    sort: "desc",
    order: "id",
  },
};
export default MeterReadModel;
