import React from "react";
import FetchTable from "../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
//import TransactionTreeModel from "../../model/transactions/transactions_model";
import { useParams } from "react-router-dom";
import { prepareParamCaller } from "../../utils/utils";
import WalletActivitiesModel, {
  WalletActivitiesReadModel,
} from "../../model/wallet_model";

function AccountTransactionTree() {
  const [datas, setDatas] = React.useState([]);
  let { caller, requester } = useParams();

  caller = prepareParamCaller(caller);
  React.useEffect(() => {}, [datas]);
  if (caller && requester) {
    return (
      <>
        <Header />
        <FetchTable
          settings={{
            name: "Wallet Activities",
            add: () => {},
            table: "",
            where: `${caller}='${requester}'`,
            control: WalletActivitiesModel.control,
            data: {
              caller,
              requester,
            },
          }}
          columns={WalletActivitiesModel.fields}
          records={[]}
        />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <FetchTable
          settings={{
            name: "Wallet Activities",
            add: () => {},
            table: WalletActivitiesReadModel.table,
            // control: WalletActivitiesReadModel.control,
          }}
          columns={WalletActivitiesReadModel.fields}
          records={[]}
        />
      </>
    );
  }
}

export default AccountTransactionTree;
