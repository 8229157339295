import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const SwapMeterModel = {
  edit: true,
  editPram: { col: "locid" },
  respText: "Update was successful!",
  table: "res_partner",
  uniqId: uniqId,
  fields: [
    {
      name: "locid",
      type: "hidden",
      value: "",
    },
    // {name:"status",label:"Activate?.",type:"switch"},
    {
      fullWidth: true,
      validate: { required: true },
      name: "meter_no",
      type: "text",
      label: "Meter",
    },
    {
      fullWidth: true,
      validate: { required: true },
      name: "seal",
      type: "text",
      label: "New Seal",
    },
    {
      name: "installedby",
      type: "text",
      label: "Installed By",
    },
    {
      // name: "arrears",
      name: "new_arrears",
      type: "select",
      fullWidth: false,
      label: "Add arrears for",
      picks: [
        { id: "0", text: "0 KWh", selected: true },
        { id: "1", text: "1 KWh", selected: false },
        { id: "2", text: "2 KWh", selected: false },
        { id: "3", text: "3 KWh", selected: false },
        { id: "4", text: "4 KWh", selected: false },
        { id: "5", text: "5 KWh", selected: false },
      ],
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Swap Meter Create/Edit",
  title: "Swap Meter Setup",
};

export const SwapMeterReadModel = [];

// const
export default SwapMeterModel;
