import { DownloadRounded } from "@mui/icons-material";
import { baseHost } from "utils/apis";
import { urls } from "../../utils/utils";

// request object contains the info to fetch data from an api and auto render

export const VendorsTransactionsModel = Object.assign(
  {},
  {
    control: "vendor_transaction_http_action",
    request: {
      url: urls.vendorTransaction,
      method: "get",
      baseUrl: baseHost + "/",
    },
    fabs: {
      downloadReport: {
        icon: <DownloadRounded />,
        onClick: () => {},
      },
    },
    action: {
      component: [],
    },
    fields: [
      {
        name: "account_number",
        label: "Account Number",
      },
      {
        name: "amount",
        label: "Amount",
      },
      {
        name: "revenue_earned",
        label: "Commision Earned",
        transform: 'currency',
      },
      {
        name: "cummulative_total",
        label: "Cummulative Total",
      },
      {
        name: "bill_type",
        label: "Bill Type",
      },
      {
        name: "vendor",
        label: "Vendor",
      },
      {
        label: "Token",
        name: "token",
      },
      {
        name: "purpose",
        label: "Purpose",
      },
      {
        label: "Arrears Paid",
        name: "arrears",
        transform: "currency",
      },
      {
        name: "trans_date",
        label: "Transaction Date",
        transform: "date",
      },
    ],
  }
);
