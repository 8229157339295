import { baseHost } from "utils/apis";
import { urls } from "../../utils/utils";

// request object contains the info to fetch data from an api and auto render

export const ListLocalVendorsModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: urls.localListVendor,
      method: "get",
      baseUrl: baseHost + "/",
    },
    table: "",
    fields: [
      {
        name: "username",
        label: "Username",
      },
      {
        name: "limitamount",
        label: "Limit Amount",
      },
      {
        name: "usertype",
        label: "Vendor Type",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        label: "Created By",
        name: "createdby",
      },
      {
        label: "Finalized By",
        name: "finalizedby",
      },
      {
        name: "timestamp",
        label: "Created on",
        transform: "date",
      },
      {
        name: "finalizedon",
        label: "Finalized on",
        transform: "date",
      },
    ],
  }
);
