/* eslint-disable unused-imports/no-unused-imports */
import { Add } from "@material-ui/icons";
import { httpRequest } from "utils/apis";
import { baseHost } from "utils/apis";
import { showToast, urls } from "./../../utils/utils";

// request object contains the info to fetch data from an api and auto render

export const PendingVendorsModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: urls.pendingVendors,
      method: "get",
      baseUrl: baseHost + "/",
    },
    fabs: {
      // addVendor: {
      //   icon: <Add style={{visibility: 'hidden'}} />,
      //   onClick: () => {},
      // },
    },
    action: {
      component: [
        ({ data }) => {
          return (
            <span
              style={{ color: "green", cursor: "pointer" }}
              onClick={() => {
                showToast({
                  type: "success",
                  message: "Approving vendor account.",
                });
                httpRequest({
                  url: urls.actionOnNewVendor,
                  method: "post",
                  baseUrl: baseHost + "/",
                  data: {
                    id: data?.id,
                    action: "approve",
                  },
                }).then((response) => {
                  //console.log(response);
                  if (response?.status) {
                    showToast({
                      type: "success",
                      message: response?.message || "Action successful.",
                    });
                    window.setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  } else {
                    showToast({
                      type: "error",
                      message: response?.message || "An error occurred.",
                    });
                  }
                });
              }}
            >
              Approve{" "}
            </span>
          );
        },
        ({ data }) => {
          return (
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                showToast({
                  type: "success",
                  message: "Rejecting vendor account.",
                });
                httpRequest({
                  url: urls.actionOnNewVendor,
                  method: "post",
                  baseUrl: baseHost + "/",
                  data: {
                    id: data?.id,
                    action: "reject",
                  },
                }).then((response) => {
                  //console.log(response);
                  if (response?.status) {
                    showToast({
                      type: "success",
                      message: response?.message || "Action successful.",
                    });
                    window.setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  } else {
                    showToast({
                      type: "error",
                      message: response?.message || "An error occurred.",
                    });
                  }
                });
              }}
            >
              Reject{" "}
            </span>
          );
        },
      ],
    },
    table: "",
    fields: [
      {
        name: "username",
        label: "Username",
      },
      {
        name: "limitamount",
        label: "Limit Amount",
      },
      {
        name: "usertype",
        label: "Vendor Type",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        label: "Created By",
        name: "createdby",
      },
      {
        name: "timestamp",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
