/* eslint-disable unused-imports/no-unused-imports */
import { UniqueCode } from "utils/events";
import DownloadDoneOutlined from "@mui/icons-material/DownloadDoneOutlined";
import { DownloadRounded } from "@mui/icons-material";

const uniqId = `L${UniqueCode(1000000)}`;
export const AdjustmentModel = {
  control: "postpaid_adjustment",
  table: "feeder_customer_details",
  fields: [
    {
      field_name: "name",
      co_name: "name",
      name: "customer_ids.acc_no#res_partner#id",
      label: "Acc No.",
    },
    {
      name: "adjustment",
      table_name: "feeder_customer_details",
      label: "Adjustment",
    },
    {
      name: "create_date",
      transform: "datetime",
      label: "Date Created",
      table_name: "feeder_customer_details",
    },
  ],
  fabs: {
    downloadReport: {
      icon: <DownloadRounded />,
      onClick: () => {},
    },
  },
};
