import React from "react";
import FetchTable from "../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
import TransactionTreeModel from "../../model/transactions/transactions_model";
import PostpaidPaymentModel from "../../model/transactions/postpaid_transactions_model";

export default function TransactionTree() {
  const [datas, setDatas] = React.useState([]);
  const [addTariff, setAddTariff] = React.useState(false);

  React.useEffect(() => {}, [datas]);
  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Transactions",
          add: () => {},
          table: TransactionTreeModel.table,
          control: TransactionTreeModel.control,
        }}
        columns={TransactionTreeModel.fields}
        records={[]}
        form={TransactionTreeModel}
      />
    </>
  );
}

export function PostpaidTransactionTree() {
  PostpaidPaymentModel.opts = undefined;
  
  const [datas, setDatas] = React.useState([]);
  const [form, setForm] = React.useState(PostpaidPaymentModel);

  React.useEffect(() => {}, []);
  
  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Transactions",
          add: () => {},
          table: form.table,
          control: form.control,
        }}
        columns={form.fields}
        records={[]}
        form={form}
      />
    </>
  );
}
