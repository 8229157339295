import { useTheme } from "@material-ui/core/styles";
import { Box, makeStyles } from "@material-ui/core";
import { ListAltOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CardStats from "./Cards/CardStats";
import componentStyles from "assets/theme/components/header.js";
import { QueryTableWhere } from "../utils/apis";
import { prepareValue, showToast } from "../utils/utils";
import Controller from "../utils/controls";
import { useParams } from "react-router-dom";
import fetchRecords from "../utils/fetch";

const useStyles = makeStyles(componentStyles);

export function AutoformRenderCard({ condition, prefix, form }) {
  const [value, setValue] = useState(form.placeholder);
  const theme = useTheme();
  const { caller, requester } = useParams();
  const [limit, setLimit] = useState(form.limit || 1);
  const [footerValue, setFooterValue] = useState(form.footerText);

  useEffect(() => {
    // fetchData();
    loadData();
  }, []);

  //console.log("opts form", form?.opts);

  const fetchData = async () => {
    if (form.control) {
      console.log("http_action", form.control, form, form?.request?.url);
      switch (form.control) {
        case "http_action":
          return await Controller({
            control: form?.control,
            url: form?.request?.url,
            data: form?.fields,
            method: form?.request?.method,
            baseUrl: form?.request?.baseUrl,
            // caller: caller,
            // requester: requester,
          });
        default:
          return await Controller({
            control: form.control,
            caller: caller,
            requester: requester,
            limit: form.limit || 1,
            opts: form?.opts,
            data: form?.fields,
          }).then((data) => {
            return data.data;
          });
      }
    } else {
      console.log("here");
      if (form.fields) {
        if (caller && requester) {
          return await fetchRecords(form.fields, form.table, form?.where || condition, limit, {
            caller,
            requester,
            ...form?.opts
          });
        } else {
          return await fetchRecords(form.fields, form.table, form?.where || condition, limit, form?.opts);
        }
      } else {
        console.log("opts form?.opts", form?.opts);
        return await QueryTableWhere(form.table, form.field, form?.where || condition, limit, form?.opts);
      }
    }
  };

  const loadData = () => {
    fetchData()
      .then((recs) => {
        if (recs) {
          if (Array.isArray(recs) && recs.length > 0) {
            if (recs[0][form.field]) {
              const _value = recs[0][form.field];
              setValue(prepareValue(form.transform, _value));
            }
            if (form.footerTextField && recs[0][form.footerTextField]) {
              setFooterValue(
                prepareValue(
                  form.footerTextTransform,
                  recs[0][form.footerTextField]
                )
              );
            }
          } else {
            if (recs && recs.status) {
              if (Array.isArray(recs.data) && recs.data.length > 0) {
                if (recs.data[0][form.field]) {
                  const _value = recs.data[0][form.field];
                  setValue(prepareValue(form.transform, _value));
                }
                if (
                  form.footerTextField &&
                  recs.data[0][form.footerTextField]
                ) {
                  setFooterValue(
                    prepareValue(
                      form.footerTextTransform,
                      recs.data[0][form.footerTextField]
                    )
                  );
                }
              } else {
                if (recs.data[form.field]) {
                  const _value = recs.data[form.field];
                  setValue(prepareValue(form.transform, _value));
                }
                if (form.footerTextField && recs.data[form.footerTextField]) {
                  setFooterValue(
                    prepareValue(
                      form.footerTextTransform,
                      recs.data[form.footerTextField]
                    )
                  );
                }
              }
            } else {
              showToast({
                type: "error",
                message: recs.message || "No data to display.",
              });
            }
            console.log("recs", recs);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <CardStats
      subtitle={form.title}
      title={prefix ? prefix + value : value}
      icon={form.icon}
      color={form.color}
      footer={
        <>
          <Box
            component="span"
            fontSize=".875rem"
            color={theme.palette.error.main}
            marginRight=".5rem"
            display="flex"
            alignItems="center"
          >
            <Box
              component={ListAltOutlined}
              width="1.5rem!important"
              height="1.5rem!important"
            />{" "}
          </Box>
          <Box component="span" whiteSpace="nowrap">
            {form.footerTextField ? <>{footerValue}</> : <>{form.footerText}</>}
          </Box>
        </>
      }
    />
  );
}
