let system = "portal";
export default class EventsControl {}
export function UniqueCode(len = 1000000000) {
  let unique = true;
  let codeValue = Math.floor(Math.random() * len);
  // while(unique)
  // {
  // await model.findOne({column: codeValue})
  // .then(data =>{
  //     if(!data)
  //         unique = false
  // })
  // .catch(err =>{
  //     console.log("Error Occured in UniqueCode ${err}");
  //     unique = false
  // })
  // }
  return codeValue;
}
