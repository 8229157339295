import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { base64ToArrayBuffer, saveByteArray } from "../../utils/utils";
import FetchTable from "components/Tables/FetchTable";
import { PrepaidReportModel } from "./../../model/prepaid_report_model";
import Controller from "./../../utils/controls";
//import { toast } from "react-toastify";

function PrepaidReportTable() {
  const [account, setAccount] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [useDistrict, setUseDistrict] = useState(false);
  const [fileName, setFileName] = useState("");

  let checkboxRef = useRef();

  useEffect(() => {
    PrepaidReportModel.fabs.downloadReport.onClick = () => {
      // make request to generate report
      Controller({
        url: PrepaidReportModel.request.url + "?report=1",
        baseUrl: PrepaidReportModel.request.baseUrl,
        method: PrepaidReportModel.request.method,
        control: "http_action",
      }).then((response) => {
        console.log("response", response);
        if (response.status) {
          saveByteArray(
            response.data.fileName,
            base64ToArrayBuffer(response.data.base64),
            ".xlsx"
          );
        }
      });
    };
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Prepaid Report",
          table: "",
          control: PrepaidReportModel.control,
        }}
        columns={PrepaidReportModel.fields}
        records={[]}
        form={PrepaidReportModel}
      />
    </>
  );
}

export default PrepaidReportTable;
