import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { httpRequest } from "../../utils/apis";
import { showToast, urls } from "../../utils/utils";
import { Button, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";

export default function FundVendorForm() {
  const { requester } = useParams();
  const [amount, setAmount] = useState(0);
  const [cashier, setCashier] = useState("");
  const [actionType, setActionType] = useState("Funding");

  let checkboxRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!amount) {
      showToast({
        type: "error",
        message: `Please fill amount.`,
      });
    }

    const payload = {
      cashier,
      amount,
    };
    payload.id = "";
    // console.log("payloaload", payload);
    showToast({ type: "success", message: 'Funding initiated' });

    httpRequest({
      url: actionType === "Funding" ? urls.vendorFund : urls.vendorWithdrawal,
      method: "post",
      data: payload,
    }).then((result) => {
      if (result && result.status) {
        showToast({ type: "success", message: result.message });
        setActionType('Funding');
        setAmount(0);
      } else {
        showToast({
          type: "error",
          message: result.message || `An error occurred.`,
        });
      }
    });
  };

  const getUser = () => {
    try {
    } catch (e) {
      console.log(e);
    }
  };

  const onAccountChange = (e) => {
    //setAccNo(e.target.textContent);
  };

  useEffect(() => {
    setCashier(requester);
  }, [cashier]);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <span class="title">Vendor {actionType}</span>

              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Action Type</label>
                      <select onChange={(e) => setActionType(e.target.value)}>
                        {["Funding"].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Amount</label>
                      <input
                        type={"number"}
                        placeholder="Amount"
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Submit
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}
