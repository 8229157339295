import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const AddTarrifModel = Object.assign(
  {},
  {
    edit: true,
    editPram: { col: "meter_no" },
    table: "res_partner",
    uniqId: uniqId,
    fields: [
      { name: "meter_no", label: "", type: "hidden" },
      {
        fullWidth: true,
        validate: { required: true },
        name: "tariff_code",
        type: "select",
        label: "Available Tarrif",
        picks: [{ id: "C1S", text: "Prepaid" }],
      },
      {
        name: "lifenotes",
        type: "text",
        validate: { required: true },
        fullWidth: true,
        label: "Life Notes",
      },
    ],
    desc: "Add Tarrif Create/Edit",
    title: "Add Tarrif Setup",
  }
);

export const AddTarrifReadModel = [];

// const
export default AddTarrifModel;
