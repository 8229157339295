/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import "assets/css/bulk.css";
import AutoBulkUpload from "./AutoBulkUpload";
import { BulkMeterModel, BulkModel } from "../../model/meter/bulk_processes";
import Header from "components/Headers/Header";
import { generateKey } from "utils/utils";

function BulkMeter() {
  return (
    <>
      <Header />
      <AutoBulkUpload
        key={generateKey()}
        form={BulkModel({ uploadType: "bulk_meter" })}
      />
    </>
  );
}
export default BulkMeter;
