import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import "assets/css/report.css";
import { httpRequest } from "../../utils/apis";
import { getUserName, showToast, urls } from "../../utils/utils";
import { Button, Grid } from "@material-ui/core";
import { Stack } from "@mui/material";
import SelectDBAuto from "components/selectDbAuto";

function SupportTicketForm() {
  const reportTypes = [
    {
      label: "Customer History",
      value: "cus_history",
    },
    {
      label: "Customer History(PDF)",
      value: "cus_history2",
    },
    {
      label: "Detailed Bill Dump",
      value: "bill_dump",
    },
    {
      label: "Minimum Bill Dump",
      value: "min_bill_dump",
    },
    {
      label: "Bill Summary",
      value: "bill_summary",
    },
    {
      label: "Payments",
      value: "payment",
    },
    {
      label: "Payment Reversal",
      value: "reverse",
    },
    {
      label: "Account without payments",
      value: "account",
    },
    {
      label: "Payments by tariff",
      value: "tariff",
    },
    {
      label: "Monthly Sales by Tariff",
      value: "month_tariff",
    },
    {
      label: "Marketer Performance",
      value: "marketer_report",
    },
    {
      label: "Viable Feeder Report",
      value: "feeder_report",
    },
    {
      label: "New Application",
      value: "new_customer",
    },
    {
      label: "New Connection",
      value: "new_connect",
    },
    {
      label: "Arrears",
      value: "arrears",
    },
    {
      label: "Customers On Credit",
      value: "credit",
    },
    {
      label: "Customers Dump",
      value: "customers",
    },
    {
      label: "Feeder Wise-Billing Report",
      value: "feeder_bill",
    },
    {
      label: "Adjustment Report",
      value: "adj_dump",
    },
  ];

  const categories = {
    "Technical Support": 127,
    "New Service": 6,
    "Account Update": 8,
    Billing: 2,
    Supply: 115,
    "Prepaid Meter": 116,
    Payment: 117,
    Hazard: 119,
    Connection: 121,
    "NERC Letter": 122,
    Unistar: 123,
    Disconnection: 124,
    "Postpaid Meter": 125,
    "Recharge Reversal": 126,
  };
  const priority = ["Low", "Normal", "Moderately", "High", "Urgent"];

  const [selectedPriority, setSelectedPriority] = useState(0);
  const [category, setCategory] = useState("Technical Support");
  const [channel, setChannel] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [accNo, setAccNo] = useState("");
  const [accType, setAccType] = useState("");

  let checkboxRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();

    const email = getUserName();
    const payload = {
      params: {
        category: categories[category],
        sub_category_id: 101,
        state: "1",
        person_name: email,
        cus_phone: "",
        email: email,
        description: description,
        acc_no: accNo,
        acc_type: accType,
        cus_street: "",
        created_by: email,
        priority_id: priority.indexOf(selectedPriority) + 1,
        acc_name: email,
        channel,
        subject,
      },
    };
    // payload._headers = {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`
    // };
    // make http request to support server
    // has header
    console.log("payloaload", payload);
    httpRequest({
      url: urls.supportTicket,
      method: "post",
      data: payload,
    }).then((data) => {
      if (data.result?.ticket_id) {
        showToast({ type: "success", message: "Ticket Created Successfully" });
      } else {
        showToast({
          type: "error",
          message: `Ticket Not Created. ${data.message || ""}`,
        });
      }
    });
  };

  const getUser = () => {
    try {
    } catch (e) {
      console.log(e);
    }
  };

  const onAccountChange = (e) => {
    setAccNo(e.target.textContent);
  };

  useEffect(() => {}, []);

  return (
    <>
      <Header />

      {/* <div class="container"> */}

      <form
        action="#"
        className="form"
        style={{ justifyContent: "space-evenly" }}
      >
        <div class="form first">
          <div class="container">
            <div class="details personal">
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Subject</label>
                      <input
                        type="text"
                        placeholder="Subject"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Channel</label>
                      <input
                        type={"text"}
                        placeholder="Channel"
                        onChange={(e) => setChannel(e.target.value)}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <Grid>
                  <Grid container lg={12} xs={12}>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Priority</label>
                      <select
                        onChange={(e) => setSelectedPriority(e.target.value)}
                      >
                        {priority.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="input-field" style={{ width: "66px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Category</label>
                      <select onChange={(e) => setCategory(e.target.value)}>
                        {Object.keys(categories).map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div class="fields card">
                <br />
                <Grid>
                  <Grid container lg={12} xs={12}>
                    {/* <div class="input-field">
                                            <label>Account No</label> */}
                    <SelectDBAuto
                      style={{ width: "70%" }}
                      col={{
                        name: "acc_no",
                        type: "select_db_auto",
                        sort: "acc_no",
                        fullWidth: false,
                        table: "res_partner",
                        value: "acc_no",
                        field: "acc_no",
                        label: "Select Account",
                        picks: [],
                        lg: 3,
                      }}
                      indx={5}
                      handleChange={onAccountChange}
                    />
                    {/* </div> */}
                    <div class="input-field" style={{ width: "242px" }}></div>
                    <div class="input-field" style={{ width: "46%" }}>
                      <label>Account Type</label>
                      <select onChange={(e) => setCategory(e.target.value)}>
                        {["postpaid", "prepaid"].map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="fields cards">
                <div class="input-field" style={{ width: "100%" }}>
                  <label>Description</label>
                  <textarea
                    style={{ height: "89px" }}
                    placeholder="Description"
                    className="form-control"
                    cols={20}
                    rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
              </div>
            </div>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button variant="contained" onClick={onSubmit}>
                <span
                  style={{
                    flex: 1,
                    alignSelf: "center",
                    justifySelf: "center",
                  }}
                >
                  Submit
                </span>
              </Button>
            </Stack>
          </div>
        </div>
      </form>
    </>
  );
}

export default SupportTicketForm;
