/* eslint-disable unused-imports/no-unused-imports */
import { OpenInNewOffRounded } from "@mui/icons-material";
import {
  downloadBillInvoiceRequest,
  generatePDFData,
  httpRequest,
} from "../utils/apis";
import { UniqueCode } from "utils/events";
import { base64ToArrayBuffer, saveByteArray, urls } from "../utils/utils";

const uniqId = `L${UniqueCode(1000000)}`;
// action to create triggerable event to download bill invoice
// control to used to attach model to defined custom action in the controller
// fields to be used to define the fields to be displayed in the model
// fabs to be used to define the fabs to be generated and displayed on the table

const GeneratedBillsModel = {
  //download/print from table
  action: {
    component: ({ data }) => {
      return (
        <OpenInNewOffRounded
          onClick={() => {
            // console.log("(JSON.stringify(data))", (JSON.stringify(data)));
            const fileName = `${data.id}_${data.created}`;
            window.open(
              urls.generateBillingReceiptBaseUrl +
                urls.generateBillingReceipt({
                  category: data.customer_category,
                  fileName: fileName,
                  id: data.user_id,
                  month: data.created.split("-")[1],
                  year: data.created.split("-")[0],
                }),
              "_blank"
            );
          }}
        />
      );
    },
  },
  control: "account_payment",
  table: "",
  fields: [
    {
      name: "id",
      label: "ID.",
    },
    { name: "amount", type: "text", label: "Amount", transform: 'currency' },
    { name: "consumed", type: "text", label: "Consumed", transform: 'number' },
    { name: "customer_category", type: "text", label: "Customer Category" },
    { name: "current_read", type: "text", label: "Current Read" },
    { name: "previous_read", type: "text", label: "Previous Read" },
    { name: "transformer_name", type: "text", label: "Trasformer" },
    { name: "bill_stage", type: "text", label: "Stage" },
    { name: "created", type: "text", label: "Created" },
    { name: "user_id", type: "hidden", label: "User Id" },
    { name: "e_consumed", type: "hidden", label: "E-Consumed" },
  ],
};

// const
export default GeneratedBillsModel;
