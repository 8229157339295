import React, { useEffect, useState } from "react";
import "assets/css/report.css";
import { httpRequest } from "./utils/apis";
import { Button, Grid } from "@material-ui/core";
// import SelectDBAuto from "../../components/selectDbAuto";
import { Stack } from "@mui/material";
import { showToast } from "./utils/utils";
import {
  clearLocalStorage,
  getFromLocalStorage,
  writeToLocalStorage,
} from "./utils/localStorage.util";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import { roles } from "./permissions";

function Login() {
  // const [login, setLogin] = useState("test@yahoo.com");
  // const [password, setPassword] = useState("@!#Geometric@123");
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  const loginUser = (user) => {
    // redirect into app
    //console.log("userRoles", userRoles, userRoles.indexOf(roles.auditors));
    history.push("/admin/switch", {
      pathname: `/admin/switch`,
      state: "",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!login || !password) {
      showToast({
        message: "Please enter login and password",
        type: "error",
      });
      return;
    }

    // make login api call
    httpRequest({
      url: "signin/",
      method: "post",
      data: {
        login: login,
        password: password,
        table: "res_users",
      },
    }).then((data) => {
      console.log("data", data);
      if (data.status) {
        // clear localstorage
        clearLocalStorage();

        // write to localstorage
        const _data = data.data;
        writeToLocalStorage("userData", { ..._data, ...{ username: login } });
        //write roles
        if (data.data?.user?.role_ids) {
          if (!Array.isArray(data.data.user.role_ids)) {
            data.data.user.role_ids = [data.data.user.role_ids];
          }
          writeToLocalStorage("roles", data.data?.user?.role_ids);
        } else {
          console.log("not supplied");
          // writeToLocalStorage('roles', ['accountant', 'auditors', 'admin']);
          // writeToLocalStorage('roles', [roles.customerCare]);
          writeToLocalStorage("roles", [roles.guest]);
        }

        showToast({
          message: "Login successful",
          type: "success",
        });
        loginUser(data.data);
      } else {
        showToast({
          message: data.message || "An error occurred",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    console.log('process.env', process.env);
    const user = getFromLocalStorage("userData");
    console.log("user", user);
    if (user) {
      loginUser(user);
    }
  });

  return (
    <>
      {/*<Header />*/}

      <div
        className="login-page"
        style={{ width: "360px", padding: "8% 0 0", margin: "auto" }}
      >
        <div
          className="form"
          style={{
            position: "relative",
            zIndex: 1,
            background: "#FFFFFF",
            width: "140%",
            marginLeft: "-90px",
            padding: "27px",
            textAlign: "center",
            boxShadow:
              "0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24)",
          }}
        >
          <Avatar
            alt="..."
            src={
              require(`./assets/img/brand/${process.env.REACT_APP_APP_LOGO
                  ? process.env.REACT_APP_APP_LOGO
                  : "argon-react.png"
                }`).default
            }
            style={{ marginBottom: "10px", width: "70px", height: "60px" }}
          />
          <h3>Staff Login</h3>
          <form className="login-form">
            <Grid item lg={12} xs={12}>
              <input
                type="text"
                placeholder="Enter Login"
                onChange={(e) => setLogin(e.target.value)}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  outline: 0,
                  background: "#f2f2f2",
                  width: "100%",
                  border: 0,
                  margin: "0 0 15px",
                  padding: "15px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                }}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <input
                type={"password"}
                placeholder="Enter Password"
                onChange={(e) => setPassword(e.target.value)}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  outline: 0,
                  background: "#f2f2f2",
                  width: "100%",
                  border: 0,
                  margin: "0 0 15px",
                  padding: "15px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                }}
              />
            </Grid>
            <Stack spacing={2} direction="row" style={{ marginTop: 25 }}>
              <Button
                variant="contained"
                onClick={onSubmit}
                style={{
                  fontFamily: '"Roboto", sans-serif',
                  textTransform: "uppercase",
                  outline: 0,
                  background: "#017cc2",
                  width: "100%",
                  border: 0,
                  padding: "15px",
                  color: "#FFFFFF",
                  fontSize: "14px",
                  transition: "all 0.3 ease",
                  cursor: "pointer",
                }}
              >
                login
              </Button>
            </Stack>
          </form>
          <p>
            {/* Are you a Vendor? <a href="/vendor-login"> Click here to Login </a> */}
          </p>
        </div>
      </div>
    </>
  );
}

export default Login;
