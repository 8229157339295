import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import componentStyles from "assets/theme/views/admin/tables.js";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(componentStyles);

const FetchTableHead = ({ columns }) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, indx) => {
          if (col.type === "hidden") return <></>;
          return (
            <TableCell
              key={`${indx}thead`}
              classes={{
                root: classes.tableCellRoot + " " + classes.tableCellRootHead,
              }}
            >
              {col.type !== undefined
                ? col.type === "action"
                  ? ""
                  : col.label
                : col.label}
            </TableCell>
          );
        })}
        <TableCell
          width={50}
          key={`checkthead`}
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        ></TableCell>
        <TableCell
          width={50}
          key={`checkthead`}
          classes={{
            root: classes.tableCellRoot + " " + classes.tableCellRootHead,
          }}
        ></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default FetchTableHead;
