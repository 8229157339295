import { useTheme } from "@material-ui/core/styles";
import { Box, makeStyles } from "@material-ui/core";
import { InsertChartOutlined, ListAltOutlined } from "@material-ui/icons";
import { useState } from "react";
import CardStats from "../../components/Cards/CardStats.js";
import componentStyles from "assets/theme/components/header.js";
import { GetLastPayment } from "../../utils/apis.js";
import { prepareValue } from "../../utils/utils.js";

const useStyles = makeStyles(componentStyles);

export const PaymentCard = ({ subtitle, footerSubtitle, form, condition }) => {
  const theme = useTheme();
  const [value, setValue] = useState(form.placeholder);
  const [_footerSubtitle, setFooterSubtitle] = useState(footerSubtitle);

  const fetchData = () => {
    GetLastPayment({
      table: form.table,
      where: condition,
    })
      .then((recs) => {
        if (recs && recs.length > 0) {
          if (recs[0]["amount"]) {
            const _value = recs[0]["amount"];
            setValue(prepareValue(form.transform, _value));
          }

          if (recs[0]["created"] && recs[0]["units"]) {
            //prepare value for date and return
            setFooter(
              prepareValue("date", recs[0]["created"]),
              prepareValue("tofixed", recs[0]["units"])
            );
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setFooter = (date, units) => {
    setFooterSubtitle(`Date - ${date} (${units}kwh)`);
  };

  // should make api call to vending apis [
  //
  // ]
  // should receive response
  // should render data as approprate
  useState(() => {
    fetchData();
  });
  return (
    <CardStats
      subtitle={form.title || "Last Payment"}
      title={`₦ ${value || "10,000"}`}
      icon={InsertChartOutlined}
      color="bgError"
      footer={
        <>
          <Box
            component="span"
            fontSize=".875rem"
            color={theme.palette.success.main}
            marginRight=".5rem"
            display="flex"
            alignItems="center"
          >
            <Box
              component={ListAltOutlined}
              width="1.5rem!important"
              height="1.5rem!important"
            />{" "}
          </Box>
          <Box component="span" whiteSpace="nowrap">
            {_footerSubtitle}
          </Box>
        </>
      }
    />
  );
};
