import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect } from "react";
import { checkUserRoleAccess, getRoles } from "./../../utils/utils";

const ApplicationTopBar = ({ routes }) => {
  const userRoles = getRoles();

  const [settings, setSettings] = React.useState([
    {
      label: "Logout",
      url: "/web-logout",
    },
  ]);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const [routeMenuItems, setRouteMenuItems] = React.useState([]);
  const [role, setRole] = React.useState(userRoles);

  const history = useHistory();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenProfileMenu = (event) => {
    setAnchorElProfile(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleCloseProfileMenu = () => {
    setAnchorElProfile(null);
  };

  

  const createLinks = (routes) => {
    const result = [];
    routes.map((prop, key) => {
      // to generate prop access, gather all the children of parent divider
      if (prop.uniqueKey && prop.uniqueKey !== null) {
        // item is parent (header) route element
        // gather all children of parent
        // generate access for parent
        prop.access = [];
        routes.map((route) => {
          if (route.parentKey === prop.uniqueKey) {
            prop.access.push(...route.access);
          }
        });
      }

      if (
        checkUserRoleAccess({
          access: prop.access,
          userRoles,
        })
      ) {
        if (prop.uniqueKey && prop.uniqueKey !== null) {
          result.push(prop);
        }
      }
    });
    return result;
  };

  const preparePageSettings = () => {
    if (
      checkUserRoleAccess({
        access: ['vendor'],
        userRoles,
      })
    ) {
      // 
      const _settings = settings;
      // settings.unshift({
      //   label: "Change Password",
      //   url: "/admin/vendor/change-password"
      // });
      setSettings(settings);
    }
  }

  useEffect(() => {
    preparePageSettings();
  }, []);

  return (
    <AppBar
      position="static"
      style={{
        background: "linear-gradient(87deg,#000, #11cdef)",
      }}
    >
      <Container style={{ marginLeft: 0 }}>
        <Toolbar disableGutters>
          <IconButton
            onClick={() => {}}
            sx={{ p: 0 }}
            style={{ marginRight: 8 }}
          >
            <Avatar
              alt={role}
              src={
                require(`./../../assets/img/brand/${
                  process.env.REACT_APP_APP_LOGO
                    ? process.env.REACT_APP_APP_LOGO
                    : "argon-react.png"
                }`).default
              }
            />
          </IconButton>
          <h2 style={{ marginRight: 100 }}>Smart Power</h2>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {routeMenuItems.map((route, index) => (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    // handle Link navigation
                    if (route.layout + route.path) {
                      history.push({
                        pathname: route.layout + route.path,
                        state: {},
                      });
                    }
                    handleCloseUserMenu(e);
                  }}
                >
                  <Typography
                    textAlign="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {route.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {routes &&
              createLinks(routes).map((page, index) => (
                <Button
                  key={index}
                  aria-haspopup="true"
                  variant="contained"
                  disableElevation
                  onClick={(e) => {
                    const routeItems = routes.filter((x) => {
                      // filter not action and child is tied to parent
                      if (
                        !x.action &&
                        x.parentKey === page.uniqueKey &&
                        checkUserRoleAccess({
                          access: x.access,
                          userRoles,
                        })
                      ) {
                        return x;
                      }
                    });
                    setRouteMenuItems(routeItems);
                    handleOpenUserMenu(e);
                  }}
                  sx={{ my: 2, color: "white", display: "block" }}
                  style={{
                    marginLeft: 10,
                    shadow: "none",
                    background: "transparent",
                    borderStyle: "solid",
                    textTransform: "capitalize",
                  }}
                >
                  {page.title}
                  {/* {<KeyboardArrowDownIcon style={{ marginBottom: "-6px" }} />} */}
                </Button>
              ))}
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {routeMenuItems &&
                routeMenuItems.map((route) => (
                  <MenuItem
                    key={route}
                    onClick={(e) => {
                      if (route.layout + route.path) {
                        history.push({
                          pathname: route.layout + route.path,
                          state: {},
                        });
                      }
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography
                      textAlign="center"
                      style={{ textTransform: "capitalize" }}
                    >
                      {route.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={role}>
              <IconButton onClick={handleOpenProfileMenu} sx={{ p: 0 }}>
                <Avatar alt="RS" src="" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElProfile}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElProfile)}
              onClose={handleCloseProfileMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={(e) => {
                    history.push({
                      pathname: setting.url,
                      state: {},
                    });
                    handleCloseProfileMenu();
                  }}
                >
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ApplicationTopBar;
