/* eslint-disable unused-imports/no-unused-imports */
import { TableChart } from "@material-ui/icons";
import {
  dailyStartAndEnd,
  managementDashboardUrls,
  monthStartAndEnd,
  weekStartAndEnd,
  yearStartAndEnd,
} from "../../utils/utils";

// Customer Category
export const TotalAssignedPrepaidMetersByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalAssignedPrepaidMetersByCustomerCategory,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Assigned  Meters by Customer Category",
  }
);

// Customer Class
export const TotalAssignedPrepaidMetersByCustomerClassModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalAssignedPrepaidMetersByCustomerClass,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 550,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
    },
    title: "Assigned Meters by Customer Class",
  }
);

export const TotalAssignedPrepaidMetersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Prepaid Customers",
    placeholder: "0",
    field: "count",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalAssignedPrepaidMeters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Assigned Meters (Prepaid)",
  }
);

export const TotalUnassignedMetersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Unassigned Meter",
    placeholder: "0",
    field: "count",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalUnassignedMeters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Total Unassigned Meters",
  }
);
