const MeterFormModel = Object.assign(
  {},
  {
    edit: false,
    table: "meter_details",
    fields: [
      // {name:"status",label:"Activate?.",type:"switch"},
      {
        fullWidth: true,
        validate: { required: true },
        name: "meter_no",
        type: "text",
        label: "Meter No.",
      },

      {
        name: "tariff",
        type: "select",
        fullWidth: false,
        label: "Tariff",
        picks: [
          { id: "C1S", text: "C1S" },
          { id: "r2s", text: "R2S" },
        ],
      },
      {
        name: "communication",
        type: "select",
        fullWidth: false,
        label: "CCommunication",
        picks: [{ id: "STS", text: "STS" }],
      },
      { name: "sgc", type: "text", fullWidth: false, label: "SGC Code" },
      { name: "sts_ti", type: "text", fullWidth: false, label: "STS TI" },
    ],
    desc: "Meter Create/Edit",
    title: "Meter Setup",
    opts: {
      sort: "desc",
      order: "id",
    },
  }
);

export default MeterFormModel;
// export MeterReadModel;
