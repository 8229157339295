import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const VendingControlModel = {
  edit: true,
  editPram: { col: "acc_no" },
  respText: "Update was successful!",
  table: "res_partner",
  uniqId: uniqId,
  fields: [
    { name: "acc_no", type: "hidden", value: "" },
    {
      fullWidth: true,
      name: "bill_status2",
      type: "radio",
      label: "",
      picks: [
        {
          label: "Vending Enable",
          value: "Active",
        },
        {
          label: "Vending Disabled",
          value: "Inactive",
        },
        // {
        //   label: 'Vending Disabled, No Monthly fee or charges', value: 'no-fee'
        // }
      ],
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      validate: { required: true },
      label: "Life Notes",
    },
  ],
  desc: "Vending Control Create/Edit",
  title: "Vending Control Setup",
};

export const VendingControlReadModel = [];

// const
export default VendingControlModel;
