import React from "react";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
// import DesktopDateTimePicker from '@mui/lab/DesktopDateTimePicker';

import { FormControl, InputLabel, Grid, TextField } from "@material-ui/core";
// import Select from '@mui/material/Select';
export default function DateTimeAuto({ col, indx, handleChange }) {
  const [value, setValue] = React.useState(null);

  return (
    <>
      <Grid
        item
        xs={12}
        lg={col.fullWidth === undefined || col.fullWidth === false ? 6 : 12}
      >
        <FormControl focused={true} fullWidth={true}>
          <InputLabel key={`${indx}label`} id={`select_label_${col.name}`}>
            {col.label}
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              id={`${col.name}`}
              label={col.label}
              myval={col.name}
              data-id={`${col.name}`}
              name={col.name}
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </>
  );
}
