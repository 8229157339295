import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const MoveOutModel = {
  edit: true,
  editPram: { col: "acc_no" },
  respText: "Move out was successful!",
  table: "res_partner",
  fields: [
    {
      name: "acc_no",
      type: "hidden",
      value: "",
    },
    {
      name: "locid",
      type: "hidden",
      value: "",
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      validate: { required: true },
      label: "Life Notes",
    },
  ],
  desc: "Move Out Create/Edit",
  title: "Move Out Setup",
};

export const MoveOutReadModel = [];

// const
export default MoveOutModel;
