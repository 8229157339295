import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { generateKey } from "utils/utils";
// import { checkUserRoleAccess } from "utils/utils";
import { getRoles } from "utils/utils";
import { ControlComponent } from "./../../components/ControlElement";

export default function BasicMenu({ options, title }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const userRoles = getRoles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {title || "menu..."}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem disabled>Pick An Option.</MenuItem>
        {options &&
          options.length > 0 &&
          options.map((option) => {
            // check access
            const access = option.access;
            // if (access && !checkUserRoleAccess({
            //   access: access,
            //   userRoles,
            // })) {
            //   return;
            // }
            return (
              <ControlComponent
                Component={
                  () => {
                    return (
                      <MenuItem
                        key={generateKey()}
                        dataname=""
                        name={option.name}
                        onClick={option.action}
                      >
                        {option.name}
                      </MenuItem>
                    );
                  }
                }
                controlAccess={access}
              />
            );
          })}
      </Menu>
    </div>
  );
}
