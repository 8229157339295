import RemoveMeterModel from "../model/remove_meter_model";
import SwapMeterModel from "../model/swap_meter_model";
import UpdateSealModel from "../model/update_seal_model";
// import TariffFormModel from "../model/tariff_model";
import MoveInModel from "../model/coustomer_control/Move_In_Model";
import MoveOutModel from "model/coustomer_control/Move_Out_Model";
import VendingControlModel from "model/coustomer_control/Vending_Control";
import PaymentModeModel from "../model/coustomer_control/Payment_Mode";
import AddTarrifModel from "../model/coustomer_control/Add_Tarrif";
import Activate4VendingModel from "../model/coustomer_control/Activate_4_Vending";
import LocationFormModel from "../model/location_model";
//import AssignMeterModel  from "../model/coustomer_control/Assign_Meter";
import ChangeTariffIndexModel from "../model/meter/Change_Tariff_Index";
import ChangeSGCCodeModel from "../model/meter/Change_SGC_Code";
import AssignLocationModel from "../model/location/assign_location_model";
import AssignMeterModel from "../model/meter/assign_meter_model";
import ClearCreditTokenModel from "../model/coustomer_control/clear_credit_token";
import TamperTokenModel from "../model/meter/tamper_token_model";
import MaxPowerToken from "../model/meter/max_power";
import FreeIssueToken from "model/meter/free_issue";
import KeyChangeToken from "./../model/meter/key_chain";
import VendModel from "../model/meter/vend_model";
import { prepareParamCaller, showToast } from "../utils/utils";
import { getFromLocalStorage } from "utils/localStorage.util";
import { roles } from "./../permissions";

const MeterControl = ({ setShowMenu, setFormModel, caller, requester }) => {
  return [
    {
      name: "Change SGC Code",
      action: () => {
        triggerMenuActionController(
          "change-sgc-code",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Change Tariff Index",
      action: () => {
        triggerMenuActionController(
          "change-tariff-index",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Remove Meter",
      action: () => {
        triggerMenuActionController(
          "remove-meter",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Set Max Power",
      action: () => {
        triggerMenuActionController(
          "max-power",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
      access: [roles.metering, roles.admin, roles.prepaidOfficer],
    },
    {
      name: "Free Issue",
      action: () => {
        triggerMenuActionController(
          "free-issue",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
      access: [roles.metering, roles.admin, roles.prepaidOfficer],
    },
    {
      name: "Vend",
      action: () => {
        console.log('process.env.REACT_APP_IN_APP_VEND != false', process.env.REACT_APP_IN_APP_VEND == 'false', process.env.REACT_APP_IN_APP_VEND);
        if (process.env.REACT_APP_IN_APP_VEND == 'false') {
          showToast({
            type: 'error',
            message: 'Feature not supported.'
          });
          return;
        } else {
          triggerMenuActionController(
            "vend",
            setShowMenu,
            setFormModel,
            requester,
            caller
          );
        }

      },
      access: [roles.headacct, roles.admin, roles.prepaidOfficer],
    },
    {
      name: "Clear Credit Token",
      action: () => {
        triggerMenuActionController(
          "clear-credit-token",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
      access: [roles.metering, roles.admin, roles.prepaidOfficer],
    },
    {
      name: "Tamper Token",
      action: () => {
        triggerMenuActionController(
          "tamper-token",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
      access: [roles.metering, roles.admin, roles.headacct, roles.prepaidOfficer,roles.metermgt],
    },
    {
      name: "Key Change",
      action: () => {
        triggerMenuActionController(
          "key-change",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
      access: [roles.metering, roles.admin, roles.prepaidOfficer],
    },
    //{ name: "Transactions", action: null },
  ]
};

const LocationControl = ({ setShowMenu, setFormModel, caller, requester }) => {
  return [
    // {
    //   name: "Adding a Tariff",
    //   action: () => {
    //     triggerMenuActionController(
    //       "add-tariff",
    //       setShowMenu,
    //       setFormModel,
    //       requester,
    //       caller
    //     );
    //   },
    // },
    {
      name: "Swap Meter",
      action: () => {
        triggerMenuActionController(
          "swap-meter",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Remove Meter",
      action: () => {
        triggerMenuActionController(
          "remove-meter",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Install Meter",
      action: () => {
        triggerMenuActionController(
          "assign-meter",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Update Seal",
      action: () => {
        triggerMenuActionController(
          "update-seal",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
  ]
};

const AccountControl = ({ setShowMenu, setFormModel, caller, requester, opts }) => {
  return [
    // { name: "Activate 4 Vending", action: () => {
    //   triggerMenuActionController(
    //     "activate-vending",
    //     setShowMenu,
    //     setFormModel,
    //     requester,caller
    //   );
    // } },
    // {
    //   name: "Add Location",
    //   action: () => {
    //     triggerMenuActionController(
    //       "add-location",
    //       setShowMenu,
    //       setFormModel,
    //       requester,
    //       caller
    //     );
    //   },
    // },
    {
      name: "Assign Meter",
      action: () => {
        triggerMenuActionController(
          "assign-meter",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Wallets Activites",
      action: () => opts.setShowWalletActivies(!opts.showWalletActivies),
    },
    {
      name: "Arrears Lookup",
      action: () => opts.setShowArrears(!opts.showArrears),
    },
    // {
    //   name: "Add Tariff",
    //   action: () => {
    //     triggerMenuActionController(
    //       "add-tarrif",
    //       setShowMenu,
    //       setFormModel,
    //       requester,
    //       caller
    //     );
    //   },
    // },
    {
      name: "Move Out",
      action: () => {
        triggerMenuActionController(
          "move-out",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
    {
      name: "Move IN",
      action: () => {
        triggerMenuActionController(
          "move-in",
          setShowMenu,
          setFormModel,
          requester,
          caller
        );
      },
    },
  ]
};


export const triggerMenuActionController = (
  trigger,
  setShowItem,
  setFormModel,
  requester = undefined,
  caller = undefined
) => {
  setShowItem(true);

  caller = prepareParamCaller(caller);

  try {
    switch (trigger) {
      case "swap-meter":
        SwapMeterModel.editPram.col = caller;
        if (requester) {
          SwapMeterModel.fields[0].value = requester;
          SwapMeterModel.fields[0].name = caller;
        }
        setFormModel(SwapMeterModel);
        break;
      case "activate-vending":
        Activate4VendingModel.editPram.col = caller;
        if (requester) {
          Activate4VendingModel.fields[0].value = requester;
          Activate4VendingModel.fields[0].name = caller;
        }
        setFormModel(Activate4VendingModel);
        break;
      case "add-location":
        // Activate4VendingModel.editPram.col = caller;
        // if (requester) {
        //   Activate4VendingModel.fields[0].value = requester;
        //   Activate4VendingModel.fields[0].name = caller;
        // }
        setFormModel(LocationFormModel);
        break;
      // case "assign-meter":
      //   // Activate4VendingModel.editPram.col = caller;
      //   // if (requester) {
      //   //   Activate4VendingModel.fields[0].value = requester;
      //   //   Activate4VendingModel.fields[0].name = caller;
      //   // }
      //   setFormModel(AssignMeterModel);
      //   break;
      case "change-tariff-index":
        ChangeTariffIndexModel.editPram.col = caller;
        if (requester) {
          ChangeTariffIndexModel.fields[0].value = requester;
          ChangeTariffIndexModel.fields[0].name = caller;
        }
        setFormModel(ChangeTariffIndexModel);
        break;
      case "change-sgc-code":
        if (requester) {
          const hrefKey = window.location.href.split("//")[1];
          const cardInfo = getFromLocalStorage(hrefKey);
          if (cardInfo) {
            ChangeSGCCodeModel.fields[0].value = cardInfo["SerialNumber"];
          } else {
            ChangeSGCCodeModel.editPram.col = caller;
            ChangeSGCCodeModel.fields[0].value = requester;
            ChangeSGCCodeModel.fields[0].name = caller;
          }
        }
        setFormModel(ChangeSGCCodeModel);
        break;
      case "add-tariff":
        AddTarrifModel.editPram.col = caller;
        if (requester) {
          AddTarrifModel.fields[0].value = requester;
          AddTarrifModel.fields[0].name = caller;
        }
        setFormModel(AddTarrifModel);
        //setFormModel(TariffFormModel);
        break;
      case "payment-mode":
        PaymentModeModel.editPram.col = caller;
        if (requester) {
          PaymentModeModel.fields[0].value = requester;
          PaymentModeModel.fields[0].name = caller;
        }
        setFormModel(PaymentModeModel);
        break;
      case "vending-control":
        VendingControlModel.editPram.col = caller;
        if (requester) {
          VendingControlModel.fields[0].value = requester;
          VendingControlModel.fields[0].name = caller;
        }
        setFormModel(VendingControlModel);
        break;
      case "move-in":
        MoveInModel.editPram.col = caller;
        if (requester) {
          MoveInModel.fields[0].value = requester;
          MoveInModel.fields[0].name = caller;
        }
        setFormModel(MoveInModel);
        break;
      case "move-out":
        MoveOutModel.editPram.col = caller;
        if (requester) {
          MoveOutModel.fields[0].value = requester;
          MoveOutModel.fields[0].name = caller;
        }
        setFormModel(MoveOutModel);
        break;
      case "remove-meter":
        RemoveMeterModel.editPram.col = caller;
        if (requester) {
          RemoveMeterModel.fields[0].value = requester;
          RemoveMeterModel.fields[0].name = caller;
        }
        setFormModel(RemoveMeterModel);
        break;
      case "update-seal":
        UpdateSealModel.editPram.col = caller;
        if (requester) {
          UpdateSealModel.fields[0].value = requester;
          UpdateSealModel.fields[0].name = caller;
        }
        setFormModel(UpdateSealModel);
        break;
      case "assign-location":
        AssignLocationModel.editPram.col = caller;
        if (requester) {
          AssignLocationModel.fields[0].value = requester;
          AssignLocationModel.fields[0].name = caller;
        }
        setFormModel(AssignLocationModel);
        break;
      case "assign-meter":
        AssignMeterModel.editPram.col = caller;
        if (requester) {
          AssignMeterModel.fields[0].value = requester;
          AssignMeterModel.fields[0].name = caller;
        }
        setFormModel(AssignMeterModel);
        break;
      case "clear-credit-token":
        ClearCreditTokenModel.fields[0].value = caller;
        ClearCreditTokenModel.fields[1].value = requester;
        setFormModel(ClearCreditTokenModel);
        break;
      case "tamper-token":
        if (requester && caller) {
          TamperTokenModel.fields[0].value = caller;
          TamperTokenModel.fields[1].value = requester;
        }
        setFormModel(TamperTokenModel);
        break;
      case "max-power":
        MaxPowerToken.fields[0].value = caller;
        MaxPowerToken.fields[1].value = requester;
        setFormModel(MaxPowerToken);
        break;
      case "free-issue":
        FreeIssueToken.fields[0].value = caller;
        FreeIssueToken.fields[1].value = requester;
        setFormModel(FreeIssueToken);
        break;
      case "key-change":
        KeyChangeToken.fields[0].value = caller;
        KeyChangeToken.fields[1].value = requester;
        setFormModel(KeyChangeToken);
        break;
      case "vend":
        VendModel.fields[0].value = caller;
        VendModel.fields[1].value = requester;
        setFormModel(VendModel);
        break;
      default:
        //
        console.log(trigger);
        AddTarrifModel.fields[0].name = caller;
        AddTarrifModel.fields[0].value = requester;
        setFormModel(AddTarrifModel);
        break;
    }
  } catch (e) {
    console.log(e);
  }
};

export const TrinityItemSetup = ({
  caller,
  requester,
  setShowMenu,
  setFormModel,
  opts
}) => {
  // console.log("trinities", (LocationControl({
  //   caller,
  //   requester,
  //   setShowMenu,
  //   setFormModel,
  //   opts
  // }))());
  console.log("trinities", LocationControl({
    caller,
    requester,
    setShowMenu,
    setFormModel,
    opts
  }));
  let trinities = [];
  switch (caller) {
    case "locid":
      trinities = [
        {
          caller: caller,
          requester: requester,
          title: "Location",
          controls: (LocationControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Account",
          controls: (AccountControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Meter",
          controls: (MeterControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
      ];
      break;
    case "meter":
      trinities = [
        {
          caller: caller,
          requester: requester,
          title: "Meter",
          controls: (MeterControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Location",
          controls: (LocationControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Account",
          controls: (AccountControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
      ];

      break;
    default:
      trinities = [
        {
          caller: caller,
          requester: requester,
          title: "Account",
          controls: (AccountControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Location",
          controls: (LocationControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
        {
          caller: caller,
          requester: requester,
          title: "Meter",
          controls: (MeterControl({
            caller,
            requester,
            setShowMenu,
            setFormModel,
            opts
          })),
        },
      ];
      break;
  }
  console.log("trinities", trinities);
  return trinities;
};
