// import { Add } from "@material-ui/icons";

import { DownloadRounded } from "@mui/icons-material";

export const VendorsModel = {
  control: "vendors",
  table: "",
  fabs: {
    downloadReport: {
      icon: <DownloadRounded />,
      onClick: ({content, title}) => {
        console.log(content, title);
      },
    },
  },
  action: {
    component: [
      // ({ data, history }) => {
      //   return (
      //     <span
      //       style={{ color: "green", cursor: "pointer" }}
      //       onClick={() => {
      //         history.push(`/admin/vendor/action/${data["username"]}`, {
      //           pathname: `/admin/vendor/action/${data["username"]}`,
      //           state: data,
      //         });
      //       }}
      //     >
      //       Fund{" "}
      //     </span>
      //   );
      // },
      ({ data, history }) => {
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() => {
              history.push(`/admin/vendor/transactions/${data["token"]}`, {
                pathname: `/admin/vendor/transactions/${data["token"]}`,
                state: data,
              });
            }}
          >
            View transactions
          </span>
        );
      },
    ],
  },
  fields: [
    ...[
      {
        name: "username",
        label: "Username",
        link: "/admin/vendor/transactions/",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "totalLimitAmount",
        label: "Limit Amount",
        transform: 'currency',
      },
      {
        name: "userLimitAmount",
        label: "Amount Available",
        transform: 'currency',
      },
      {
        name: "amountCollected",
        label: "Amount Collected",
        transform: 'currency',
      },
      {
        name: "amountToSettle",
        label: "Amount To Settle",
      },
      {
        name: "fees",
        label: "Fees",
        transform: 'currency',
      },
      {
        name: "user_type",
        label: "Vendor Type",
      },
      // {
      //   name: "status",
      //   label: "Status",
      // },
      {
        name: "owner_user",
        label: "Owner",
      },
      {
        name: "create_date",
        label: "Created on",
        transform: "date",
      },
    ],
  ],
};
