export const getFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    //console.log("key", window.localStorage.getItem(key));
    //return (window.localStorage.getItem(key));
    return JSON.parse(window.localStorage.getItem(key));
  }
};

export const removeFromLocalStorage = (key) => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
};

export const writeToLocalStorage = (key, data) => {
  if (typeof window !== "undefined") {
    console.log("saved");
    window.localStorage.setItem(key, JSON.stringify(data));
  }
};

export const clearLocalStorage = () => {
  if (typeof window !== "undefined") {
    window.localStorage.clear();
  }
};

export const getToken = () => {
  let token = null;

  let rawData = getFromLocalStorage("userData");

  if (rawData) {
    const result = rawData;

    token = result?.token;
  }

  return token;
};
