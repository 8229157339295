import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const ClearCreditTokenModel = {
  edit: false,
  respText: "Clear credit token was successful!",
  table: "res_partner",
  control: "meter_action",
  fields: [
    // {
    //   name: "meter_no",
    //   type: "select_db_auto",
    //   value: "",
    // },
    // {
    //   name: "meter_no",
    //   type: "select_db_auto",
    //   sort: "meter_no",
    //   fullWidth: true,
    //   table: "meter_details",
    //   value: "meter_no",
    //   field: "concat(id,' - ', meter_no)",
    //   label: "Select Meter",
    //   picks: [],
    // },
    {
      name: "field",
      type: "hidden",
      value: "",
    },
    {
      name: "value",
      type: "hidden",
      value: "",
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      validate: { required: true },
      label: "Life Notes",
    },
    {
      name: "action",
      type: "hidden",
      value: "clearCreditToken",
    },
  ],
  desc: "Clear Credit Token Create/Edit",
  title: "Clear Credit Token Setup",
};

export const ClearCreditTokenReadModel = [];

// const
export default ClearCreditTokenModel;
