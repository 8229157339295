/* eslint-disable unused-imports/no-unused-imports */
import { Add } from "@material-ui/icons";
import { httpRequest } from "utils/apis";
import { baseHost } from "utils/apis";
import { showToast, urls } from "./../utils/utils";

// request object contains the info to fetch data from an api and auto render

export const PendingMeterRequestModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: urls.getAllPendingMeterRequest,
      method: "get",
      baseUrl: baseHost + "/",
    },
    fabs: {
      // addVendor: {
      //   icon: <Add style={{visibility: 'hidden'}} />,
      //   onClick: () => {},
      // },
    },
    action: {
      component: [
        ({ data }) => {
          if (data?.status === 'pending') {
            return (
              <span
                style={{ color: "green", cursor: "pointer" }}
                onClick={() => {
                  showToast({
                    type: "success",
                    message: "Approving vendor account.",
                  });
                  httpRequest({
                    url: urls.approveMeterRequest,
                    method: "PATCH",
                    data: {
                      id: data?.id,
                      action: "approved",
                    },
                  }).then((response) => {
                    //console.log(response);
                    if (response?.status) {
                      showToast({
                        type: "success",
                        message: response?.message || "Action successful.",
                      });
                      window.setTimeout(() => {
                        window.location.reload();
                      }, 1500);
                    } else {
                      showToast({
                        type: "error",
                        message: response?.message || "An error occurred.",
                      });
                    }
                  });
                }}
              >
                Approve{" "}
              </span>
            );
          } else {
            return null;
          }
        },
        ({ data }) => {
          return (
            <span
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                showToast({
                  type: "success",
                  message: "Rejecting vendor account.",
                });
                httpRequest({
                  url: urls.approveMeterRequest,
                    method: "PATCH",
                  data: {
                    id: data?.id,
                    action: "rejected",
                  },
                }).then((response) => {
                  //console.log(response);
                  if (response?.status) {
                    showToast({
                      type: "success",
                      message: response?.message || "Action successful.",
                    });
                    window.setTimeout(() => {
                      window.location.reload();
                    }, 1500);
                  } else {
                    showToast({
                      type: "error",
                      message: response?.message || "An error occurred.",
                    });
                  }
                });
              }}
            >
              Reject{" "}
            </span>
          );
        },
      ],
    },
    table: "",
    fields: [
      {
        name: "id",
        label: "ID Number",
        link: "/admin/single-meter-request/",
        type: "selflink",
      },
      {
        name: "account_no",
        label: "Account No",
      },
      {
        name: "fullname",
        label: "Name",
      },
      {
        name: "email",
        label: "Email",
      },
      {
        name: "phone",
        label: "Phone",
      },
      {
        name: "status",
        label: "Status",
      },
      {
        name: "createdate",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
