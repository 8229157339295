// view vendors
// view subvendors
// view cashiers
// view agents
// view vendor's subvendors
// view subvendor's cashiers
// view subvendor's agent

import Header from "components/Headers/Header";
import FetchTable from "../Tables/FetchTable";
// import { VendorsModel } from "../../model/vendors/vendors_model";
import React from "react";
import { useHistory } from "react-router-dom";
import { ExternalVendorsModel } from "./../../model/vendors/external_vendors_model";

export default function ExternalVendors() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Vendors",
          table: ExternalVendorsModel.table,
          control: ExternalVendorsModel.control,
        }}
        columns={ExternalVendorsModel.fields}
        records={[]}
        form={ExternalVendorsModel}
      />
    </>
  );
}
