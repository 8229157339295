const AssignMeterModel = Object.assign({},{
  edit: true,
  editPram: { col: "locid" },
  respText: "Update was successful!",
  table: "res_partner",
  control: "assign_meter",
  fields: [
    { name: "locid", type: "hidden", value: "" },
    {
      name: "meter_no",
      type: "select_db_auto",
      sort: "meter_no",
      fullWidth: true,
      table: "meter_details",
      value: "meter_no",
      field: "concat(meter_no)",
      // field: "concat(id,' - ', meter_no)",
      label: "Select Meter",
      picks: [],
    },
    {
      name: "lifenotes",
      type: "text",
      validate: { required: true },
      fullWidth: true,
      label: "Life Notes",
    },
  ],
  desc: "Assign Meter",
  title: "Assign Meter Setup",
  opts: {
    where: " customer_id is null and locid is null and meter_no is not null",
  }
});

export default AssignMeterModel;
