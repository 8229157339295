import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const PaymentModeModel = {
  edit: true,
  editPram: { col: "acc_no" },
  respText: "Payment mode was changed was successful!",
  table: "res_partner",
  uniqId: uniqId,
  fields: [
    { name: "acc_no", type: "hidden", value: "" },
    // {name:"status",label:"Activate?.",type:"switch"},
    // {
    //   fullWidth: true,
    //   name: "metering_type",
    //   type: "radio",
    //   label: "Arrears",
    //   value: 'postpaid',
    // },
    {
      fullWidth: true,
      name: "metering_type",
      type: "radio",
      label: "Prepaid",
      value: "prepaid",
      checked: true,
      picks: [
        {
          value: "postpaid",
          label: "Arrears",
          checked: false,
        },
        {
          value: "prepaid",
          label: "Prepaid",
          checked: true,
        },
      ],
    },
    {
      name: "lifenotes",
      type: "text",
      fullWidth: true,
      validate: { required: true },
      label: "Life Notes",
    },
  ],
  desc: "Payment Mode Create/Edit",
  title: "Payment Mode Setup",
};

export const PaymentModeReadModel = [];

// const
export default PaymentModeModel;
