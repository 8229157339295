/* eslint-disable unused-imports/no-unused-imports */
import { TableChart } from "@material-ui/icons";
import {
  dailyStartAndEnd,
  managementDashboardUrls,
  monthStartAndEnd,
  weekStartAndEnd,
  yearStartAndEnd,
} from "../../utils/utils";

// Customer Category
export const CustomersByCustomerCategoryModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalCustomersByCustomerCategory,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 350,
      height: 250,
    },
    fields: {
      argumentField: "name",
      valueField: "result",
      countField: "result",
      showValueField: false,
    },
    title: "Customer by Customer Category",
  }
);

// Customer Class
// export const CustomersByCustomerClassModel = Object.assign(
//   {},
//   {
//     control: "http_action",
//     request: {
//       url: managementDashboardUrls.totalCustomersByCustomerClass,
//       method: "get",
//       baseUrl: managementDashboardUrls.baseURL,
//     },
//     style: {
//       width: 550,
//       height: 250,
//     },
//     fields: {
//       argumentField: "name",
//       valueField: "result",
//     },
//     title: "Customers by Customer Class",
//   }
// );

export const PrepaidCustomersByServiceCenterModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalPrepaidCustomersByServiceCenter,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 950,
      height: 350,
    },
    fields: {
      argumentField: "total_customer",
      valueField: "service_center",
    },
    title: "Prepaid Customers by Service Center",
  }
);

export const ActivePostpaidCustomersByServiceCenterModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalActivePostpaidCustomersByServiceCenters,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 950,
      height: 350,
    },
    fields: {
      argumentField: "total_customer",
      valueField: "service_center",
    },
    title: "Prepaid Customers by Service Center",
  }
);

export const SuspendedPostpaidCustomersByServiceCenterModel = Object.assign(
  {},
  {
    control: "http_action",
    request: {
      url: managementDashboardUrls.totalSuspendedPostpaidCustomersByServiceCenter,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    style: {
      width: 950,
      height: 350,
    },
    fields: {
      argumentField: "total_customer",
      valueField: "service_center",
    },
    title: "Active Postpaid Customers by Service Center",
  }
);

export const TotalPrepaidCustomersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Total Prepaid Customers",
    placeholder: "0",
    field: "total_customers",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalPrepaidCustomer,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Prepaid Customers",
  }
);

export const TotalActivePostpaidCustomersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Postpaid Customers",
    placeholder: "0",
    field: "total_customers",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalActivePostpaid,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Active Postpaid Customers",
  }
);

export const TotalSuspendedPostpaidCustomersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Postpaid Customers",
    placeholder: "0",
    field: "total_customers",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalSuspendedPostpaid,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Suspended Customers",
  }
);

export const TotalCustomersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Total Customers",
    placeholder: "0",
    field: "total_customers",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalCustomers,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Customers",
  }
);

export const TotalUnMeteredCustomersModel = Object.assign(
  {},
  {
    icon: TableChart,
    control: "http_action",
    color: "bgWarning",
    footerText: "Customers",
    placeholder: "0",
    field: "total_customers",
    transform: "number",
    request: {
      url: managementDashboardUrls.totalUnMeteredCustomer,
      method: "get",
      baseUrl: managementDashboardUrls.baseURL,
    },
    title: "Total Unmetered Customers",
  }
);
