import { PieChart, TableChart } from "@material-ui/icons";
import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
export const WalletSingleModel = Object.assign({},{
  table: "res_partner",
  transform: "currency",
  field: "wallet",
  icon: PieChart,
  color: "bgWarning",
  footerText: "Balance In Wallet",
  placeholder: "0.00",
  desc: "Wallet Card Model",
  title: "Wallet",
});

export const PrepaidTransactionSingleModel = Object.assign({},{
  table: "res_partner",
  transform: "currency",
  field: "amount",
  icon: TableChart,
  color: "bgWarning",
  footerTextField: "created",
  footerText: "",
  placeholder: "0.00",
  desc: "",
  title: "Last Payment/Purchase",
  fields: [
    { name: "amount", type: "text", label: "Amount" },
  ],
  // where: "amount is not null",
  limit: 1,
  control: "prepaid_transaction_table",
  opts: {
    sort: 'desc',
    order: 'prepaid_trans.created::date',
  }
});

//export const LastPaymentSingleModel = {
export const TotalVendModel = Object.assign({},{
  table: "res_partner",
  transform: "currency",
  field: "b_arrears",
  icon: TableChart,
  color: "bgWarning",
  footerText: "KwH Vended",
  placeholder: "0.00",
  desc: "Last Payment Model",
  title: "Total Vended",
  fields: [
    {
      co_name: "amount",
      name: "id.amount#prepaid_trans#acc_no",
    },
  ],
  control: "render_card_action",
});

// export const ArrearsSingleModel = {
//   table: "prepaid_trans",
//   transform: "currency",
//   field: "arrears",
//   icon: TableChart,
//   color: "bgWarning",
//   footerText: "Arrears Balance",
//   placeholder: "0.00",
//   desc: "Arrears Model",
//   title: "Arrears",
//   fields: [
//     {
//       co_name: "amount",
//       name: "id.amount#prepaid_trans#acc_no",
//     },
//   ],
//   control: "prepaid_transaction_table",
// };

export const ArrearsSingleModel = {
  table: "res_partner",
  transform: "currency",
  icon: PieChart,
  color: "bgWarning",
  placeholder: "0.00",
  field: "new_arrears",
  footerText: "Arrears Balance",
  desc: "Arrears Model",
  title: "Arrears",
};
