// view vendors
// view subvendors
// view cashiers
// view agents
// view vendor's subvendors
// view subvendor's cashiers
// view subvendor's agent

import Header from "components/Headers/Header";
import FetchTable from "./../../components/Tables/FetchTable";
import { VendorsModel } from "./../../model/vendors/vendors_model";
import React from "react";
import { useHistory } from "react-router-dom";

export default function VendorsTree() {
  // const [datas,setDatas] = React.useState([])
  const [addaccount, setAddAccount] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    // VendorsModel.fabs && VendorsModel.fabs.addVendor.onClick = () => {
    //   // override the default onClick
    //   history.push("/admin/create-vendor", {
    //     pathname: `/admin/create-vendor`,
    //     state: "",
    //   });
    // };
  }, []);

  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Vendors",
          table: VendorsModel.table,
          control: VendorsModel.control,
        }}
        columns={VendorsModel.fields}
        records={[]}
        form={VendorsModel}
      />
    </>
  );
}
