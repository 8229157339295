/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Select,
} from "@material-ui/core";
import {
  //LoadSql,
  LoadFromTable,
} from "../utils/apis";
// import Select from '@mui/material/Select';
export default function SelectDB({ col, indx, handleChange }) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    //let query = `SELECT id,${col.field} as text FROM ${col.table} LIMIT 50`;
    //LoadSql(query)
    LoadFromTable(col.table, col.field)
      .then((res) => {
        if (mounted !== false) {
          console.log(res);
          setRows(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => (mounted = false);
  }, []);

  return (
    <>
      <Grid
        item
        xs={12}
        lg={col.fullWidth === undefined || col.fullWidth === false ? 6 : 12}
      >
        <FormControl focused={true} fullWidth={true}>
          <InputLabel key={`${indx}label`} id={`select_label_${col.name}`}>
            {col.label}
          </InputLabel>
          {/* <select
            labelId={`select_label_${col.name}`}
            key={`${indx}select_db`}
            id={col.name}
            label={col.label}
            defaultValue={null}
            name={col.name}
            onChange={
              col.handleChange === undefined ? handleChange : col.handleChange
            }
          >
            <option key="lolaDB_option" disabled={true} value={null}>
              Select {col.label}
            </option>
            {
              rows.map((val, s_index) => {
                return (
                  <option
                    key={s_index} 
                    value={col.value ? val[col.value] : val.id}
                  >
                    {val.text}
                  </option>
                );
              })
            }
          </select> */}
          <Select
            labelId={`select_label_${col.name}`}
            key={`${indx}select_db`}
            id={col.name}
            label={col.label}
            defaultValue={null}
            name={col.name}
            onChange={
              col.handleChange === undefined ? handleChange : col.handleChange
            }
          >
            <MenuItem key="lolaDB" disabled={true} value={null}>
              Select {col.label}
            </MenuItem>
            {rows.map((val, s_indx) => {
              return (
                <MenuItem
                  key={s_indx}
                  value={col.value ? val[col.value] : val.id}
                >
                  {val.text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}
