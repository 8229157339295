import React from "react";
import FetchTable from "../../components/Tables/FetchTable";
import Header from "components/Headers/Header";
import TariffFormModel, { TariffReadModel } from "model/tariff_model";
import AutoForms from "../../components/AutoForm";

function TariffTree() {
  const [datas, setDatas] = React.useState([]);
  const [addTariff, setAddTariff] = React.useState(false);

  const sendSubmit = () => {
    window.Tariff.reload();
  };

  React.useEffect(() => {}, [datas]);
  return (
    <>
      <Header />
      <FetchTable
        settings={{
          name: "Tariff",
          add: setAddTariff,
          table: "tariff_extention",
        }}
        columns={TariffReadModel}
        records={[]}
      />

      <AutoForms
        key={0}
        keyId={1567}
        form={TariffFormModel}
        cancel={setAddTariff}
        open={addTariff}
        submit={sendSubmit}
      />
    </>
  );
}

export default TariffTree;
