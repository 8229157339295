import { UniqueCode } from "utils/events";

const uniqId = `L${UniqueCode(1000000)}`;
const WalletActivitiesModel = {
  control: "wallet_transactions",
  table: "",
  fields: [
    {
      name: "partner_id",
      label: "Partner Id.",
    },
    { name: "trans_id", type: "text", label: "Transaction Id" },
    { name: "amount", type: "text", label: "Transaction Amount" },
    { name: "type", type: "text", label: "Transaction Type" },
    { name: "wallet_balance", type: "text", label: "Wallet Balance" },
  ],
};

// const
export default WalletActivitiesModel;

export const WalletActivitiesReadModel = {
  table: "wallet_transactions",
  limit: 1000,
  fields: [
    {
      name: "partner_id",
      label: "Partner Id.",
    },
    { name: "trans_id", type: "text", label: "Transaction Id" },
    { name: "amount", type: "text", label: "Transaction Amount" },
    { name: "type", type: "text", label: "Transaction Type" },
    { name: "wallet_balance", type: "text", label: "Wallet Balance" },
  ],
};
