/* eslint-disable unused-imports/no-unused-imports */
import React from "react";
//import 'react-bootstrap-typeahead/css/Typeahead.css';
//import { Typeahead } from 'react-bootstrap-typeahead';
//import { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { generateKey } from "utils/utils";
import { useEffect } from "react";
import { LoadFromTableAuto } from "./../../utils/apis";

export const TypeheadSingle = ({
  variant = "standard",
  idField,
  style = { width: 270 },
  form,
  onChange,
}) => {
  const [search, setSearch] = React.useState("");
  const [rows, setRows] = React.useState(form.picks || []);

  useEffect(() => {
    //form
    fetchData();
    if (form && form.picks) setRows(form.picks);
  }, [search, form]);

  const reLoadOptions = (evt) => {
    if (evt.target.value) setSearch(evt.target.value);
  };

  const fetchData = () => {
    //if (!search) return;
    if (form.table) {
      LoadFromTableAuto(form.table, form.field, form.value, search, form.sort, form.opts)
        .then((res) => {
          console.log(res);
          setRows(res);
          //setLoading(false);
        })
        .catch((err) => {
          //setLoading(false);
          console.error(err);
        });
    }
  };

  return (
    <Autocomplete
      id={generateKey()}
      options={rows.filter(
        (row) => row[idField] != undefined && row[idField] != null
      )}
      onInputChange={reLoadOptions}
      renderInput={(params) => {
        if (params) {
          return <TextField {...params} variant={variant} />;
        }
      }}
      getOptionLabel={(option) => {
        if (
          option &&
          (option[idField] != null || option[idField] != undefined)
        ) {
          return option[idField];
        }
      }}
      style={style}
      onChange={(e) => {
        onChange(e);
      }}
    />
  );
};
