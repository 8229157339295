// import { Add } from "@material-ui/icons";

import { DownloadRounded } from "@mui/icons-material";

export const InternalVendorsModel = Object.assign(
  {},
  {
    control: "internal_vendors_model",
    table: "",
    fabs: {
      downloadReport: {
        icon: <DownloadRounded />,
        onClick: () => {},
      },
    },
    action: {
      component: [
        // ({ data, history }) => {
        //   return (
        //     <span
        //       style={{ color: "green", cursor: "pointer" }}
        //       onClick={() => {
        //         history.push(`/admin/vendor/action/${data["username"]}`, {
        //           pathname: `/admin/vendor/action/${data["username"]}`,
        //           state: data,
        //         });
        //       }}
        //     >
        //       Fund{" "}
        //     </span>
        //   );
        // },
        ({ data, history }) => {
          return (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push(`/admin/vendor/transactions/${data["username"]}`, {
                  pathname: `/admin/vendor/transactions/${data["username"]}`,
                  state: data,
                });
              }}
            >
              View transactions
            </span>
          );
        },
      ],
    },
    fields: [
      {
        name: "username",
        label: "Username",
        // link: "/admin/vendors/owner/",
        // link: "/admin/vendor/action/",
        // type: "selflink",
      },
      {
        name: "name",
        label: "Name",
      },
      {
        name: "totalLimitAmount",
        label: "Limit Amount",
        transform: 'currency',
      },
      {
        name: "userLimitAmount",
        label: "Amount Available",
        transform: 'currency',
      },
      {
        name: "amountCollected",
        label: "Amount Collected",
        transform: 'currency',
      },
      {
        name: "fees",
        label: "Fees",
        transform: 'currency',
      },
      {
        name: "amountToSettle",
        label: "Amount To Settle",
        transform: 'currency',
      },
      {
        name: "user_type",
        label: "Vendor Type",
      },
      // {
      //   name: "status",
      //   label: "Status",
      // },
      // {
      //   name: "owner_user",
      //   label: "Owner",
      // },
      {
        name: "create_date",
        label: "Created on",
        transform: "date",
      },
    ],
  }
);
